import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {  Typography } from '@mui/material';
import Table from '../../../../../components/Table';

// Redux
import { clearErrors, setError } from '../../../../../alerts';

// API
import { getBusiness, listTripsForBusiness } from '../../../../../api/admin';

// Icons
import VisibilityIcon from '@mui/icons-material/Edit';
import { LineItem } from '@lexcelon/react-util';
import { BackToButton } from '../../../../../components';

class BusinessSharedTrips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      business: null,
      trips: []
    };
  }

  componentDidMount() {
    getBusiness(this.props.match?.params?.id).then(business => {
      this.setState({ business });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve business.');
    });

    listTripsForBusiness(this.props.match?.params?.id).then(trips => {
      this.setState({ trips });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve trips.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  render() {
    return (
      <div style={{ margin: '20px' }}>
        <BackToButton to={`/admin/businesses/${this.state.business?.getID()}`} description={this.state.business?.getName()} />

        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Shared Trips</Typography>

        <LineItem
          description='Business Name'
          value={this.state.business?.getName()}
        />

        <Table
          title='Shared Trips'
          data={this.state.trips ?? []}
          columns={[
            {
              title: 'Trip ID',
              field: 'id'
            },
            {
              title: 'Boat Name',
              render: rowData => rowData?.getBoat()?.getName()
            },
            {
              title: 'Departure Date',
              field: 'startTimestamp',
              render: rowData => rowData.startTimestamp ? rowData.startTimestamp.toLocaleString() : ''
            },
            {
              title: 'Guests',
              render: rowData => (
                <>
                  {rowData.getGuestPermissions()?.map(guestPermission => (
                    <Typography key={guestPermission.getID()} variant='body2' style={{ fontSize: 14, color: 'slategrey' }}>
                      <b>{guestPermission.getGuest() != null ? (
                        guestPermission.getGuest().getName() + (guestPermission.getGuest()?.getAssociatedWithBusiness() != null ? (' (' + guestPermission.getGuest().getAssociatedWithBusiness().getName() + ')') : '')
                      ) : (
                        guestPermission.getGuestInvitation().getEmail() + (guestPermission.getGuestInvitation()?.getAssociatedWithBusiness() != null ? (' (' + guestPermission.getGuestInvitation().getAssociatedWithBusiness().getName() + ')') : '')
                      )}</b>:
                      {guestPermission.getPermissionListString()}
                    </Typography>
                  ))}
                </>
              )
            },
            {
              title: 'Edit',
              render: rowData => <Link to={`/admin/trips/${rowData?.id}/shared`}><VisibilityIcon /></Link>
            }
          ]}
          options={{ pageSize: 20 }}
        />
      </div>
    );
  }
}

BusinessSharedTrips.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(BusinessSharedTrips);

import { User, Trip, UserInvitation } from '.';
import { DateTime } from 'luxon';

export default class GuestTripPermission {
  static thaw(guestTripPermissionObj) {
    if (guestTripPermissionObj == null) return null;
    const guestTripPermission = new GuestTripPermission();
    if (guestTripPermissionObj.id != null) guestTripPermission.setID(guestTripPermissionObj.id);
    if (guestTripPermissionObj.guestId != null) guestTripPermission.setGuestID(guestTripPermissionObj.guestId);
    if (guestTripPermissionObj.guestInvitationId != null) guestTripPermission.setGuestInvitationID(guestTripPermissionObj.guestInvitationId);
    if (guestTripPermissionObj.tripId != null) guestTripPermission.setTripID(guestTripPermissionObj.tripId);
    if (guestTripPermissionObj.fishingPerformance != null) guestTripPermission.setFishingPerformance(guestTripPermissionObj.fishingPerformance ? true : false);
    if (guestTripPermissionObj.complianceReports != null) guestTripPermission.setComplianceReports(guestTripPermissionObj.complianceReports ? true : false);
    if (guestTripPermissionObj.videos != null) guestTripPermission.setVideos(guestTripPermissionObj.videos ? true : false);
    if (guestTripPermissionObj.images != null) guestTripPermission.setImages(guestTripPermissionObj.images ? true : false);
    if (guestTripPermissionObj.createdAt != null) guestTripPermission.setCreatedAt(DateTime.fromISO(guestTripPermissionObj.createdAt));
    if (guestTripPermissionObj.updatedAt != null) guestTripPermission.setUpdatedAt(DateTime.fromISO(guestTripPermissionObj.updatedAt));
    if (guestTripPermissionObj.trip != null) guestTripPermission.setTrip(Trip.thaw(guestTripPermissionObj.trip));
    if (guestTripPermissionObj.guest != null) guestTripPermission.setGuest(User.thaw(guestTripPermissionObj.guest));
    if (guestTripPermissionObj.guestInvitation != null) guestTripPermission.setGuestInvitation(UserInvitation.thaw(guestTripPermissionObj.guestInvitation));
    return guestTripPermission;
  }

  static thawList(guestTripPermissionObjList) {
    if (guestTripPermissionObjList == null) return [];
    var guestTripPermissions = [];
    for (var i = 0; i < guestTripPermissionObjList.length; i++) {
      guestTripPermissions.push(GuestTripPermission.thaw(guestTripPermissionObjList[i]));
    }
    return guestTripPermissions;
  }

  static freeze(guestTripPermission) {
    var guestTripPermissionObj = {};
    if (guestTripPermission.getID() != null) guestTripPermissionObj.id = guestTripPermission.getID();
    if (guestTripPermission.getGuestID() != null) guestTripPermissionObj.guestId = guestTripPermission.getGuestID();
    if (guestTripPermission.getGuestInvitationID() != null) guestTripPermissionObj.guestInvitationId = guestTripPermission.getGuestInvitationID();
    if (guestTripPermission.getTripID() != null) guestTripPermissionObj.tripId = guestTripPermission.getTripID();
    if (guestTripPermission.getFishingPerformance() != null) guestTripPermissionObj.fishingPerformance = guestTripPermission.getFishingPerformance() ? 1 : 0;
    if (guestTripPermission.getComplianceReports() != null) guestTripPermissionObj.complianceReports = guestTripPermission.getComplianceReports() ? 1 : 0;
    if (guestTripPermission.getVideos() != null) guestTripPermissionObj.videos = guestTripPermission.getVideos() ? 1 : 0;
    if (guestTripPermission.getImages() != null) guestTripPermissionObj.images = guestTripPermission.getImages() ? 1 : 0;
    if (guestTripPermission.getCreatedAt() != null) guestTripPermissionObj.createdAt = guestTripPermission.getCreatedAt().toISO();
    if (guestTripPermission.getUpdatedAt() != null) guestTripPermissionObj.updatedAt = guestTripPermission.getUpdatedAt().toISO();
    if (guestTripPermission.getTrip() != null) guestTripPermissionObj.trip = Trip.freeze(guestTripPermission.getTrip());
    if (guestTripPermission.getGuest() != null) guestTripPermissionObj.guest = User.freeze(guestTripPermission.getGuest());
    if (guestTripPermission.getGuestInvitation() != null) guestTripPermissionObj.guestInvitation = UserInvitation.freeze(guestTripPermission.getGuestInvitation());
    return guestTripPermissionObj;
  }

  static freezeList(guestTripPermissions) {
    if (guestTripPermissions == null) return [];
    var guestTripPermissionObjList = [];
    for (var i = 0; i < guestTripPermissions.length; i++) {
      guestTripPermissionObjList.push(GuestTripPermission.freeze(guestTripPermissions[i]));
    }
    return guestTripPermissionObjList;
  }

  constructor() {
    this.setID(null);
    this.setGuestID(null);
    this.setGuestInvitationID(null);
    this.setTripID(null);
    this.setFishingPerformance(null);
    this.setComplianceReports(null);
    this.setVideos(null);
    this.setImages(null);
    this.setCreatedAt(null);
    this.setUpdatedAt(null);
    this.setTrip(null);
    this.setGuest(null);
    this.setGuestInvitation(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setGuestID(guestID) {
    this.guestID = guestID;
  }

  setGuestInvitationID(guestInvitationID) {
    this.guestInvitationID = guestInvitationID;
  }

  setTripID(tripID) {
    this.tripID = tripID;
  }

  setFishingPerformance(fishingPerformance) {
    this.fishingPerformance = fishingPerformance;
  }

  setComplianceReports(complianceReports) {
    this.complianceReports = complianceReports;
  }

  setVideos(videos) {
    this.videos = videos;
  }

  setImages(images) {
    this.images = images;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
  }

  setUpdatedAt(updatedAt) {
    this.updatedAt = updatedAt;
  }

  setTrip(trip) {
    this.trip = trip;
  }

  setGuest(guest) {
    this.guest = guest;
  }

  setGuestInvitation(guestInvitation) {
    this.guestInvitation = guestInvitation;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getGuestID() {
    return this.guestID;
  }

  getGuestInvitationID() {
    return this.guestInvitationID;
  }

  getTripID() {
    return this.tripID;
  }

  getFishingPerformance() {
    return this.fishingPerformance;
  }

  getComplianceReports() {
    return this.complianceReports;
  }

  getVideos() {
    return this.videos;
  }

  getImages() {
    return this.images;
  }

  getCreatedAt() {
    return this.createdAt;
  }

  getUpdatedAt() {
    return this.updatedAt;
  }

  getTrip() {
    return this.trip;
  }

  getGuest() {
    return this.guest;
  }

  getGuestInvitation() {
    return this.guestInvitation;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getPermissionListString() {
    const permissionList = [];
    if (this.getFishingPerformance()) permissionList.push('Fishing Performance');
    if (this.getComplianceReports()) permissionList.push('Compliance Reports');
    if (this.getVideos()) permissionList.push('Videos');
    if (this.getImages()) permissionList.push('Images');
    return permissionList.join(', ');
  }

}

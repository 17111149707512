import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, Tooltip, Typography } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../alerts';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// API
import { getBusiness, toggleBusinessActivation, resendUserInvitation, revokeUserInvitation } from '../../../../api/admin';

// Components
import { BackToButton, BusinessForm, LineItem, Table } from '../../../../components';

// Boat Table Columns
const BOAT_COLUMNS = [
  {
    title: 'View',
    render: rowData => (
      <Link to={'/admin/boats/' + rowData?.id}><VisibilityIcon /></Link>
    )
  },
  { title: 'Name', field: 'name' },
  { title: 'AWS Identifier', field: 'awsIdentifier' },
  {
    title: 'Is Active',
    field: 'isActive',
    customFilterAndSearch: (term, rowData) => (rowData.isActive && term.toLowerCase() === 'active') || (!rowData.isActive && term.toLowerCase() === 'inactive')
  }
];

class Business extends Component {
  constructor(props) {
    super(props);

    this.state = {
      business: null,
      isLoadingBusiness: true,
      isLoading: false,
      inEditMode: false,
      isResendingInvitation: false,
      isRevokingInvitation: false
    };
  }

  componentDidMount() {
    this.refreshBusiness();
  }

  componentWillUnmount() {
    clearErrors();
  }

  refreshBusiness() {
    this.setState({ isLoadingBusiness: true });
    const businessID = this.props.match?.params?.id;
    getBusiness(businessID).then(business => {
      this.setState({
        business,
        name: business.getName(),
        contactName: business.getContactName(),
        contactEmail: business.getContactEmail(),
        isLoadingBusiness: false
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve business.');
      this.setState({ isLoadingBusiness: false });
    });
  }

  toggleBusinessActivation = () => {
    const isActive = !this.state.business?.getIsActive();

    this.setState({ isLoading: true });
    toggleBusinessActivation(this.state.business?.getID(), isActive).then(() => {
      setSuccess('Successfully ' + (isActive ? '' : 'de') + 'activated business.');

      this.setState({ isLoading: false });
      this.refreshBusiness();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to ' + (isActive ? '' : 'de') + 'activate business.');
      this.setState({ isLoading: false });
    });
  }

  resendInvitation = () => {
    if (this.state.business?.getInvitations()?.length <= 0) return;

    this.setState({ isResendingInvitation: true });
    resendUserInvitation(this.state.business?.getID(), this.state.business?.getInvitations()[0]?.getID()).then(() => {
      setSuccess('Successfully resent user invitation.');
      this.refreshBusiness();
      this.setState({ isResendingInvitation: false });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to resend invitation.');
      this.setState({ isResendingInvitation: false });
    });
  }

  revokeInvitation = () => {
    if (this.state.business?.getInvitations()?.length <= 0) return;

    this.setState({ isRevokingInvitation: true });
    revokeUserInvitation(this.state.business?.getID(), this.state.business?.getInvitations()[0]?.getID()).then(() => {
      setSuccess('Successfully revoked user invitation.');
      this.refreshBusiness();
      this.setState({ isRevokingInvitation: false });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to revoke invitation.');
      this.setState({ isRevokingInvitation: false });
    });
  }

  onCloseForm = () => {
    this.setState({ inEditMode: false });
    this.refreshBusiness();
  }

  render() {
    return (
      <Container style={{ marginTop: 30, marginBottom: 30 }}>
        <BackToButton to='/admin' description='Admin' />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h1'>{this.state.inEditMode && 'Edit'} {this.state.business?.getName()}</Typography>
            {this.state.isLoadingBusiness && <CircularProgress style={{ marginLeft: '20px' }} />}
          </div>
          <Typography variant='subtitle' style={{ color: this.state.business?.getIsActive() ? 'green' : 'red', fontStyle: 'italic' }}>{this.state.business?.getIsActive() ? 'Active' : 'Inactive'}</Typography>
        </div>

        {/* View Mode */}
        {!this.state.inEditMode &&
        <>
          <LineItem value={this.state.business?.getName()} description='Name' />
          <LineItem value={this.state.business?.getContactName()} description='Contact Name' />
          <LineItem value={this.state.business?.getContactEmail()} description='Contact Email' />
          <LineItem value={this.state.business?.getID()} description='Business ID' />

          {/* Buttons */}
          <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
            <Button
              onClick={() => this.setState({ inEditMode: true })}
              style={{ marginBottom: 10 }}
              disabled={this.state.isLoadingBusiness || !this.state.business || this.state.isLoading}>
              Edit Business
            </Button>

            <Button
              component={Link}
              to={`/admin/businesses/${this.state.business?.getID()}/shared-trips`}
              style={{ marginBottom: 10 }}
              disabled={this.state.isLoadingBusiness || !this.state.business || this.state.isLoading}>
              Guest Permissions
            </Button>

            {this.state.business?.getUser() == null && this.state.business?.getInvitations()?.length <= 0 && <Button component={Link} to={'/admin/businesses/' + this.state.business?.getID() + '/invite'} style={{ marginBottom: 10 }} disabled={this.state.isLoadingBusiness || !this.state.business}>Invite User</Button>}

            <Tooltip title={this.state.business?.getIsActive() ? 'Warning: This will revoke the business\'s portal functionality.' : 'Warning: This will allow the business\'s portal functionality.'}>
              <Button
                variant='outlined'
                onClick={this.toggleBusinessActivation}
                style={{ marginBottom: 10 }}
                disabled={this.state.isLoadingBusiness || !this.state.business || this.state.isLoading}>
                {this.state.business?.getIsActive() ? 'Deactivate' : 'Activate'} Business
              </Button>
            </Tooltip>
          </div>
        </>}

        {/* Edit Mode */}
        {this.state.inEditMode &&
        <BusinessForm
          onClose={this.onCloseForm}
          business={this.state.business}
        />}

        {/* Business User Invitation */}
        {this.state.business?.getInvitations()?.length > 0 && this.state.business.getInvitations()[0]?.getIsValid() &&
        <div>
          <Typography variant='h2' style={{ marginTop: '1em' }}>User Invitation</Typography>

          <LineItem value={this.state.business.getInvitations()[0].getEmail()} description='Email' />
          <LineItem value={this.state.business.getInvitations()[0].getCreatedAt()?.toLocaleString(DateTime.DATETIME_SHORT)} description='Sent On' />

          <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
            <Button onClick={this.resendInvitation} disabled={this.state.isResendingInvitation || this.state.isRevokingInvitation}>
              Resend Invitation
              {this.state.isResendingInvitation && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
            </Button>
            <Button onClick={this.revokeInvitation} style={{ marginTop: 10 }} disabled={this.state.isResendingInvitation || this.state.isRevokingInvitation}>
              Revoke Invitation
              {this.state.isRevokingInvitation && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
            </Button>
          </div>
        </div>}

        {/* Business User */}
        {this.state.business?.getUser() != null &&
        <div>
          <Typography variant='h2' style={{ marginTop: '1em' }}>User</Typography>

          <LineItem value={this.state.business.getUser().getName()} description='Name' />
          <Button component={Link} to={'/admin/users/' + this.state.business.getUser().getID()} style={{ width: 200 }}>View User</Button>
        </div>}

        {/* Boats */}
        {this.state.business?.getBoats() != null &&
        <div>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Vessels</Typography>
          <Table
            title={
              <Button component={Link} to={'/admin/boats/create?id=' + this.state?.business?.getID()} style={{ width: 200 }}>
                Create New
              </Button>
            }
            data={this.state.business?.getBoats()}
            columns={BOAT_COLUMNS}
            options={{ pageSize: 20 }}
          />
        </div>}
      </Container>
    );
  }
}

Business.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Business);

import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Container, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../../alerts';

// Components
import { BoatForm } from '../../../../components';

class CreateBoat extends Component {
  constructor(props) {
    super(props);

    this.businessID = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.id;

    if (this.businessID == null) {
      setError('Error: Something went wrong. Please try again.');
    }

    this.state = {
      redirectToBusinesses: this.businessID == null
    };
  }

  render() {
    return this.state.redirectToBusinesses ? <Redirect to='/admin/businesses' /> : (
      <Container style={{ marginTop: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Create New Boat</Typography>

        <BoatForm
          businessID={this.businessID}
        />
      </Container>
    );
  }
}

CreateBoat.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(CreateBoat);

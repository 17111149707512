import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Button, CircularProgress, Checkbox, Container, FormControlLabel, IconButton, TextField, Typography, Grid, MenuItem } from '@mui/material';

// Date/Time UI
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/CheckCircle';
import XIcon from '@mui/icons-material/Cancel';

// Alerts
import { clearErrors, setError, setSuccess } from '../alerts';

// API
import { getBoat, checkTripProject, createTrip, updateTrip } from '../api/admin';

// Lib
import { TripProject } from '../lib';

// Components
import { LineItem } from './';

class TripForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingBoat: false,
      boat: null,
      startTimestamp: DateTime.now(),
      stopTimestamp: DateTime.now(),
      convertToKg: false,
      tripProjects: [],
      redirectToBoat: false,
      tripCameras: []
    };
  }

  componentDidMount() {
    if (this.props.trip != null) {
      this.props.trip.getProjects()?.forEach(project => {
        project.setFoundMatchingBoat(true);
        project.setFoundMatchingCamera(true);
      });
      this.setState({
        startTimestamp: this.props.trip.getStartTimestamp(),
        stopTimestamp: this.props.trip.getStopTimestamp(),
        convertToKg: this.props.trip.getConvertToKg(),
        tripProjects: this.props.trip.getProjects() ? this.props.trip.getProjects() : [],
        tripCameras: this.props.trip.getTripCameras()?.map(tripCamera => ({ cameraId: tripCamera.getCameraID(), type: tripCamera.getType() ?? 'OFF' }))
      });
    }

    // Retrieve boat object
    this.setState({ isLoadingBoat: true });
    getBoat(this.props.boatID).then(boat => {
      this.setState({
        boat,
        isLoadingBoat: false
      });

      if (this.props.trip == null) this.setState({ tripCameras: boat?.getCameras()?.filter(camera => camera.getIsActive())?.map(camera => ({ cameraId: camera.getID(), type: '' })) });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve boat.');
      this.setState({ isLoadingBoat: false });
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  createTrip = (e) => {
    e.preventDefault();

    const { startTimestamp, stopTimestamp, convertToKg, tripProjects, tripCameras } = this.state;

    // Make sure all trip projects match boat and camera
    let allTripProjectsMatch = true;
    tripProjects.forEach(tripProject => {
      if (!tripProject.getFoundMatchingBoat() || !tripProject.getFoundMatchingCamera()) {
        allTripProjectsMatch = false;
        setError('Error: All Project IDs must match boat and camera.');
        return;
      }
    });
    if (!allTripProjectsMatch) return;

    // Change any tripCameras types from OFF to null
    tripCameras.forEach(tripCamera => {
      if (tripCamera.type === 'OFF') tripCamera.type = null;
    });

    this.setState({ isLoading: true });
    if (this.props.trip != null) {
      updateTrip(this.props.trip.getID(), startTimestamp, stopTimestamp, convertToKg, tripProjects, tripCameras).then(() => {
        setSuccess('Successfully updated trip.');
        this.setState({ isLoading: false, redirectToBoat: true });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to create trip.');
        this.setState({ isLoading: false });
      });
    }
    else {
      createTrip(this.props.boatID, startTimestamp, stopTimestamp, convertToKg, tripProjects, tripCameras).then(() => {
        setSuccess('Successfully made trip.');
        this.setState({ isLoading: false, redirectToBoat: true });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to create trip.');
        this.setState({ isLoading: false });
      });
    }
  }

  addTripProject = () => {
    this.setState({ tripProjects: [...this.state.tripProjects, new TripProject() ] });
  }

  removeTripProject = (index) => {
    this.setState({ tripProjects: this.state.tripProjects?.filter((_, filterIndex) => filterIndex !== index) });
  }

  onSearchTripProject = (e, index) => {
    e.preventDefault();

    const projectID = this.state.tripProjects[index]?.getProjectID();

    // Make sure the same trip project doesn't already exist on page
    let doesNotExist = true;
    this.state.tripProjects?.forEach(tripProject => {
      if (tripProject.getProjectID() === projectID && tripProject.getFoundMatchingBoat() && tripProject.getFoundMatchingCamera()) {
        doesNotExist = false;
        setError('Error: You have already added this trip project.');
        return;
      }
    });
    if (!doesNotExist) return;

    this.setState({ isLoading: true });
    checkTripProject(this.props.boatID, projectID).then(tripProject => {
      this.setState({
        isLoading: false,
        tripProjects: this.state.tripProjects?.map((mapTripProject, mapIndex) => {
          if (mapIndex !== index) return mapTripProject;
          mapTripProject.setFoundMatchingBoat(tripProject.getFoundMatchingBoat());
          mapTripProject.setFoundMatchingCamera(tripProject.getFoundMatchingCamera());
          mapTripProject.setS3Path(tripProject.getS3Path());
          mapTripProject.setCameraID(tripProject.getCameraID());
          return mapTripProject;
        })
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to search trip project.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e, index) => {
    this.state.tripProjects[index]?.setProjectID(e.target.value);
    this.setState({ tripProjects: this.state.tripProjects });
  }

  render() {
    return this.state.redirectToBoat ? <Redirect to={'/admin/boats/' + this.props.boatID} /> : (
      <Container style={{ marginTop: 20, marginBottom: 50 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {this.state.isLoadingBoat && <CircularProgress />}
        </div>

        <div>
          {/* Business Name */}
          <LineItem
            value={this.state.boat?.getBusiness()?.getName()}
            description='Business'
          />

          {/* Boat Name */}
          <LineItem
            value={this.state.boat?.getName()}
            description='Boat'
          />

          {/* Start Timestamp*/}
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              renderInput={(props) =>
                <TextField
                  required
                  variant='filled'
                  style={{ width: '100%', marginBottom: '10px' }}
                  {...props}
                />
              }
              name='startTimestamp'
              label='Start Timestamp'
              value={this.state.startTimestamp}
              onChange={(newValue) => {
                this.setState({
                  startTimestamp: newValue
                });
              }}
              disabled={this.state.isLoading || this.state.isLoadingBoat}
            />
          </LocalizationProvider>

          {/* Stop Timestamp*/}
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              renderInput={(props) =>
                <TextField
                  required
                  variant='filled'
                  style={{ width: '100%', marginBottom: '10px' }}
                  {...props}
                />
              }
              name='stopTimestamp'
              label='Stop Timestamp'
              value={this.state.stopTimestamp}
              onChange={(newValue) => {
                this.setState({
                  stopTimestamp: newValue
                });
              }}
              disabled={this.state.isLoading || this.state.isLoadingBoat}
            />
          </LocalizationProvider>

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.convertToKg}
                onChange={(val) => this.setState({ convertToKg: val.target.checked })}
              />
            }
            label='Convert lbs to kg'
          />

          <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trip Cameras</Typography>
          {this.state.tripCameras?.map((tripCamera, index) => (
            <Grid container key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5em' }}>
              <Grid item xs={6}>
                <Typography>{this.state.boat?.getCameras()?.find(camera => camera.id === tripCamera.cameraId)?.getName()}</Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  value={this.state.tripCameras[index]?.type}
                  onChange={(e) => {
                    const tripCameras = this.state.tripCameras;
                    tripCameras[index].type = e.target.value;
                    this.setState({ tripCameras: tripCameras });
                  }}
                  style={{ width: '100%', marginLeft: 20 }}
                  variant='filled'
                  label='Camera Type'
                  disabled={this.state.isLoading || this.state.isLoadingBoat}
                  select>
                  <MenuItem value='OFF'>OFF</MenuItem>
                  <MenuItem value='SET'>SET</MenuItem>
                  <MenuItem value='HAUL'>HAUL</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          ))}

          {/* Trip Projects */}
          <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trip Projects</Typography>
          {this.state.tripProjects?.map((tripProject, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', padding: 20, backgroundColor: 'lightgrey', borderRadius: 7, marginBottom: 15 }}>
              {/* Project ID and Search Button */}
              <form onSubmit={(e) => this.onSearchTripProject(e, index)}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                  <TextField
                    required
                    name='projectID'
                    label='Project ID'
                    style={{ width: '100%', backgroundColor: 'white' }}
                    value={tripProject.getProjectID()}
                    onChange={(e) => this.onChange(e, index)}
                    variant='filled'
                    type='text'
                    disabled={this.state.isLoading || (tripProject.getFoundMatchingBoat() && tripProject.getFoundMatchingCamera())}
                  />
                  <Button style={{ width: 100, marginLeft: 20 }} type='submit' disabled={this.state.isLoading || (tripProject.getFoundMatchingBoat() && tripProject.getFoundMatchingCamera())}>
                    Search
                  </Button>
                </div>
              </form>

              {/* File Path */}
              <LineItem
                value={tripProject.getS3Path()}
                description='File Path'
              />

              {/* Validation Icon and Delete Button */}
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  {tripProject.getFoundMatchingBoat() && tripProject.getFoundMatchingCamera() ? (
                    <>
                      <CheckIcon style={{ color: 'green' }} />
                      <Typography variant='body1' style={{ marginLeft: 5, color: 'green' }}>Boat and Camera match</Typography>
                    </>
                  ) : (
                    tripProject.getFoundMatchingBoat() == null || tripProject.getFoundMatchingCamera() == null ? (
                      <>
                        <Typography variant='body1'>Please search ProjectID</Typography>
                      </>
                    ) : (
                      <>
                        <XIcon style={{ color: 'red' }} />
                        <Typography variant='body1' style={{ marginLeft: 5, color: 'red' }}>{
                          !tripProject.getFoundMatchingBoat() && !tripProject.getFoundMatchingCamera() ? 'Boat and Camera do NOT match' : (
                            !tripProject.getFoundMatchingBoat() ? 'Boat does NOT match' : 'Camera does NOT match'
                          )
                        }</Typography>
                      </>
                    )
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: 125 }}>
                  <IconButton>
                    <CloseIcon onClick={() => this.removeTripProject(index)} />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
          <Button style={{ width: 120, marginTop: 10 }} onClick={this.addTripProject} disabled={this.state.isLoading}>Add New</Button>

          {/* Buttons */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 50 }}>
            {this.props.onClose &&
            <Button onClick={this.props.onClose} disabled={this.state.isLoading} style={{ backgroundColor: 'grey', marginRight: 10 }}>
              Cancel
            </Button>}
            <Button onClick={this.createTrip} disabled={this.state.isLoading}>
              {this.props.trip != null ? 'Update' : 'Create'} Trip
              {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

TripForm.propTypes = {
  trip: PropTypes.object,
  boatID: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withRouter(TripForm);

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, TextField, Typography } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../../alerts';

// API
import { getBusiness, inviteUser } from '../../../../../api/admin';

class InviteUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      business: null,
      email: '',
      isLoadingBusiness: true,
      isLoading: false,
      redirectToBusiness: false
    };
  }

  componentDidMount() {
    this.setState({ isLoadingBusiness: true });
    const businessID = this.props.match?.params?.id;
    getBusiness(businessID).then(business => {
      this.setState({
        business,
        isLoadingBusiness: false
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve business.');
      this.setState({ isLoadingBusiness: false });
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  inviteUser = (e) => {
    e.preventDefault();

    const { email } = this.state;

    this.setState({ isLoading: true });
    inviteUser(this.state.business?.getID(), email).then(() => {
      setSuccess('Successfully invited user.');
      this.setState({ isLoading: false, redirectToBusiness: true });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to invite user.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.redirectToBusiness ? <Redirect to={'/admin/businesses/' + this.state.business?.getID()} /> : (
      <Container style={{ marginBottom: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <Typography variant='h1'>Invite {this.state.business?.getName()} User</Typography>
          {this.state.isLoadingBusiness && <CircularProgress style={{ marginLeft: '20px' }} />}
        </div>

        <form onSubmit={this.inviteUser}>
          {/* Email Input */}
          <TextField
            required
            type='email'
            error={this.state.formError && this.state.email === ''}
            name='email'
            label='User Email'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
          />

          <Button style={{ marginTop: 20 }} type='submit' disabled={this.state.isLoading}>
            Invite
            {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
          </Button>
        </form>
      </Container>
    );
  }
}

InviteUser.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(InviteUser);

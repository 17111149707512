import { DateTime } from 'luxon';

export default class ChangeEmailRequest {
  static thaw(changeEmailRequestObj) {
    if (changeEmailRequestObj == null) return null;
    const changeEmailRequest = new ChangeEmailRequest();
    if (changeEmailRequestObj.id != null) changeEmailRequest.setID(changeEmailRequestObj.id);
    if (changeEmailRequestObj.email != null) changeEmailRequest.setEmail(changeEmailRequestObj.email);
    if (changeEmailRequestObj.token != null) changeEmailRequest.setToken(changeEmailRequestObj.token);
    if (changeEmailRequestObj.isValid != null) changeEmailRequest.setIsValid(changeEmailRequestObj.isValid == null ? null : (changeEmailRequestObj.isValid ? true : false));
    if (changeEmailRequestObj.completedAt != null) changeEmailRequest.setCompletedAt(DateTime.fromISO(changeEmailRequestObj.completedAt));
    if (changeEmailRequestObj.userId != null) changeEmailRequest.setUserID(changeEmailRequestObj.userId);
    return changeEmailRequest;
  }

  static thawList(changeEmailRequestObjList) {
    if (changeEmailRequestObjList == null) return [];
    var changeEmailRequests = [];
    for (var i = 0; i < changeEmailRequestObjList.length; i++) {
      changeEmailRequests.push(ChangeEmailRequest.thaw(changeEmailRequestObjList[i]));
    }
    return changeEmailRequests;
  }

  static freeze(changeEmailRequest) {
    var changeEmailRequestObj = {};
    if (changeEmailRequest.getID() != null) changeEmailRequestObj.id = changeEmailRequest.getID();
    if (changeEmailRequest.getEmail() != null) changeEmailRequestObj.email = changeEmailRequest.getEmail();
    if (changeEmailRequest.getToken() != null) changeEmailRequestObj.token = changeEmailRequest.getToken();
    if (changeEmailRequest.getIsValid() != null) changeEmailRequestObj.isValid = changeEmailRequest.getIsValid() == null ? null : (changeEmailRequest.getIsValid() ? 1 : 0);
    if (changeEmailRequest.getCompletedAt() != null) changeEmailRequestObj.completedAt = DateTime.fromISO(changeEmailRequest.getCompletedAt());
    if (changeEmailRequest.getUserID() != null) changeEmailRequestObj.userId = changeEmailRequest.getUserID();
    return changeEmailRequestObj;
  }

  static freezeList(changeEmailRequests) {
    if (changeEmailRequests == null) return [];
    var changeEmailRequestObjList = [];
    for (var i = 0; i < changeEmailRequests.length; i++) {
      changeEmailRequestObjList.push(ChangeEmailRequest.freeze(changeEmailRequests[i]));
    }
    return changeEmailRequestObjList;
  }

  constructor() {
    this.setID(null);
    this.setEmail(null);
    this.setToken(null);
    this.setIsValid(null);
    this.setCompletedAt(null);
    this.setUserID(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setEmail(email) {
    this.email = email;
  }

  setToken(token) {
    this.token = token;
  }

  setIsValid(isValid) {
    this.isValid = isValid;
  }

  setCompletedAt(completedAt) {
    this.completedAt = completedAt;
  }

  setUserID(userID) {
    this.userID = userID;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getEmail() {
    return this.email;
  }

  getToken() {
    return this.token;
  }

  getIsValid() {
    return this.isValid;
  }

  getCompletedAt() {
    return this.completedAt;
  }

  getUserID() {
    return this.userID;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { withRouter, Link } from 'react-router-dom';
import { Button, CircularProgress, Container, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../alerts';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// API
import { getBoat, listTrips, toggleBoatActivation } from '../../../../api/admin';

// Components
import { BackToButton, BoatForm, LineItem, Table } from '../../../../components';

const CAMERA_COLUMNS = [
  {
    title: 'View',
    render: rowData => (
      <Link to={'/admin/cameras/' + rowData?.id}><VisibilityIcon /></Link>
    )
  },
  { title: 'Name', field: 'name' },
  { title: 'AWS Identifier', field: 'awsIdentifier' },
  { title: 'Satellite IMEI', field: 'rockBlockImei' },
  {
    title: 'Is Active',
    field: 'isActive',
    customFilterAndSearch: (term, rowData) => (rowData.isActive && term.toLowerCase() === 'active') || (!rowData.isActive && term.toLowerCase() === 'inactive')
  }
];
const TRIP_COLUMNS = [
  {
    title: 'View',
    render: rowData => (
      <Link to={'/admin/trips/' + rowData?.id}><VisibilityIcon /></Link>
    )
  },
  {
    title: 'Start Timestamp',
    render: (rowData) => <Typography variant='body1'>{rowData.getStartTimestamp()?.toLocaleString(DateTime.DATETIME_SHORT)}</Typography>
  },
  {
    title: 'Stop Timestamp',
    render: (rowData) => <Typography variant='body1'>{rowData.getStopTimestamp()?.toLocaleString(DateTime.DATETIME_SHORT)}</Typography>
  },
  {
    title: 'Project IDs',
    render: (rowData) => (
      <Typography variant='body1'>{
        rowData.getProjects()?.map((project, index) => (
          project.getProjectID() + (index === rowData.getProjects()?.length - 1 ? '' : ', ')
        ))
      }</Typography>
    )
  }
];

class Boat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boat: null,
      isLoadingBoat: true,
      isLoading: false,
      trips: null,
      hideInactiveCameras: true
    };
  }

  componentDidMount() {
    this.refreshBoat();
  }

  componentWillUnmount() {
    clearErrors();
  }

  refreshBoat() {
    this.setState({ isLoadingBoat: true });
    const boatID = this.props.match?.params?.id;
    getBoat(boatID).then(boat => {
      listTrips(boatID).then(trips => {
        this.setState({
          boat,
          name: boat?.getName(),
          awsIdentifier: boat?.getAwsIdentifier(),
          isLoadingBoat: false,
          trips
        });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to retrieve list of trips.');
        this.setState({
          boat: boat,
          name: boat?.getName(),
          awsIdentifier: boat?.getAwsIdentifier(),
          isLoadingBoat: false
        });
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve boat.');
      this.setState({ isLoadingBoat: false });
    });
  }

  toggleBoatActivation = () => {
    const isActive = !this.state.boat?.getIsActive();
    this.setState({ isLoading: true });
    toggleBoatActivation(this.state.boat?.getID(), isActive).then(() => {
      setSuccess('Successfully ' + (isActive ? '' : 'de') + 'activated boat.');

      this.setState({ isLoading: false });
      this.refreshBoat();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to ' + (isActive ? '' : 'de') + 'activate boat.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onInactiveCameraChange = () => {
    this.setState({ hideInactiveCameras: !this.state.hideInactiveCameras });
  }

  onCloseForm = () => {
    this.setState({ inEditMode: false });
    this.refreshBoat();
  }

  render() {
    return (
      <Container style={{ marginTop: 30 }}>
        <BackToButton to={'/admin/businesses/' + this.state.boat?.getBusinessID()} description={this.state.boat?.getBusiness()?.getName()} />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h1'>{this.state.inEditMode && 'Edit'} {this.state.boat?.getName()}</Typography>
            {this.state.isLoadingBoat && <CircularProgress style={{ marginLeft: '20px' }} />}
          </div>
          <Typography variant='subtitle' style={{ color: this.state.boat?.getIsActive() ? 'green' : 'red', fontStyle: 'italic' }}>{this.state.boat?.getIsActive() ? 'Active' : 'Inactive'}</Typography>
        </div>

        {/* View Mode */}
        {!this.state.inEditMode &&
        <>
          <LineItem value={this.state.boat?.getName()} description='Name' />
          <LineItem value={this.state.boat?.getAwsIdentifier()} description='AWS Identifier' />

          {/* Buttons */}
          <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
            <Button
              onClick={() => this.setState({ inEditMode: true })}
              style={{ marginBottom: 10 }}
              disabled={this.state.isLoadingBoat || !this.state.boat || this.state.isLoading}>
              Edit Boat
            </Button>
            <Tooltip title={this.state.boat?.getIsActive() ? 'Warning: This will prevent users from viewing this boat.' : 'Warning: This will allow users to view this boat.'}>
              <Button
                variant='outlined'
                onClick={this.toggleBoatActivation}
                style={{ marginBottom: 10 }}
                disabled={this.state.isLoadingBoat || !this.state.boat || this.state.isLoading}>
                {this.state.boat?.getIsActive() ? 'Deactivate' : 'Activate'} Boat
              </Button>
            </Tooltip>
          </div>
        </>}

        {/* Edit Mode */}
        {this.state.inEditMode &&
        <BoatForm
          boat={this.state.boat}
          onClose={this.onCloseForm}
        />}

        {/* Cameras */}
        {this.state.boat != null &&
        <div>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Cameras</Typography>
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                checked={this.state.hideInactiveCameras}
                onChange={this.onInactiveCameraChange}
              />}
            label="Filter Out Inactive Cameras" />
          <Table
            title={<Button component={Link} to={'/admin/cameras/create?id=' + this.state.boat?.getID()} style={{ width: 200 }}>Create New</Button>}
            data={this.state.hideInactiveCameras ? (this.state.boat?.getCameras().filter(camera => camera.isActive == true)) : (this.state.boat?.getCameras())}
            columns={CAMERA_COLUMNS}
            options={{ pageSize: 20 }}
          />
        </div>}

        {/* Trips */}
        {this.state.trips != null &&
        <div>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Trips</Typography>
          <Table
            title={<Button component={Link} to={'/admin/trips/create?id=' + this.state.boat?.getID()} style={{ width: 200 }}>Create New</Button>}
            data={this.state.trips}
            columns={TRIP_COLUMNS}
            options={{ pageSize: 20 }}
          />
        </div>}
      </Container>
    );
  }
}

Boat.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Boat);

import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

class MapWidget extends React.Component {
  render() {
    return (
      <div style={{ height: this.props.height, width: this.props.width, ...this.props.style }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDSDsTwCHJzumvHSIUL0aHRqHurc6QAAW4' }}
          defaultCenter={this.props.defaultCenter}
          defaultZoom={this.props.defaultZoom ?? 11}
          yesIWantToUseGoogleMapApiInternals={this.props.yesIWantToUseGoogleMapApiInternals ?? false}
          onGoogleApiLoaded={this.props.onGoogleApiLoaded ?? null}
          options={{
            scaleControl: true
          }}
        >
          {this.props.children}
        </GoogleMapReact>
      </div>
    );
  }
}

MapWidget.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  defaultCenter: PropTypes.object.isRequired,
  defaultZoom: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.any,
  yesIWantToUseGoogleMapApiInternals: PropTypes.bool,
  onGoogleApiLoaded: PropTypes.func
};

export default MapWidget;

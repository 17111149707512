import { DateTime } from 'luxon';

export default class ForgotPasswordRequest {
  static thaw(forgotPasswordRequestObj) {
    if (forgotPasswordRequestObj == null) return null;
    const forgotPasswordRequest = new ForgotPasswordRequest();
    if (forgotPasswordRequestObj.id != null) forgotPasswordRequest.setID(forgotPasswordRequestObj.id);
    if (forgotPasswordRequestObj.token != null) forgotPasswordRequest.setToken(forgotPasswordRequestObj.token);
    if (forgotPasswordRequestObj.isValid != null) forgotPasswordRequest.setIsValid(forgotPasswordRequestObj.isValid == null ? null : (forgotPasswordRequestObj.isValid ? true : false));
    if (forgotPasswordRequestObj.completedAt != null) forgotPasswordRequest.setCompletedAt(DateTime.fromISO(forgotPasswordRequestObj.completedAt));
    if (forgotPasswordRequestObj.userId != null) forgotPasswordRequest.setUserID(forgotPasswordRequestObj.userId);
    return forgotPasswordRequest;
  }

  static thawList(forgotPasswordRequestObjList) {
    if (forgotPasswordRequestObjList == null) return [];
    var forgotPasswordRequests = [];
    for (var i = 0; i < forgotPasswordRequestObjList.length; i++) {
      forgotPasswordRequests.push(ForgotPasswordRequest.thaw(forgotPasswordRequestObjList[i]));
    }
    return forgotPasswordRequests;
  }

  static freeze(forgotPasswordRequest) {
    var forgotPasswordRequestObj = {};
    if (forgotPasswordRequest.getID() != null) forgotPasswordRequestObj.id = forgotPasswordRequest.getID();
    if (forgotPasswordRequest.getToken() != null) forgotPasswordRequestObj.token = forgotPasswordRequest.getToken();
    if (forgotPasswordRequest.getIsValid() != null) forgotPasswordRequestObj.isValid = forgotPasswordRequest.getIsValid() == null ? null : (forgotPasswordRequest.getIsValid() ? 1 : 0);
    if (forgotPasswordRequest.getCompletedAt() != null) forgotPasswordRequestObj.completedAt = forgotPasswordRequest.getCompletedAt();
    if (forgotPasswordRequest.getUserID() != null) forgotPasswordRequestObj.userId = forgotPasswordRequest.getUserID();
    return forgotPasswordRequestObj;
  }

  static freezeList(forgotPasswordRequests) {
    if (forgotPasswordRequests == null) return [];
    var forgotPasswordRequestObjList = [];
    for (var i = 0; i < forgotPasswordRequests.length; i++) {
      forgotPasswordRequestObjList.push(ForgotPasswordRequest.freeze(forgotPasswordRequests[i]));
    }
    return forgotPasswordRequestObjList;
  }

  constructor() {
    this.setID(null);
    this.setToken(null);
    this.setIsValid(null);
    this.setCompletedAt(null);
    this.setUserID(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setToken(token) {
    this.token = token;
  }

  setIsValid(isValid) {
    this.isValid = isValid;
  }

  setCompletedAt(completedAt) {
    this.completedAt = completedAt;
  }

  setUserID(userID) {
    this.userID = userID;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getToken() {
    return this.token;
  }

  getIsValid() {
    return this.isValid;
  }

  getCompletedAt() {
    return this.completedAt;
  }

  getUserID() {
    return this.userID;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

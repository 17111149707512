import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, MenuItem, TextField, Typography } from '@mui/material';
import ImageGallery from 'react-image-gallery';
import { DateTime } from 'luxon';

// Constants
import { DATE_TIME_ZONE_FORMAT } from '../constants';

class VideoGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayedVideoIndexSelection: '',
      cameraVideoFolders: [],
      selectedCameraIndex: null,
      selectedSessionIndex: null,
    };
  }

  componentDidMount() {
    this.formatProjects();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trip !== this.props.trip) {
      this.formatProjects();
    }
  }

  formatProjects() {
    const haulSessions = [];
    const setSessions = [];

    // Get all haul and set sessions
    for (const set of this.props.trip?.getSets()) {
      if (set.getTotalDistance() > 0) {
        setSessions.push({
          startTimestamp: set.getStartTimestamp(),
          stopTimestamp: set.getStopTimestamp()
        });
      }
      if (set.getHaul() != null) {
        haulSessions.push({
          startTimestamp: set.getHaul()?.getStartTimestamp(),
          stopTimestamp: set.getHaul()?.getStopTimestamp()
        });
      }
    }

    let cameraVideoFolders = [];
    const tripCameras = this.props.trip?.getTripCameras()?.filter(tripCamera => tripCamera.getType() != null) ?? [];
    for (const tripCamera of tripCameras) {
      cameraVideoFolders.push({
        camera: tripCamera.getCamera(),
        sessions: tripCamera.getType() === 'HAUL' ? haulSessions.map(session => ({ ...session, videos: [] })) : setSessions.map(session => ({ ...session, videos: [] })),
        glacier: false
      });
    }

    // Add all videos into correct camera/session
    for (const project of this.props.trip?.getProjects()) {
      let cameraVideoFolderIndex = cameraVideoFolders.findIndex(cameraVideoFolder => cameraVideoFolder.camera.getID() === project.getCamera().getID());
      if (cameraVideoFolderIndex === -1) continue;
      if (project.getGlacier()) cameraVideoFolders[cameraVideoFolderIndex].glacier = true;
      for (const videoFolder of project.getVideoFolders()) {
        for (const video of videoFolder.getVideos()) {
          cameraVideoFolders[cameraVideoFolderIndex].sessions.forEach(session => {
            if (session.startTimestamp <= video.getDate() && video.getDate() <= session.stopTimestamp) {
              session.videos.push({
                embedUrl: video.getUrl(),
                timestamp: video.getDate(),
                cameraName: project.getCamera()?.getName(),
                renderItem: this.renderVideo.bind(this),
                fromGlacier: video.getFromGlacier()
              });
            }
          });
        }
      }
    }

    // Sort videos and add clipNumber and totalNumClips to each video
    for (const cameraVideoFolder of cameraVideoFolders) {
      for (const session of cameraVideoFolder.sessions) {
        session.videos.sort((a, b) => a.timestamp - b.timestamp);
        for (let i = 0; i < session.videos.length; i++) {
          session.videos[i].clipNumber = i + 1;
          session.videos[i].totalNumClips = session.videos.length;
        }
      }
    }

    this.setState({ cameraVideoFolders });
  }

  renderVideo(video) {
    return (
      <div>
        <video width='90%' height='500' controls muted>
          <source
            ref={ref => this._video = ref}
            src={video.embedUrl + '#t=0.001'}
            type='video/mp4'
          />
        </video>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 20 }}>
          <Typography variant='body1'><b>Video Start Time:</b> {video.timestamp?.setZone(this.props.trip?.getTimezone())?.toLocaleString({ ...DateTime.DATETIME_SHORT, timeZoneName: 'short' })}</Typography>
          <Typography variant='body1'><b>Camera:</b> {video.cameraName}</Typography>
          <Typography variant='body1'><b>Clip Number:</b> {video.clipNumber} of {video.totalNumClips}</Typography>
          {video.fromGlacier &&
          <Typography variant='body1'><b>Video Pulled from Storage</b></Typography>}
        </div>
      </div>
    );
  }

  goToClipNumber = () => {
    this._imageGallery.slideToIndex(this.state.displayedVideoIndexSelection);
  }

  render() {
    return (
      <div>
        {/* Select Camera */}
        <TextField
          name='selectedCameraIndex'
          label='Camera'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.selectedCameraIndex}
          onChange={(e) => this.setState({ selectedCameraIndex: e.target.value, selectedSessionIndex: null, displayedVideoIndexSelection: '' })}
          variant='filled'
          select
        >
          {this.state.cameraVideoFolders?.map((cameraFolder, index) => (
            <MenuItem key={index} value={index}>{cameraFolder.camera.getName()}</MenuItem>
          ))}
        </TextField>

        {/* Select Haul/Set Session */}
        <TextField
          name='selectedSessionIndex'
          label='Recording Session'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.selectedSessionIndex}
          onChange={(e) => this.setState({ selectedSessionIndex: e.target.value, displayedVideoIndexSelection: '' })}
          variant='filled'
          disabled={this.state.selectedCameraIndex == null}
          select
        >
          {this.state.selectedCameraIndex != null && this.state.selectedCameraIndex < this.state.cameraVideoFolders?.length && this.state.cameraVideoFolders[this.state.selectedCameraIndex]?.sessions?.map((session, index) => (
            <MenuItem key={index} value={index}>{session.startTimestamp.setZone(this.props.trip?.getTimezone())?.toLocaleString(DATE_TIME_ZONE_FORMAT)}</MenuItem>
          ))}
        </TextField>

        {this.state.cameraVideoFolders[this.state.selectedCameraIndex]?.glacier &&
        <Typography variant='body1' style={{ fontWeight: 'bold', margin: '1em', textAlign: 'center' }}>Additional videos may be in storage. Contact FlyWire for more information.</Typography>}

        {this.state.selectedCameraIndex != null && this.state.selectedSessionIndex != null ? (
          this.state.cameraVideoFolders[this.state.selectedCameraIndex]?.sessions[this.state.selectedSessionIndex]?.videos?.length === 0 ? (
            <Typography variant='body1'>No videos captured for selected camera and session.</Typography>
          ) : (
            <div>
              <ImageGallery
                ref={(i) => (this._imageGallery = i)}
                key={this.state.cameraVideoFolders[this.state.selectedCameraIndex]?.sessions[this.state.selectedSessionIndex]?.videos?.length > 0 ? this.state.cameraVideoFolders[this.state.selectedCameraIndex]?.sessions[this.state.selectedSessionIndex]?.videos[0]?.embedUrl : 1}
                items={this.state.cameraVideoFolders[this.state.selectedCameraIndex]?.sessions[this.state.selectedSessionIndex]?.videos}
                infinite={false}
                showPlayButton={false}
                showThumbnails={false}
                showFullscreenButton={false}
                lazyLoad
              />

              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <TextField
                  label='Jump to Clip'
                  value={this.state.displayedVideoIndexSelection}
                  onChange={(e) => this.setState({ displayedVideoIndexSelection: e.target.value })}
                  style={{ width: '150px' }}
                  select
                >
                  {Array.from({ length: this.state.cameraVideoFolders[this.state.selectedCameraIndex]?.sessions[this.state.selectedSessionIndex]?.videos?.length }, (_, i) => i).map((number) => (
                    <MenuItem key={number} value={number}>{number + 1}</MenuItem>
                  ))}
                </TextField>

                <Button onClick={this.goToClipNumber} style={{ width: '75px', marginLeft: '10px' }}>Go</Button>
              </div>
            </div>
          )
        ) : (
          <Typography variant='body1'>Please select a recording session to view videos.</Typography>
        )}
      </div>
    );
  }
}

VideoGallery.propTypes = {
  trip: PropTypes.object.isRequired
};

export default VideoGallery;

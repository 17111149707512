import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography
} from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Redux
import { clearErrors, setError } from '../../../../alerts';

// Constants
import { getMultiFactorUser, updateMfaPhoneNumber } from '../../../../api';

class TwoFactorPhoneNumber extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      phoneNumber: '',
      redirectToMfa: false,
    };
  }

  componentDidMount() {
    getMultiFactorUser().then(user => {
      this.setState({ user, phoneNumber: user.getPhoneNumber() });
    }).catch(error => {
      setError(error ?? 'Unable to retrieve user information.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    updateMfaPhoneNumber(this.state.phoneNumber).then(() => {
      this.setState({ redirectToMfa: true });
    }).catch(error => {
      setError(error ?? 'Unable to update 2FA phone number.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.redirectToMfa ? <Redirect to='/auth/2fa' /> : (
      <Container>
        <form autoComplete='off' onSubmit={this.onSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h1' style={{ margin: '1em', textAlign: 'center' }}>Change Two Factor Authentication Phone Number</Typography>

          {/* Phone Number */}
          <ReactPhoneInput
            country='us'
            preferredCountries={['us', 'cn']}
            value={this.state.phoneNumber}
            onChange={(phoneNumber) => this.setState({ phoneNumber })}
            component={TextField}
            inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.state.isLoading }}
            containerStyle={{ width: '300px', marginBottom: '10px' }}
          />

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
            <Button style={{ width: '150px', backgroundColor: 'grey' }} onClick={() => this.setState({ redirectToMfa: true })} disabled={this.state.isLoading}>Cancel</Button>
            <Button style={{ width: '150px', marginLeft: '10px' }} type='submit' disabled={this.state.isLoading}>
              Save
              {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

export default TwoFactorPhoneNumber;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, CircularProgress, TextField } from '@mui/material';

// Alerts
import { setError, setSuccess } from '../alerts';

// API
import { createBusiness, updateBusiness } from '../api/admin';

class BusinessForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      name: '',
      contactName: '',
      contactEmail: '',
      business: null
    };
  }

  componentDidMount() {
    if (this.props.business != null) {
      this.setState({
        name: this.props.business.getName(),
        contactName: this.props.business.getContactName(),
        contactEmail: this.props.business.getContactEmail()
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createBusiness = (e) => {
    e.preventDefault();

    const { name, contactName, contactEmail } = this.state;

    if (name === '' || contactName === '' || contactEmail === '') {
      setError('Error: All fields are required.');
      return;
    }

    this.setState({ isLoading: true });
    if (this.props.business == null) {
      createBusiness({ name, contactName, contactEmail }).then(business => {
        setSuccess('Successfully created the business.');
        this.setState({ isLoading: false, business });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to create business.');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateBusiness(this.props.business?.getID(), { name, contactName, contactEmail }).then(() => {
        setSuccess('Successfully updated business.');
        this.setState({ isLoading: false });
        this.props.onClose();
      }).catch(error => {
        setError(error ? error : 'Error: Unable to update business.');
        this.setState({ isLoading: false });
      });
    }
  }

  render() {
    return this.state.business != null ? <Redirect to={'/admin/businesses/' + this.state.business?.getID()} /> : (
      <form onSubmit={this.createBusiness}>
        {/* Name Input */}
        <TextField
          required
          name='name'
          label='Name'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.name}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        {/* Contact Name Input */}
        <TextField
          required
          name='contactName'
          label='Contact Name'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.contactName}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        {/* Contact Email Input */}
        <TextField
          required
          type='email'
          name='contactEmail'
          label='Contact Email'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.contactEmail}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        {/* Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 50 }}>
          {this.props.onClose &&
          <Button onClick={this.props.onClose} disabled={this.state.isLoading} style={{ backgroundColor: 'grey', marginRight: 10 }}>
            Cancel
          </Button>}
          <Button type='submit' disabled={this.state.isLoading}>
            {this.props.business != null ? 'Update' : 'Create'} Business
            {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
          </Button>
        </div>
      </form>
    );
  }
}

BusinessForm.propTypes = {
  business: PropTypes.object,
  onClose: PropTypes.func
};

export default BusinessForm;

import PropTypes from 'prop-types';
import React from 'react';
import { IconButton } from '@mui/material';

import { navigate } from 'react-big-calendar/lib/utils/constants';

import { Table } from './';
import { DATE_TIME_ZONE_FORMAT } from '../constants';

// ICONS
import VisibilityIcon from '@mui/icons-material/Visibility';

const TRIP_COLUMNS = (onSelectEvent) => ([
  {
    title: 'View',
    render: rowData => (
      <IconButton onClick={(e) => onSelectEvent(rowData, e)}><VisibilityIcon /></IconButton>
    )
  },
  {
    title: 'Boat',
    field: 'boatName'
  },
  {
    title: 'Start',
    render: rowData => (
      rowData.startDate?.setZone(rowData.timeZone)?.toLocaleString(DATE_TIME_ZONE_FORMAT)
    ),
    field: 'start',
    customFilterAndSearch: (term, rowData) => rowData.startDate?.setZone(rowData.timeZone)?.toLocaleString(DATE_TIME_ZONE_FORMAT)?.toUpperCase()?.includes(term.toUpperCase())
  },
  {
    title: 'End',
    render: rowData => (
      rowData.endDate?.setZone(rowData.timeZone)?.toLocaleString(DATE_TIME_ZONE_FORMAT)
    ),
    customFilterAndSearch: (term, rowData) => rowData.endDate?.setZone(rowData.timeZone)?.toLocaleString(DATE_TIME_ZONE_FORMAT)?.toUpperCase()?.includes(term.toUpperCase())
  }
]);

function Agenda({
  accessors,
  events,
  localizer,
  onSelectEvent
}) {

  let { messages } = localizer;

  events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

  return (
    <div className="rbc-agenda-view">
      {events.length !== 0 ? (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '99%' }}>
              <Table
                title={'Trip'}
                data={events}
                columns={TRIP_COLUMNS(onSelectEvent)}
                options={{ pageSize: 20, order: 'desc', orderByIdx: 1 }}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <span className="rbc-agenda-empty">{messages.noEventsInRange}</span>
      )}
    </div>
  );
}

Agenda.propTypes = {
  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  date: PropTypes.instanceOf(Date),
  events: PropTypes.array,
  length: PropTypes.number.isRequired,
  localizer: PropTypes.object.isRequired,
  onSelectEvent: PropTypes.func
};

Agenda.defaultProps = {
  length: 30,
};

Agenda.navigate = (
  date,
  action,
  { length = Agenda.defaultProps.length, localizer }
) => {
  switch (action) {
    case navigate.PREVIOUS:
      return localizer.add(date, -length, 'day');

    case navigate.NEXT:
      return localizer.add(date, length, 'day');

    default:
      return date;
  }
};

Agenda.title = () => {
  return '';
};

export default Agenda;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, TextField } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../alerts';

// API
import { createBoat, getBusiness, updateBoat } from '../api/admin';

// Components
import { LineItem } from '../components';

class BoatForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isBusinessLoading: false,
      name: '',
      awsIdentifier: '',
      business: null,
      boat: null
    };
  }

  componentDidMount() {
    if (this.props.boat != null) {
      this.setState({
        name: this.props.boat.getName(),
        awsIdentifier: this.props.boat.getAwsIdentifier()
      });
    }

    // Retrieve business object
    this.setState({ isBusinessLoading: true });
    let businessID = this.props.boat != null ? this.props.boat.getBusinessID() : this.props.businessID;
    getBusiness(businessID).then(business => {
      this.setState({ isBusinessLoading: false, business });
    }).catch(error => {
      this.setState({ isBusinessLoading: false });
      setError(error ? error : 'Error: Unable to retrieve business.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createBoat = (e) => {
    e.preventDefault();

    const { name, awsIdentifier } = this.state;

    if (name === '' || awsIdentifier === '') {
      setError('Error: All fields are required.');
      return;
    }

    this.setState({ isLoading: true });
    if (this.props.boat == null) {
      createBoat({ name, awsIdentifier, businessID: this.props.businessID }).then(boat => {
        setSuccess('Successfully created the boat.');
        this.setState({ isLoading: false, boat });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to create boat.');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateBoat(this.props.boat?.getID(), { name, awsIdentifier }).then(() => {
        setSuccess('Successfully updated boat.');
        this.setState({ isLoading: false });
        this.props.onClose();
      }).catch(error => {
        setError(error ? error : 'Error: Unable to update boat.');
        this.setState({ isLoading: false });
      });
    }
  }

  render() {
    return this.state.boat != null ? <Redirect to={'/admin/boats/' + this.state.boat?.getID()} /> : (
      <form onSubmit={this.createBoat}>

        <LineItem
          value={this.state.business?.getName()}
          description='Business'
        />

        {/* Name Input */}
        <TextField
          required
          name='name'
          label='Name'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.name}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        {/* AWS Identifier Input */}
        <TextField
          required
          name='awsIdentifier'
          label='AWS Identifier'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.awsIdentifier}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        {/* Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 50 }}>
          {this.props.onClose &&
          <Button onClick={this.props.onClose} disabled={this.state.isLoading} style={{ backgroundColor: 'grey', marginRight: 10 }}>
            Cancel
          </Button>}
          <Button type='submit' disabled={this.state.isLoading}>
            {this.props.boat != null ? 'Update' : 'Create'} Boat
            {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
          </Button>
        </div>
      </form>
    );
  }
}

BoatForm.propTypes = {
  setSuccess: PropTypes.func.isRequired,
  businessID: PropTypes.number,
  boat: PropTypes.object,
  onClose: PropTypes.func
};

export default withRouter(BoatForm);

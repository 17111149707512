import {
  IS_LOADING,
  IS_NOT_LOADING,
  SOFT_AUTHENTICATED,
  FULLY_AUTHENTICATED,
  NOT_AUTHENTICATED,
  SET_SKELETON_USER,
  CLEAR_SKELETON_USER
} from '../actions/authActions';

const initialState = {
  isLoading: true,
  isSoftAuthenticated: false,
  isFullyAuthenticated: false,
  twoFactorAuthSetupRequired: false,
  userType: null,
  skeletonUserID: null,
  skeletonUserName: null,
  skeletonUserUserType: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case IS_NOT_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case SOFT_AUTHENTICATED:
      return {
        isLoading: false,
        isSoftAuthenticated: true,
        isFullyAuthenticated: false,
        twoFactorAuthSetupRequired: action.twoFactorAuthSetupRequired,
        userType: action.userType
      };
    case FULLY_AUTHENTICATED:
      return {
        ...state,
        isLoading: false,
        isSoftAuthenticated: true,
        isFullyAuthenticated: true,
        twoFactorAuthSetupRequired: false,
        userType: action.userType
      };
    case NOT_AUTHENTICATED:
      return {
        userType: null,
        isLoading: false,
        isSoftAuthenticated: false,
        isFullyAuthenticated: false,
        skeletonUserID: null,
        skeletonUserName: null,
        skeletonUserUserType: null,
        twoFactorAuthSetupRequired: false
      };
    case SET_SKELETON_USER:
      return {
        ...state,
        skeletonUserID: action.userID,
        skeletonUserName: action.userName,
        skeletonUserUserType: action.userType
      };
    case CLEAR_SKELETON_USER:
      return {
        ...state,
        skeletonUserID: null,
        skeletonUserName: null,
        skeletonUserUserType: null
      };
    default:
      return state;
  }
};

export default auth;

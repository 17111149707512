import { DateTime } from 'luxon';

export default class TripPosition {
  static thaw(tripPositionObj) {
    if (tripPositionObj == null) return null;
    const tripPosition = new TripPosition();
    if (tripPositionObj.id != null) tripPosition.setID(tripPositionObj.id);
    if (tripPositionObj.latitude != null) tripPosition.setLatitude(tripPositionObj.latitude);
    if (tripPositionObj.longitude != null) tripPosition.setLongitude(tripPositionObj.longitude);
    if (tripPositionObj.timestamp != null) tripPosition.setTimestamp(DateTime.fromISO(tripPositionObj.timestamp));
    if (tripPositionObj.tripId != null) tripPosition.setTripID(tripPositionObj.tripId);
    return tripPosition;
  }

  static thawList(tripPositionObjList) {
    if (tripPositionObjList == null) return [];
    var tripPositions = [];
    for (var i = 0; i < tripPositionObjList.length; i++) {
      tripPositions.push(TripPosition.thaw(tripPositionObjList[i]));
    }
    return tripPositions;
  }

  static freeze(tripPosition) {
    var tripPositionObj = {};
    if (tripPosition.getID() != null) tripPositionObj.id = tripPosition.getID();
    if (tripPosition.getLatitude() != null) tripPositionObj.latitude = tripPosition.getLatitude();
    if (tripPosition.getLongitude() != null) tripPositionObj.longitude = tripPosition.getLongitude();
    if (tripPosition.getTimestamp() != null) tripPositionObj.timestamp = tripPosition.getTimestamp();
    if (tripPosition.getTripID() != null) tripPositionObj.tripId = tripPosition.getTripID();
    return tripPositionObj;
  }

  static freezeList(tripPositions) {
    if (tripPositions == null) return [];
    var tripPositionObjList = [];
    for (var i = 0; i < tripPositions.length; i++) {
      tripPositionObjList.push(TripPosition.freeze(tripPositions[i]));
    }
    return tripPositionObjList;
  }

  constructor() {
    this.setID(null);
    this.setLatitude(null);
    this.setLongitude(null);
    this.setTimestamp(null);
    this.setTripID(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setLatitude(latitude) {
    this.latitude = latitude;
  }

  setLongitude(longitude) {
    this.longitude = longitude;
  }

  setTimestamp(timestamp) {
    this.timestamp = timestamp;
  }

  setTripID(tripID) {
    this.tripID = tripID;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getLatitude() {
    return this.latitude;
  }

  getLongitude() {
    return this.longitude;
  }

  getTimestamp() {
    return this.timestamp;
  }

  getTripID() {
    return this.tripID;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getGoogleMapsPosition() {
    return {
      id: this.id,
      lat: this.latitude,
      lng: this.longitude
    };
  }

  static listGoogleMapsPositions(tripPositions) {
    return tripPositions?.map(position => position?.getGoogleMapsPosition());
  }
}

import PropTypes from 'prop-types';
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { VictoryAxis, VictoryBoxPlot, VictoryChart } from 'victory';

class SetHaulShiftDialog extends React.Component {
  constructor(props) {
    super(props);
  }


  onClose = () => {
    this.props.onClose();
  }

  render() {
    let tripStatistics = this.props.tripStatistics;
    let haulY = null;
    let setY = null;
    if (tripStatistics != null) {
      haulY = [tripStatistics.getHaulShiftMin(), tripStatistics.getHaulShiftQ1(), tripStatistics.getHaulShiftMedian(), tripStatistics.getHaulShiftQ3(), tripStatistics.getHaulShiftMax()];
      setY = [tripStatistics.getSetShiftMin(), tripStatistics.getSetShiftQ1(), tripStatistics.getSetShiftMedian(), tripStatistics.getSetShiftQ3(), tripStatistics.getSetShiftMax()];

      // Convert to hours from milliseconds
      haulY = haulY.map((value) => value == null ? null : value / (1000 * 60 * 60));
      setY = setY.map((value) => value == null ? null : value / (1000 * 60 * 60));
    }

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.onClose}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogContent dividers={scroll === 'paper'}>
            <Typography variant='h2'>Set / Haul Shift Distribution</Typography>

            {(haulY != null && setY != null && !haulY.includes(null) && !setY.includes(null)) ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='body1' style={{ writingMode: 'vertical-lr', transform: 'rotate(-180deg)', textAlign: 'center' }}>Shift Time (hours)</Typography>
                </div>

                
                <VictoryChart domainPadding={25}>
                  <VictoryAxis
                    tickValues={[0, 1, 2, 3]}
                    tickFormat={['', 'Set', 'Haul', '']}
                  />
                  <VictoryAxis
                    dependentAxis
                  />

                  {/* Set Shift Box Plot */}
                  <VictoryBoxPlot
                    boxWidth={20}
                    whiskerWidth={15}
                    data={[
                      { x: 1, y: setY }
                    ]}
                  />

                  {/* Haul Shift Box Plot */}
                  <VictoryBoxPlot
                    boxWidth={20}
                    whiskerWidth={15}
                    data={[
                      { x: 2, y: haulY }
                    ]}
                  />
                </VictoryChart>
              </div>
            ) : (
              <Typography variant='body1' style={{ marginTop: '20px' }}>No data available.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SetHaulShiftDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  tripStatistics: PropTypes.object,
  open: PropTypes.bool.isRequired
};

export default SetHaulShiftDialog;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import AuthRedirect from './AuthRedirect';

// Pages
import Satellite from '../pages/satellite';
import History from '../pages/history';
import GuestHistory from '../pages/history/guest';
import TripHistory from '../pages/history/trips/[id]';
import Login from '../pages/auth/login';
import TwoFactorPhoneNumber from '../pages/auth/2fa/phone-number';
import TwoFactor from '../pages/auth/2fa';
import CreateAccount from '../pages/auth/create-account/[token]';
import RequestForgotPassword from '../pages/auth/forgot-password/request';
import ConfirmForgotPassword from '../pages/auth/forgot-password/[token]';
import Account from '../pages/account';
import ConfirmChangeEmail from '../pages/account/confirm-change-email/[token]';

// Admin Pages
import UpdateGuestTripPermission from '../pages/admin/guest-trip-permission/[id]';
import Users from '../pages/admin/users';
import InviteGuest from '../pages/admin/users/invite';
import User from '../pages/admin/users/[id]';
import Business from '../pages/admin/businesses/[id]';
import InviteUser from '../pages/admin/businesses/[id]/invite';
import BusinessSharedTrips from '../pages/admin/businesses/[id]/shared-trips';
import CreateBusiness from '../pages/admin/businesses/create';
import Boat from '../pages/admin/boats/[id]';
import CreateTrip from '../pages/admin/trips/create';
import CreateBoat from '../pages/admin/boats/create';
import ConfigCamera from '../pages/admin/cameras/[id]/config';
import Camera from '../pages/admin/cameras/[id]';
import CreateCamera from '../pages/admin/cameras/create';
import SharedTrips from '../pages/admin/trips/[id]/shared';
import ShareTrip from '../pages/admin/trips/[id]/share';
import Trip from '../pages/admin/trips/[id]';
import Admin from '../pages/admin';
import CameraConfigRecords from '../pages/admin/cameras/[id]/config/records';

class RouteAndWrapper extends Component {
  render() {
    return (
      <Route path={this.props.path} exact={this.props.exact}>
        {AuthRedirect(this.props.location.pathname, this.props.authProps) || this.props.children}
      </Route>
    );
  }
}

RouteAndWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  location: PropTypes.object.isRequired,
  authProps: PropTypes.object.isRequired
};

const routeAndWrapperMapStateToProps = state => ({
  authProps: state.auth
});

const ConnectedRouteAndWrapper = connect(routeAndWrapperMapStateToProps, null)(withRouter(RouteAndWrapper));

export default class Navigation extends Component {
  render() {
    return (
      <div style={{ paddingBottom: '120px' }}>
        <Switch>
          <ConnectedRouteAndWrapper path='/admin/guest-trip-permission/:id' exact>
            <UpdateGuestTripPermission />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/users/invite' exact>
            <InviteGuest />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/users/:id' exact>
            <User />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/users' exact>
            <Users />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/cameras/create' exact>
            <CreateCamera />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/cameras/:id/config/records' exact>
            <CameraConfigRecords />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/cameras/:id/config' exact>
            <ConfigCamera />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/cameras/:id' exact>
            <Camera />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/boats/create' exact>
            <CreateBoat />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/trips/create' exact>
            <CreateTrip />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/trips/:id/share' exact>
            <ShareTrip />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/trips/:id/shared' exact>
            <SharedTrips />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/trips/:id' exact>
            <Trip />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/boats/:id' exact>
            <Boat />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/businesses/create' exact>
            <CreateBusiness />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/businesses/:id/shared-trips' exact>
            <BusinessSharedTrips />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/businesses/:id/invite' exact>
            <InviteUser />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/businesses/:id' exact>
            <Business />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin' exact>
            <Admin />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/forgot-password/request' exact>
            <RequestForgotPassword />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/forgot-password/:token' exact>
            <ConfirmForgotPassword />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/account/confirm-change-email/:token' exact>
            <ConfirmChangeEmail />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/account' exact>
            <Account />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/2fa/phone-number' exact>
            <TwoFactorPhoneNumber />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/2fa' exact>
            <TwoFactor />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/login' exact>
            <Login />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/create-account/:token' exact>
            <CreateAccount />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/satellite' exact>
            <Satellite />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/history/guest' exact>
            <GuestHistory />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/history/trips/:id' exact>
            <TripHistory />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/history' exact>
            <History />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/'>
            <Redirect to='/account' />
          </ConnectedRouteAndWrapper>
        </Switch>
      </div>
    );
  }
}

/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/lab';
import { Button } from '@mui/material';

// Redux
import { connect } from 'react-redux';
import { removeAlert } from '../redux/actions/alertActions';

class AlertList extends Component {
  render() {
    return (
      <>
        {this.props.alerts.map((alert, index) => (
          <Alert
            key={index}
            severity={alert.severity}
            onClose={alert.showClose ? () => this.props.removeAlert(alert.id) : null}
            action={
              alert.onButtonPress ? (
                <Button size='small' onClick={alert.onButtonPress}>
                  {alert.buttonText}
                </Button>
              ) : null
            }>
            {alert.msg}
          </Alert>
        ))}
      </>
    );
  }
}

AlertList.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alerts
});

const mapDispatchToProps = dispatch => ({
  removeAlert: id => dispatch(removeAlert(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);

import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../alerts';

// API
import { listGuestTripPermissions } from '../../../api';
import { Table } from '../../../components';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

class GuestHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guestTripPermissions: []
    };
  }

  componentDidMount() {
    listGuestTripPermissions().then(guestTripPermissions => {
      this.setState({ guestTripPermissions });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve list of boats.');
    });
  }

  render() {
    return this.state.redirectToTripID != null ? <Redirect to={'/history/trips/' + this.state.redirectToTripID} /> : (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px 50px 0px 50px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Select a Trip</Typography>

        <Table
          title='Shared Trips'
          data={this.state.guestTripPermissions}
          columns={[
            {
              title: 'Departure Date',
              render: rowData => rowData.getTrip()?.getStartTimestamp()?.toLocaleString()
            },
            {
              title: 'Business',
              render: rowData => rowData.getTrip()?.getBoat()?.getBusiness()?.getName()
            },
            {
              title: 'Boat',
              render: rowData => rowData.getTrip()?.getBoat()?.getName()
            },
            {
              title: 'View',
              render: rowData => <IconButton component={Link} to={`/history/trips/${rowData.getTrip()?.getID()}`} variant='contained' color='primary' style={{ width: 150 }}><VisibilityIcon /></IconButton>
            }
          ]}
          options={{ pageSize: 20 }}
        />
      </div>
    );
  }
}

export default GuestHistory;

import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, ListSubheader } from '@mui/material';

const indentBorder = '3px inset #aaa3';

/**
 * @param {object} props
 * @param {object | string | number | boolean | null} props.entry
 * @param {string | number} props.name
 * @param {number} props.indentation
 */
const ConfigEntry = ({ entry, name, indentation }) => {
  if (Array.isArray(entry)) {
    return (<List dense component="div" disablePadding sx={{ pl: `${indentation}rem` }} subheader={
      typeof name === 'string' ? <ListSubheader sx={{ position: 'relative' }} component="div" disableGutters>{name}</ListSubheader> : null
    }>
      {entry.map((entryValue, index) => {
        return (
          <div style={{ borderLeft: indentBorder }} key={index}>
            <ConfigEntry entry={typeof entryValue === 'object' ? '• ' + JSON.stringify(entryValue) : '• ' + entryValue} name={index} indentation={indentation} />
          </div>
        );
      })}
    </List>);
  }
  else if (typeof entry === 'string' || typeof entry === 'number' || typeof entry === 'boolean' || entry === null) {
    return (
      <ListItem dense disableGutters key={name} sx={{ pl: `${indentation}rem` }}>
        <ListItemText primary={entry.toString()} secondary={typeof name === 'number' ? undefined : name} />
      </ListItem>
    );
  }
  else if (typeof entry === 'object') {
    return (<List dense component="div" disablePadding sx={{ pl: `${indentation}rem` }} subheader={
      typeof name === 'string' ? <ListSubheader sx={{ position: 'relative' }} component="div" disableGutters>{name}</ListSubheader> : null
    }>
      {Object.entries(entry).map(([entryKey, entryValue], index) => {
        return (
          <div style={{ borderLeft: indentBorder, paddingLeft: '0.5rem' }} key={index}>
            <ConfigEntry key={index} entry={entryValue} name={entryKey} indentation={indentation + 0.5} />
          </div>
        );
      })}
    </List>);
  }
  else {
    return null;
  }
};

ConfigEntry.propTypes = {
  entry: PropTypes.any.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  indentation: PropTypes.number.isRequired,
};

/**
 * @param {object} props
 * @param {string} props.fileName
 * @param {string} props.fileContent
 */
const ConfigFilePreview = ({ fileContent }) => {
  if (fileContent == null) {
    return <p>No associated file</p>;
  }
  const entries = JSON.parse(fileContent);
  return (
    <List sx={{
      display: 'block',
      bgcolor: 'background.paper',
      position: 'relative',
      overflow: 'auto',
    }}
    >
      {Object.keys(entries).map((key) => {
        return (
          <ConfigEntry key={key} entry={entries[key]} name={key} indentation={0} />
        );
      }
      )}
    </List>
  );
};

ConfigFilePreview.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileContent: PropTypes.string.isRequired
};

export default ConfigFilePreview;

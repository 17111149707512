import { Button, Grid } from '@mui/material';
import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';

export default class CalendarToolbar extends Toolbar {

  render() {
    if (this.props.view == 'agenda') {
      return (
        <Grid container className='rbc-toolbar' rowSpacing={1}>
          <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'center', md: 'flex-start' } }}>
          </Grid>
          <Grid xs={12} md={2} item className="rbc-toolbar-label">{this.props.label}</Grid>
          <Grid xs={12} md={5} item sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'center', md: 'flex-end' } }}>
            <Button style={{ width: '100px' }} onClick={this.view.bind(null, 'month')}>Month</Button>
            <Button style={{ width: '100px' }} onClick={this.view.bind(null, 'agenda')}>Historical</Button>
          </Grid>
        </Grid>
      );
    }
    else {
      return (
        <Grid container className='rbc-toolbar' rowSpacing={1}>
          <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'center', md: 'flex-start' } }}>
            <Button style={{ width: '100px' }} onClick={() => this.navigate('TODAY')}>Today</Button>
            <Button style={{ width: '100px' }} onClick={() => this.navigate('PREV')}>Back</Button>
            <Button style={{ width: '100px' }} onClick={() => this.navigate('NEXT')}>Next</Button>
          </Grid>
          <Grid xs={12} md={2} item className="rbc-toolbar-label">{this.props.label}</Grid>
          <Grid xs={12} md={5} item sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'center', md: 'flex-end' } }}>
            <Button style={{ width: '100px' }} onClick={this.view.bind(null, 'month')}>Month</Button>
            <Button style={{ width: '100px' }} onClick={this.view.bind(null, 'agenda')}>Historical</Button>
          </Grid>
        </Grid>
      );
    }
  }
}
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { NAVY, WHITE } from '../constants';

function ConfirmationModal({ open, onClose, onConfirm, title = null, body = null, isLoading = false }) {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus={true}
    >
      {title &&
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

      {body &&
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>}

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading} style={{ borderColor: NAVY, color: NAVY }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} autoFocus disabled={isLoading} style={{ backgroundColor: NAVY, color: WHITE }}>
          Confirm
          {isLoading &&
            <CircularProgress size={15} style={{ color: WHITE, marginLeft: '10px' }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ConfirmationModal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Button, CircularProgress, Container, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../alerts';

// API
import { getCamera, updateCamera, toggleCameraActivation, resetCamera, getLatestSatelliteLogMessages, getSatelliteOutgoingCommands } from '../../../../api/admin';

// Components
import { BackToButton, CameraForm, ConfirmationModal, LineItem, Table } from '../../../../components';
import { DATE_TIME_ZONE_FORMAT } from '../../../../constants';

const SATELLITE_ERROR_COLUMNS = [
  {
    title: 'Received At',
    render: rowData => (
      rowData?.getReceivedAt()?.toLocaleString(DATE_TIME_ZONE_FORMAT)
    )
  },
  { title: 'Message', field: 'message' },
];

const OUTGOING_SATELLITE_COMMAND_COLUMNS = [
  {
    title: 'Sent At',
    render: rowData => (
      rowData?.getTimestamp()?.toLocaleString(DATE_TIME_ZONE_FORMAT)
    )
  },
  { title: 'Data', field: 'data' },
  { title: 'Response', field: 'response' },
];

class Camera extends Component {
  constructor(props) {
    super(props);

    this.state = {
      camera: null,
      isLoadingCamera: true,
      isLoading: false,
      confirmResetCamera: false,
      resetCameraIsLoading: false,
      satelliteErrors: [],
      satelliteOutgoingCommands: [],
      autoReset: false
    };
  }

  componentDidMount() {
    this.refreshCamera();
  }

  componentWillUnmount() {
    clearErrors();
  }

  refreshCamera() {
    this.setState({ isLoadingCamera: true });
    const cameraID = this.props.match?.params?.id;

    getCamera(cameraID).then(camera => {
      this.setState({
        camera: camera,
        name: camera?.getName(),
        awsIdentifier: camera?.getAwsIdentifier(),
        rockBlockImei: camera?.getRockBlockImei(),
        configurationFiles: camera?.getConfigurationFiles()?.map(e => e.getFileName()),
        isLoadingCamera: false,
        autoReset: camera.getAutoReset()
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve camera.');
      this.setState({ isLoadingCamera: false });
    });

    getLatestSatelliteLogMessages(cameraID).then(satelliteErrors => {
      this.setState({ satelliteErrors });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve satellite logs.');
    });

    getSatelliteOutgoingCommands(cameraID).then(satelliteOutgoingCommands => {
      this.setState({ satelliteOutgoingCommands });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve satellite outgoing commands.');
    });
  }

  resetCamera() {
    this.setState({ resetCameraIsLoading: true });
    const cameraID = this.props.match?.params?.id;

    resetCamera(cameraID).then(() => {
      setSuccess('Successfully reset camera.');
      this.setState({ confirmResetCamera: false, resetCameraIsLoading: false });
    }).catch(error => {
      this.setState({ confirmResetCamera: false, resetCameraIsLoading: false });
      setError(error ? error : 'Error: Unable to reset camera.');
    });
  }

  toggleCameraActivation = (isActive) => {
    this.setState({ isLoading: true });
    toggleCameraActivation(this.state.camera?.getID(), isActive).then(() => {
      setSuccess('Successfully ' + (isActive ? '' : 'de') + 'activated camera.');

      this.setState({ isLoading: false });
      this.refreshCamera();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to ' + (isActive ? '' : 'de') + 'activate camera.');
      this.setState({ isLoading: false });
    });
  }

  onCloseForm = () => {
    this.setState({ inEditMode: false });
    this.refreshCamera();
  }

  onAutoResetChange = () => {
    this.setState({ autoReset: !this.state.autoReset }, () => {
      updateCamera(this.state.camera?.getID(), { autoReset: this.state.autoReset }).then(() => {
        setSuccess('Successfully updated camera.');
      }).catch(error => {
        setError(error ? error : 'Error: Unable to update camera.');
        this.setState({ autoReset: this.state.camera.getAutoReset() });
      });
    });
  }

  render() {
    return (
      <>
        <ConfirmationModal
          title='Reset Camera'
          body='Are you sure you want to reset this camera?'
          open={this.state.confirmResetCamera}
          onClose={() => this.setState({ confirmResetCamera: false })}
          onConfirm={() => this.resetCamera()}
          isLoading={this.state.resetCameraIsLoading}
        />

        <Container style={{ marginTop: 30 }}>
          <BackToButton to={'/admin/boats/' + this.state.camera?.getBoatID()} description={this.state.camera?.getBoat()?.getName()} />

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h1'>{this.state.inEditMode && 'Edit'} {this.state.camera?.getName()}</Typography>
              {this.state.isLoadingCamera && <CircularProgress style={{ marginLeft: '20px' }} />}
            </div>
            <Typography variant='subtitle' style={{ color: this.state.camera?.getIsActive() ? 'green' : 'red', fontStyle: 'italic' }}>{this.state.camera?.getIsActive() ? 'Active' : 'Inactive'}</Typography>
          </div>

          {/* View Mode */}
          {!this.state.inEditMode &&
          <>
            <LineItem value={this.state.camera?.getName()} description='Name' />
            <LineItem value={this.state.camera?.getAwsIdentifier()} description='AWS Identifier' />
            <LineItem value={this.state.camera?.getRockBlockImei()} description='Satellite IMEI' />
            <LineItem value={this.state.camera?.getConfigurationFiles()?.map(e => e.getFileName()).join(', ')} description='Configuration Files' />

            {/* Buttons */}
            <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
              <Button onClick={() => this.setState({ inEditMode: true })} style={{ marginBottom: 10 }} disabled={this.state.isLoadingCamera || !this.state.camera}>Edit Camera</Button>
              <Tooltip title={this.state.camera?.getIsActive() ? 'Warning: This will prevent users from viewing this camera.' : 'Warning: This will allow users to view this camera.'}>
                <Button variant='outlined' onClick={() => this.toggleCameraActivation(!this.state.camera?.getIsActive())} style={{ marginBottom: 10 }} disabled={this.state.isLoadingCamera || !this.state.camera}>{this.state.camera?.getIsActive() ? 'Deactivate' : 'Activate'} Camera</Button>
              </Tooltip>
            </div>

            <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Device Management</Typography>
            {/* Buttons */}
            <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
              <Button
                variant='contained'
                component={Link}
                to={'/admin/cameras/' + this.props.match?.params?.id + '/config'}
                style={{ marginBottom: 10 }}
                disabled={this.state.isLoadingCamera || !this.state.camera}
              >
                Edit Configuration
              </Button>
              <Button
                variant='contained'
                component={Link}
                to={'/admin/cameras/' + this.props.match?.params?.id + '/config/records'}
                style={{ marginBottom: 10 }}
                disabled={this.state.isLoadingCamera || !this.state.camera}
              >
                View Config Records
              </Button>
              <Button
                variant='outlined'
                onClick={() => this.setState({ confirmResetCamera: true })}
                style={{ marginBottom: 10 }}
                disabled={this.state.isLoadingCamera || !this.state.camera}
              >
                Reset Camera
              </Button>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={this.state.camera?.getAutoReset()}
                    checked={this.state.autoReset}
                    onChange={this.onAutoResetChange}
                  />}
                label="Auto Reset" />
            </div>

            <div style={{ margin: '20px' }}>
              <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1.5em' }}>Recent Satellite Log Messages</Typography>
              <Table
                title={''}
                data={this.state.satelliteErrors}
                columns={SATELLITE_ERROR_COLUMNS}
                options={{ pageSize: 10 }}
              />
              <Typography variant='caption' style={{ marginTop: '0.3em' }}>Limited to 100 entries.</Typography>

              <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1.5em' }}>Recent Outgoing Satellite Commands</Typography>
              <Table
                title={''}
                data={this.state.satelliteOutgoingCommands}
                columns={OUTGOING_SATELLITE_COMMAND_COLUMNS}
                options={{ pageSize: 10 }}
              />
              <Typography variant='caption' style={{ marginTop: '0.3em' }}>Limited to 50 entries.</Typography>
            </div>
          </>}

          {/* Edit Mode */}
          {this.state.inEditMode &&
          <CameraForm
            camera={this.state.camera}
            onClose={this.onCloseForm}
          />}
        </Container>
      </>
    );
  }
}

Camera.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Camera);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, CircularProgress, Container, Grid, MenuItem, TextField, Typography } from '@mui/material';

// Redux
import { connect } from 'react-redux';

// Alerts
import { setError } from '../../alerts';

// Components
import { SSSMapWidget, LineItem } from '../../components';

// API
import { listBoats, listSatelliteMessages } from '../../api';

// Constants
import { DATE_TIME_ZONE_FORMAT, USER_TYPES } from '../../constants';
const CAMERA_COLORS = [
  '#FF1493', // deep pink
  '#8A2BE2', // purple
  '#A52A2A', // brown
  '#FF7F50', // coral
  '#B22222', // fire brick
  '#DAA520', // golden rod
  '#7CFC00', // lawn green
  '#4B0082', // indigo
  '#FF8C00', // dark orange
  '#FFE4B5' // tan
];

class Satellite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boatID: '',
      boats: [],
      satelliteMessages: [],
      isLoadingBoats: false,
      isLoadingSatelliteMessages: false,
      satelliteMessageID: null,
      selectedCamerasMap: new Map()
    };
  }

  componentDidMount() {
    this.loadBoats();
  }

  loadBoats() {
    this.setState({ isLoadingBoats: true });
    listBoats().then(boats => {
      boats.forEach(boat => {
        boat.getCameras()?.forEach((camera, index) => {
          this.state.selectedCamerasMap.set(camera?.getID(), true);
          camera.setDisplayColor(this.props.userType === USER_TYPES.ADMIN ? CAMERA_COLORS[index % CAMERA_COLORS.length] : CAMERA_COLORS[0]);
        });
      });
      this.setState({ boats, boatID: boats?.length > 0 ? boats[0]?.getID() : null, isLoadingBoats: false, selectedCamerasMap: this.state.selectedCamerasMap }, () => {
        if (this.state.boats?.length > 0) {
          this.loadSatelliteMessages();
        }
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve list of boats.');
      this.setState({ isLoadingBoats: false });
    });
  }

  loadSatelliteMessages() {
    this.setState({ isLoadingSatelliteMessages: true });
    listSatelliteMessages(this.state.boatID).then(satelliteMessages => {
      this.setState({ satelliteMessages: satelliteMessages, isLoadingSatelliteMessages: false, satelliteMessageID: satelliteMessages.length > 0 ? satelliteMessages[0].getID() : null });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve list of satellite messages.');
      this.setState({ isLoadingSatelliteMessages: false });
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      // Retrieve GPS data and sensor data
      if (event.target.name === 'boatID') {
        this.loadSatelliteMessages();
      }
    });
  }

  onCheckboxChange = (event) => {
    const cameraID = parseInt(event.target.name);
    const isChecked = this.state.selectedCamerasMap.get(cameraID) == null ? true : event.target.checked;
    this.state.selectedCamerasMap.set(cameraID, isChecked);
    this.setState({ selectedCamerasMap: this.state.selectedCamerasMap });
  }

  render() {
    if ((this.state.isLoadingBoats || this.state.isLoadingSatelliteMessages)) {
      return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 30 }}>
          <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Real-Time Data Center</Typography>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
            <CircularProgress />
          </div>
        </Container>
      );
    }
    if (!(this.state.isLoadingBoats || this.state.isLoadingSatelliteMessages) && this.state.boats?.length == 0) {
      return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 30 }}>
          <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Real-Time Data Center</Typography>
          <div>
            No boats associated with your business.
          </div>
        </Container>
      );
    }

    let selectedSatelliteMessage = this.state.satelliteMessages?.find(message => message.getID() === this.state.satelliteMessageID) ?? null;

    return (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 30 }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Real-Time Data Center</Typography>

        {/* Boat Selector */}
        <TextField
          select
          name='boatID'
          value={this.state.boatID}
          label='Select Boat'
          onChange={this.handleChange}
          style={{ width: '100%', marginBottom: 20 }}
        >
          {this.state.boats?.map(boat => (
            <MenuItem key={boat.getID()} value={boat.getID()}>{boat.getName()}</MenuItem>
          ))}
        </TextField>

        {/* List of Cameras */}
        {this.props.userType === USER_TYPES.ADMIN &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20 }}>
          <Typography variant='overline'>Cameras</Typography>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {this.state.boats?.find(boat => boat.getID() === this.state.boatID)?.getCameras()?.map((camera, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox name={camera.getID()?.toString()} defaultChecked onChange={this.onCheckboxChange} sx={{ color: camera.getDisplayColor(), '&.Mui-checked': { color: camera.getDisplayColor() } }} />
                <Typography variant='body1' style={{ color: camera.getDisplayColor(), fontWeight: 'bold' }}>{camera.getName()}</Typography>
              </div>
            ))}
          </div>
        </div>}

        {/* No Satellite Messages Message */}
        {!this.state.isLoadingSatelliteMessages && this.state.satelliteMessages?.length === 0 &&
        <Typography variant='body1'>No satellite data to display for this boat.</Typography>}

        {/* Map Widget & Locations */}
        {this.state.satelliteMessages?.length > 0 &&
        <SSSMapWidget
          satelliteMessages={this.state.satelliteMessages?.filter(satelliteMessage => {
            // Filter only the satellite messages for the selected cameras
            let showSatelliteMessage = false;
            this.state.selectedCamerasMap?.forEach((value, key) => {
              if (key === satelliteMessage?.getCamera()?.getID() && value) showSatelliteMessage = true;
            });
            return showSatelliteMessage;
          })?.map(satelliteMessage => {
            // Add the satelliteMessage color
            const cameraID = satelliteMessage.getCamera()?.getID();
            const displayColor = this.state.boats?.find(boat => boat.getID() === this.state.boatID)?.getCameras()?.find(camera => camera.getID() === cameraID)?.getDisplayColor();

            satelliteMessage.setDisplayColor(displayColor);

            return satelliteMessage;
          })}
          height={350}
          width={'100%'}
          style={{ marginBottom: 20 }}
          onSelect={(satelliteMessageID) => this.setState({ satelliteMessageID })}
          selectedSatelliteMessageID={this.state.satelliteMessageID}
        />}

        {/* All Data */}
        {selectedSatelliteMessage != null &&
        <Grid container direction='row' style={{ justifyContent: 'center' }}>

          <Grid item style={{ width: 325 }}>
            <LineItem
              description='Last Update'
              value={selectedSatelliteMessage.getRecordedTimestamp()?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
              centered
            />
          </Grid>

          {selectedSatelliteMessage?.getCameraInputs()?.map((cameraInput, index) => {
            let description = cameraInput.getCameraKeyType().getDescription();
            let units = cameraInput.getCameraKeyType().getUnits() != null ? ' [' + cameraInput.getCameraKeyType().getUnits() + ']' : '';
            let inputNumber = cameraInput.getInputNumber() != null ? ' [input ' + cameraInput.getInputNumber() + ']' : '';
            return (
              <Grid key={index} item style={{ width: 325 }}>
                <LineItem
                  description={description + (cameraInput.getDisplayValue() === cameraInput.getValue() ? units : '') + inputNumber}
                  value={cameraInput.getDisplayValue()}
                  centered
                  fixed={2}
                />
              </Grid>
            );
          })}
          {selectedSatelliteMessage.getUsbUtilizedPercentage() != null &&
          <Grid item style={{ width: 325 }}>
            <LineItem
              description='USB Utilized Percentage'
              value={selectedSatelliteMessage.getUsbUtilizedPercentage() === -1 ? 'N/A' : `${selectedSatelliteMessage.getUsbUtilizedPercentage()}%`}
              centered
            />
          </Grid>}
          {selectedSatelliteMessage.getShutdownSwitch() != null &&
          <Grid item style={{ width: 325 }}>
            <LineItem
              description='Shutdown Switch'
              value={selectedSatelliteMessage.getShutdownSwitch() === 1 ? 'Shutdown' : 'Running'}
              centered
            />
          </Grid>}
          {selectedSatelliteMessage.getFirmwareState() != null &&
          <Grid item style={{ width: 325 }}>
            <LineItem
              description='Firmware State'
              value={selectedSatelliteMessage.getFirmwareStateText()}
              centered
            />
          </Grid>}
          <Grid item style={{ width: 325 }}>
            <LineItem
              description='Satellite Latitude [Est]'
              value={selectedSatelliteMessage.getLatitude()}
              centered
              fixed={2}
            />
          </Grid>
          <Grid item style={{ width: 325 }}>
            <LineItem
              description='Satellite Longitude [Est]'
              value={selectedSatelliteMessage.getLongitude()}
              centered
              fixed={2}
            />
          </Grid>
          {selectedSatelliteMessage.getCep() != null &&
          <Grid item style={{ width: 325 }}>
            <LineItem
              description='Satellite Estimated Accuracy [+/- Km]'
              value={selectedSatelliteMessage.getCep()}
              centered
              fixed={2}
            />
          </Grid>}
        </Grid>}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userType: state.auth.userType
});

Satellite.propTypes = {
  userType: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(Satellite);

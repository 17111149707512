import PropTypes from 'prop-types';
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Table } from '.';
import { FishCatch } from '../lib';
import { LBS_TO_KG } from '../constants';

const FISH_COLUMNS = ({ convertToKg }) => ([
  {
    title: 'Species',
    field: 'speciesName'
  },
  {
    title: `Weight ${convertToKg ? '(kg)' : '(lbs)'}`,
    render: rowData => (
      convertToKg ? (rowData.getWeight() * LBS_TO_KG).toFixed(1) : rowData.getWeight().toFixed(1)
    ),
    customSort: (a, b) => {
      const aWeight = a.getWeight();
      const bWeight = b.getWeight();
      
      return aWeight - bWeight;
    }
  }
]);

class FishCatchDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  onClose = () => {
    this.props.onClose();
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.onClose}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogContent dividers={scroll === 'paper'}>
            <Table
              title={this.props.title}
              data={FishCatch.groupList(this.props.fishCatch)}
              columns={FISH_COLUMNS({ convertToKg: this.props.convertToKg })}
              options={{ pageSize: 20, order: 'desc', orderByIdx: 1 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FishCatchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  fishCatch: PropTypes.array,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  convertToKg: PropTypes.bool
};

export default FishCatchDialog;
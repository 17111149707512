import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Checkbox, Grid, Typography } from '@mui/material';

// Components
import { CustomAgenda, CustomToolbar } from '../../components';

// Alerts
import { setError } from '../../alerts';

// API
import { listBoats, listTrips } from '../../api';

// Constants
const localizer = momentLocalizer(moment);
const BOAT_COLORS = [
  '#FF1493', // deep pink
  '#8A2BE2', // purple
  '#A52A2A', // brown
  '#FF7F50', // coral
  '#B22222', // fire brick
  '#DAA520', // golden rod
  '#7CFC00', // lawn green
  '#4B0082', // indigo
  '#FF8C00', // dark orange
  '#FFE4B5' // tan
];

class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boats: [],
      isLoadingBoats: false,
      selectedBoatsMap: new Map(),
      events: [],
      redirectToTripID: null
    };
  }

  componentDidMount() {
    this.loadBoats();
  }

  loadBoats() {
    this.setState({ isLoadingBoats: true });
    listBoats().then(boats => {
      boats.forEach((boat, index) => {
        this.state.selectedBoatsMap.set(boat.getID(), true);
        boat.setDisplayColor(BOAT_COLORS[index % BOAT_COLORS.length]);
      });
      this.setState({ boats, isLoadingBoats: false }, () => this.loadTrips());
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve list of boats.');
      this.setState({ isLoadingBoats: false });
    });
  }

  loadTrips() {
    this.state.boats?.forEach(boat => {
      listTrips(boat.getID()).then(trips => {
        trips.forEach(trip => {
          this.state.events.push({
            id: trip.getID(),
            boatID: boat.getID(),
            boatName: boat.getName(),
            title: boat.getName() + ': ' + trip.getStartTimestamp().toLocaleString() + ' - ' + trip.getStopTimestamp().toLocaleString(),
            startDate: trip.getStartTimestamp(),
            endDate: trip.getStopTimestamp(),
            timeZone: trip.getTimezone(),
            start: new Date(trip.getStartTimestamp()),
            end: new Date(trip.getStopTimestamp()),
            color: boat.getDisplayColor()
          });
        });

        boat.setTrips(trips);
        this.setState({ boats: this.state.boats, events: this.state.events });

      }).catch(error => {
        setError(error ? error : 'Error: Unable to retrieve list of trips.');
      });
    });
  }

  onCheckboxChange = (event) => {
    const boatID = parseInt(event.target.name);
    const isChecked = this.state.selectedBoatsMap.get(boatID) == null ? true : event.target.checked;
    this.state.selectedBoatsMap.set(boatID, isChecked);
    this.setState({ selectedBoatsMap: this.state.selectedBoatsMap });
  }

  eventPropGetter = (event) => {
    return { style: { backgroundColor: event.color, fontSize: 10 } };
  }

  render() {
    return this.state.redirectToTripID != null ? <Redirect to={'/history/trips/' + this.state.redirectToTripID} /> : (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px 50px 0px 50px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Select a Trip</Typography>

        <Grid container columnSpacing={{ md: 5 }} rowSpacing={{ xs: 2 }}>
          {/* List of Boats */}
          <Grid item xs={12} md={2}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: 15 }}>
              {this.state.boats?.map((boat, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox name={boat.getID()} defaultChecked onChange={this.onCheckboxChange} sx={{ color: boat.getDisplayColor(), '&.Mui-checked': { color: boat.getDisplayColor() } }} />
                  <Typography variant='body1' style={{ color: boat.getDisplayColor(), fontWeight: 'bold' }}>{boat.getName()}</Typography>
                </div>
              ))}
            </div>
          </Grid>

          {/* Calendar View */}
          <Grid item xs={12} md={10}>
            <Calendar
              localizer={localizer}
              style={{ height: 500, width: '100%' }}
              views={{
                month: true,
                agenda: CustomAgenda
              }}
              components={{
                toolbar: CustomToolbar
              }}
              length={365}
              defaultDate={new Date()}
              events={this.state.events.filter(ev => this.state.selectedBoatsMap.get(ev.boatID))}
              eventPropGetter={this.eventPropGetter}
              selectable={true}
              popup
              onSelectEvent={(trip) => this.setState({ redirectToTripID: trip?.id })}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default History;

export default class Role {
  static thaw(roleObj) {
    if (roleObj == null) return null;
    const role = new Role();
    if (roleObj.id != null) role.setID(roleObj.id);
    if (roleObj.name != null) role.setName(roleObj.name);
    return role;
  }

  static thawList(roleObjList) {
    if (roleObjList == null) return [];
    var roles = [];
    for (var i = 0; i < roleObjList.length; i++) {
      roles.push(Role.thaw(roleObjList[i]));
    }
    return roles;
  }

  static freeze(role) {
    var roleObj = {};
    if (role.getID() != null) roleObj.id = role.getID();
    if (role.getName() != null) roleObj.name = role.getName();
    return roleObj;
  }

  static freezeList(roles) {
    if (roles == null) return [];
    var roleObjList = [];
    for (var i = 0; i < roles.length; i++) {
      roleObjList.push(Role.freeze(roles[i]));
    }
    return roleObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import { TripHaul, TripPosition } from './';
import { DateTime } from 'luxon';

export const SET = 'set';

export default class TripSet {
  static thaw(tripSetObj) {
    if (tripSetObj == null) return null;
    const tripSet = new TripSet();
    if (tripSetObj.id != null) tripSet.setID(tripSetObj.id);
    if (tripSetObj.startTimestamp != null) tripSet.setStartTimestamp(DateTime.fromISO(tripSetObj.startTimestamp));
    if (tripSetObj.stopTimestamp != null) tripSet.setStopTimestamp(DateTime.fromISO(tripSetObj.stopTimestamp));
    if (tripSetObj.elapsedTime != null) tripSet.setElapsedTime(tripSetObj.elapsedTime);
    if (tripSetObj.gearType != null) tripSet.setGearType(tripSetObj.gearType);
    if (tripSetObj.gearSubtype != null) tripSet.setGearSubtype(tripSetObj.gearSubtype);
    if (tripSetObj.gearAmount != null) tripSet.setGearAmount(tripSetObj.gearAmount);
    if (tripSetObj.totalDistance != null) tripSet.setTotalDistance(tripSetObj.totalDistance);
    if (tripSetObj.averageSpeed != null) tripSet.setAverageSpeed(tripSetObj.averageSpeed);
    if (tripSetObj.tripId != null) tripSet.setTripID(tripSetObj.tripId);
    if (tripSetObj.haul != null) tripSet.setHaul(TripHaul.thaw(tripSetObj.haul));
    if (tripSetObj.positions != null) tripSet.setPositions(TripPosition.thawList(tripSetObj.positions));
    if (tripSetObj.depth != null) tripSet.setDepth(tripSetObj.depth);
    if (tripSetObj.crewAmount != null) tripSet.setCrewAmount(tripSetObj.crewAmount);
    if (tripSetObj.excludeFromCalculations != null) tripSet.setExcludeFromCalculations(tripSetObj.excludeFromCalculations == null ? null : (tripSetObj.excludeFromCalculations ? true : false));
    return tripSet;
  }

  static thawList(tripSetObjList) {
    if (tripSetObjList == null) return [];
    var tripSets = [];
    for (var i = 0; i < tripSetObjList.length; i++) {
      tripSets.push(TripSet.thaw(tripSetObjList[i]));
    }
    return tripSets;
  }

  static freeze(tripSet) {
    var tripSetObj = {};
    if (tripSet.getID() != null) tripSetObj.id = tripSet.getID();
    if (tripSet.getStartTimestamp() != null) tripSetObj.startTimestamp = tripSet.getStartTimestamp();
    if (tripSet.getStopTimestamp() != null) tripSetObj.stopTimestamp = tripSet.getStopTimestamp();
    if (tripSet.getElapsedTime() != null) tripSetObj.elapsedTime = tripSet.getElapsedTime();
    if (tripSet.getGearType() != null) tripSetObj.gearType = tripSet.getGearType();
    if (tripSet.getGearSubtype() != null) tripSetObj.gearSubtype = tripSet.getGearSubtype();
    if (tripSet.getGearAmount() != null) tripSetObj.gearAmount = tripSet.getGearAmount();
    if (tripSet.getTotalDistance() != null) tripSetObj.totalDistance = tripSet.getTotalDistance();
    if (tripSet.getAverageSpeed() != null) tripSetObj.averageSpeed = tripSet.getAverageSpeed();
    if (tripSet.getTripID() != null) tripSetObj.tripId = tripSet.getTripID();
    if (tripSet.getHaul() != null) tripSetObj.haul = TripHaul.freeze(tripSet.getHaul());
    if (tripSet.getDepth() != null) tripSetObj.depth = tripSet.getDepth();
    if (tripSet.getCrewAmount() != null) tripSetObj.crewAmount = tripSet.getCrewAmount();
    if (tripSet.getExcludeFromCalculations() != null) tripSetObj.excludeFromCalculations = tripSet.getExcludeFromCalculations();
    return tripSetObj;
  }

  static freezeList(tripSets) {
    if (tripSets == null) return [];
    var tripSetObjList = [];
    for (var i = 0; i < tripSets.length; i++) {
      tripSetObjList.push(TripSet.freeze(tripSets[i]));
    }
    return tripSetObjList;
  }

  constructor() {
    this.setID(null);
    this.setStartTimestamp(null);
    this.setStopTimestamp(null);
    this.setElapsedTime(null);
    this.setGearType(null);
    this.setGearSubtype(null);
    this.setGearAmount(null);
    this.setTotalDistance(null);
    this.setAverageSpeed(null);
    this.setTripID(null);
    this.setHaul(null);
    this.setPositions(null);
    this.setDepth(null);
    this.setCrewAmount(null);
    this.setExcludeFromCalculations(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setStartTimestamp(startTimestamp) {
    this.startTimestamp = startTimestamp;
  }

  setStopTimestamp(stopTimestamp) {
    this.stopTimestamp = stopTimestamp;
  }

  setElapsedTime(elapsedTime) {
    this.elapsedTime = elapsedTime;
  }

  setGearType(gearType) {
    this.gearType = gearType;
  }

  setGearSubtype(gearSubtype) {
    this.gearSubtype = gearSubtype;
  }

  setGearAmount(gearAmount) {
    this.gearAmount = gearAmount;
  }

  setTotalDistance(totalDistance) {
    this.totalDistance = totalDistance;
  }

  setAverageSpeed(averageSpeed) {
    this.averageSpeed = averageSpeed;
  }

  setTripID(tripID) {
    this.tripID = tripID;
  }

  setHaul(haul) {
    this.haul = haul;
  }

  setPositions(positions) {
    this.positions = positions;
  }

  setDepth(depth) {
    this.depth = depth;
  }

  setCrewAmount(crewAmount) {
    this.crewAmount = crewAmount;
  }

  setExcludeFromCalculations(excludeFromCalculations) {
    this.excludeFromCalculations = excludeFromCalculations;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getStartTimestamp() {
    return this.startTimestamp;
  }

  getStopTimestamp() {
    return this.stopTimestamp;
  }

  getElapsedTime() {
    return this.elapsedTime;
  }

  getGearType() {
    return this.gearType;
  }

  getGearSubtype() {
    return this.gearSubtype;
  }

  getGearAmount() {
    return this.gearAmount;
  }

  getTotalDistance() {
    return this.totalDistance;
  }

  getAverageSpeed() {
    return this.averageSpeed;
  }

  getTripID() {
    return this.tripID;
  }

  getHaul() {
    return this.haul;
  }

  getPositions() {
    return this.positions;
  }

  getDepth() {
    return this.depth;
  }

  getCrewAmount() {
    return this.crewAmount;
  }

  getIsAnalyzed() {
    return this.crewAmount != null && this.gearAmount != null;
  }

  getExcludeFromCalculations() {
    return this.excludeFromCalculations;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */
  listGoogleMapsPositions() {
    return TripPosition.listGoogleMapsPositions(this.positions);
  }

  getObjectType() {
    return SET;
  }
}

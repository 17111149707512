import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, Typography, TextField } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Redux
import { connect } from 'react-redux';
import { clearErrors, setError } from '../../../../alerts';
import { createUserAccount } from '../../../../redux/actions/authActions';

// Images
import ColorLogo from '../../../../images/ColorLogo.png';

// API
import { checkInvitation } from '../../../../api';

// Constants
import { NAVY } from '../../../../constants';

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      isTokenValid: null,
      isCheckingToken: true
    };
  }

  componentDidMount() {
    const token = this.props.match?.params?.token;
    checkInvitation(token).then(userInvitation => {
      this.setState({ isCheckingToken: false, isTokenValid: true, email: userInvitation.getEmail() });
    }).catch(error => {
      setError(error);
      this.setState({ isCheckingToken: false, isTokenValid: false });
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword
    } = this.state;

    // Make sure passwords match
    if (password !== confirmPassword) {
      setError('Error: Passwords must match');
      return;
    }

    if (phoneNumber?.length <= 3) {
      setError('Error: Invalid phone number');
      return;
    }

    const token = this.props.match?.params?.token;
    this.props.createUserAccount({ firstName, lastName, email, phoneNumber, password }, token);
  };

  render() {
    return (
      <Container>
        <form autoComplete='off' onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
          {/* ----- LOGO & TITLE ----- */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2em', marginBottom: '1em' }}>
            <img
              width={250}
              src={ColorLogo}
              alt={'FlyWire Logo'}
              title={'FlyWire Logo'}
              style={{ objectFit: 'contain' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h1' style={{ textAlign: 'center' }}>Create an Account</Typography>
            {this.state.isCheckingToken && <CircularProgress style={{ marginLeft: '20px' }} />}
          </div>

          {/* ----- PERSONAL INFORMATION ----- */}
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Personal Information</Typography>

          {/* Name Input */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
            <TextField required error={this.state.formError && this.state.firstName === ''} name='firstName' label='First Name' style={{ width: '49%' }} value={this.state.firstName} onChange={this.onChange} variant='filled' disabled={this.props.isLoading || !this.state.isTokenValid} />
            <TextField required error={this.state.formError && this.state.lastName === ''} name='lastName' label='Last Name' style={{ width: '49%' }} value={this.state.lastName} onChange={this.onChange} variant='filled' disabled={this.props.isLoading || !this.state.isTokenValid} />
          </div>

          {/* Email Input */}
          <TextField
            name='email'
            label='Email'
            type='email'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.email}
            variant='filled'
            disabled
          />

          {/* Phone Number */}
          <ReactPhoneInput
            country='us'
            preferredCountries={['us', 'cn']}
            value={this.state.phoneNumber}
            onChange={(phoneNumber) => this.setState({ phoneNumber })}
            component={TextField}
            inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.props.isLoading || !this.state.isTokenValid, error: this.state.formError && this.state.phoneNumber?.length <= 3 }}
            containerStyle={{ width: '100%', marginBottom: '10px' }}
          />

          {/* ----- CREATE PASSWORD ----- */}
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Create Password</Typography>
          <TextField
            required
            error={this.state.formError && this.state.password === ''}
            name='password'
            label='Password'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.password}
            onChange={this.onChange}
            variant='filled'
            type='password'
            disabled={this.props.isLoading || !this.state.isTokenValid}
          />

          <TextField
            required
            error={this.state.formError && this.state.confirmPassword === ''}
            name='confirmPassword'
            label='Confirm Password'
            style={{ width: '100%', marginBottom: '20px' }}
            value={this.state.confirmPassword}
            onChange={this.onChange}
            variant='filled'
            type='password'
            disabled={this.props.isLoading || !this.state.isTokenValid}
          />

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
            <Button type='submit' variant='contained' color='primary' disabled={this.props.isLoading || !this.state.isTokenValid} style={{ width: '50%', backgroundColor: NAVY, color: 'white' }}>
              Create Account
              {this.props.isLoading && <CircularProgress size={25} style={{ marginLeft: '1em' }} />}
            </Button>
            <Typography variant='button' style={{ marginTop: '10px' }}>Already have an account?{' '}
              <Link to='/auth/login' style={{ color: NAVY }}>
                Log in now
              </Link>.
            </Typography>
          </div>
        </form>
      </Container>
    );
  }
}

CreateAccount.propTypes = {
  createUserAccount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  createUserAccount: (userObj, token) => createUserAccount(dispatch, userObj, token)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateAccount));

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Container, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../../alerts';

// Components
import { BackToButton, TripForm } from '../../../../components';
import { getBoat } from '../../../../api/admin';

class CreateTrip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boat: null
    };

    // Retrieve the boatID from the url
    this.boatID = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.id;
    if (this.boatID == null) {
      setError('Error: Something went wrong. Please try again.');
    }
  }

  componentDidMount() {
    getBoat(this.boatID).then(boat => {
      this.setState({ boat });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve boat.');
    });
  }

  render() {
    return (
      <Container style={{ marginTop: 20, marginBottom: 50 }}>
        <BackToButton to={`/admin/boats/${this.boatID}`} description={this.state.boat?.getName()} />
        <Typography variant='h1'>Create Trip</Typography>
        <TripForm boatID={this.boatID} />
      </Container>
    );
  }
}

CreateTrip.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(CreateTrip);

import { CameraKeyTypeDisplay } from './';

export default class CameraKeyType {
  static thaw(cameraKeyTypeObj) {
    if (cameraKeyTypeObj == null) return null;
    const cameraKeyType = new CameraKeyType();
    if (cameraKeyTypeObj.id != null) cameraKeyType.setID(cameraKeyTypeObj.id);
    if (cameraKeyTypeObj.key != null) cameraKeyType.setKey(cameraKeyTypeObj.key);
    if (cameraKeyTypeObj.description != null) cameraKeyType.setDescription(cameraKeyTypeObj.description);
    if (cameraKeyTypeObj.units != null) cameraKeyType.setUnits(cameraKeyTypeObj.units);
    if (cameraKeyTypeObj.isAdminOnly != null) cameraKeyType.setIsAdminOnly(cameraKeyTypeObj.isAdminOnly);
    if (cameraKeyTypeObj.cameraKeyTypeDisplays != null) cameraKeyType.setCameraKeyTypeDisplays(CameraKeyTypeDisplay.thawList(cameraKeyTypeObj.cameraKeyTypeDisplays));
    return cameraKeyType;
  }

  static thawList(cameraKeyTypeObjList) {
    if (cameraKeyTypeObjList == null) return [];
    var cameraKeyTypes = [];
    for (var i = 0; i < cameraKeyTypeObjList.length; i++) {
      cameraKeyTypes.push(CameraKeyType.thaw(cameraKeyTypeObjList[i]));
    }
    return cameraKeyTypes;
  }

  static freeze(cameraKeyType) {
    var cameraKeyTypeObj = {};
    if (cameraKeyType.getID() != null) cameraKeyTypeObj.id = cameraKeyType.getID();
    if (cameraKeyType.getKey() != null) cameraKeyTypeObj.key = cameraKeyType.getKey();
    if (cameraKeyType.getDescription() != null) cameraKeyTypeObj.description = cameraKeyType.getDescription();
    if (cameraKeyType.getUnits() != null) cameraKeyTypeObj.units = cameraKeyType.getUnits();
    if (cameraKeyType.getIsAdminOnly() != null) cameraKeyTypeObj.isAdminOnly = cameraKeyType.getIsAdminOnly();
    if (cameraKeyType.getCameraKeyTypeDisplays() != null) cameraKeyTypeObj.cameraKeyTypeDisplays = CameraKeyTypeDisplay.freezeList(cameraKeyType.getCameraKeyTypeDisplays());
    return cameraKeyTypeObj;
  }

  static freezeList(cameraKeyTypes) {
    if (cameraKeyTypes == null) return [];
    var cameraKeyTypeObjList = [];
    for (var i = 0; i < cameraKeyTypes.length; i++) {
      cameraKeyTypeObjList.push(CameraKeyType.freeze(cameraKeyTypes[i]));
    }
    return cameraKeyTypeObjList;
  }

  constructor() {
    this.setID(null);
    this.setKey(null);
    this.setDescription(null);
    this.setUnits(null);
    this.setIsAdminOnly(null);
    this.setCameraKeyTypeDisplays(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setKey(key) {
    this.key = key;
  }

  setDescription(description) {
    this.description = description;
  }

  setUnits(units) {
    this.units = units;
  }

  setIsAdminOnly(isAdminOnly) {
    this.isAdminOnly = isAdminOnly;
  }

  setCameraKeyTypeDisplays(cameraKeyTypeDisplays) {
    this.cameraKeyTypeDisplays = cameraKeyTypeDisplays;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getKey() {
    return this.key;
  }

  getDescription() {
    return this.description;
  }

  getUnits() {
    return this.units;
  }

  getIsAdminOnly() {
    return this.isAdminOnly;
  }

  getCameraKeyTypeDisplays() {
    return this.cameraKeyTypeDisplays;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import { Video } from './';
import { DateTime } from 'luxon';

export default class VideoFolder {
  static thaw(videoFolderObj) {
    if (videoFolderObj == null) return null;
    const videoFolder = new VideoFolder();
    if (videoFolderObj.date != null) videoFolder.setDate(DateTime.fromISO(videoFolderObj.date));
    if (videoFolderObj.videos != null) videoFolder.setVideos(Video.thawList(videoFolderObj.videos));
    return videoFolder;
  }

  static thawList(videoFolderObjList) {
    if (videoFolderObjList == null) return [];
    var videoFolders = [];
    for (var i = 0; i < videoFolderObjList.length; i++) {
      videoFolders.push(VideoFolder.thaw(videoFolderObjList[i]));
    }
    return videoFolders;
  }

  constructor() {
    this.setDate(null);
    this.setVideos(null);
    this.setCamera(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setDate(date) {
    this.date = date;
  }

  setVideos(videos) {
    this.videos = videos;
  }

  setCamera(camera) {
    this.camera = camera;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getDate() {
    return this.date;
  }

  getVideos() {
    return this.videos;
  }

  getCamera() {
    return this.camera;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

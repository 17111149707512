import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, MenuItem, TextField, Typography } from '@mui/material';
import ReactImageGallery from 'react-image-gallery';

// Alerts
import { setError } from '../alerts';

class ImageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProjectIndex: null,
      isFullScreen: false,
      displayedImageIndexSelection: ''
    };

    this.renderImage = this.renderImage.bind(this);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onScreenChange(fullScreenElement) {
    this.setState({
      isFullScreen: !!fullScreenElement
    });
  }

  handleImageLoadingError = (error) => {
    setError(error ? error : 'Error: Unable to load image.');
  }

  renderImage(image) {
    return (
      <div>
        <img className={this.state.isFullScreen ? 'fullscreen-image' : ''} width='90%' height='500' src={image.original} style={{ objectFit: 'contain' }} />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 20 }}>
          <Typography variant='body1'><b>Image Number:</b> {image.clipNumber}</Typography>
        </div>
      </div>
    );
  }

  goToClipNumber = () => {
    this._imageGallery.slideToIndex(this.state.displayedImageIndexSelection);
  }

  render() {
    return (
      <div>
        {/* Select Camera */}
        <TextField
          name='selectedProjectIndex'
          label='Camera'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.selectedProjectIndex}
          onChange={this.onChange}
          variant='filled'
          select
        >
          {this.props.trip.getProjects()?.map((project, index) => (
            <MenuItem key={index} value={index}>{project.camera?.getName()}</MenuItem>
          ))}
        </TextField>

        {this.state.selectedProjectIndex != null && this.state.selectedProjectIndex < this.props.trip.getProjects()?.length && this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages()?.length > 0 ? (
          <div>
            <ReactImageGallery
              ref={(i) => (this._imageGallery = i)}
              items={this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages()?.map((image, index) => ({
                original: image.getUrl(),
                thumbnail: image.getUrl(),
                clipNumber: (index + 1) + ' of ' + this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages().length,
                renderItem: this.renderImage,
                loading: 'lazy'
              }))}
              infinite={false}
              showPlayButton={false}
              onErrorImageURL={this.handleImageLoadingError}
              onScreenChange={this.onScreenChange.bind(this)}
              lazyLoad
              showThumbnails={false}
              style={{ width: '100%' }}
            />

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
              <TextField
                label='Jump to Image'
                value={this.state.displayedImageIndexSelection}
                onChange={(e) => this.setState({ displayedImageIndexSelection: e.target.value })}
                style={{ width: '150px' }}
                select
              >
                {Array.from({ length: this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages().length }, (_, i) => i).map((number) => (
                  <MenuItem key={number} value={number}>{number + 1}</MenuItem>
                ))}
              </TextField>

              <Button onClick={this.goToClipNumber} style={{ width: '75px', marginLeft: '10px' }}>Go</Button>
            </div>
          </div>
        ) : (
          <Typography variant='body1'>
            {this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages()?.length === 0 ? 'No images captured for selected camera.' : 'Please select a camera to view images.'}
          </Typography>
        )}
      </div>
    );
  }
}

ImageGallery.propTypes = {
  trip: PropTypes.object.isRequired
};

export default ImageGallery;



export default class CameraConfigurationFile {
  static thaw(cameraConfigurationFileObj) {
    if (cameraConfigurationFileObj == null) return null;
    const cameraConfigurationFile = new CameraConfigurationFile();
    if (cameraConfigurationFileObj.id != null) cameraConfigurationFile.setID(cameraConfigurationFileObj.id);
    if (cameraConfigurationFileObj.fileName != null) cameraConfigurationFile.setFileName(cameraConfigurationFileObj.fileName);
    if (cameraConfigurationFileObj.fileContent != null) cameraConfigurationFile.setFileContent(cameraConfigurationFileObj.fileContent);
    return cameraConfigurationFile;
  }

  static thawList(cameraConfigurationFileObjList) {
    if (cameraConfigurationFileObjList == null) return [];
    var cameraConfigurationFiles = [];
    for (var i = 0; i < cameraConfigurationFileObjList.length; i++) {
      cameraConfigurationFiles.push(CameraConfigurationFile.thaw(cameraConfigurationFileObjList[i]));
    }
    return cameraConfigurationFiles;
  }

  static freeze(cameraConfigurationFile) {
    var cameraConfigurationFileObj = {};
    if (cameraConfigurationFile.getID() != null) cameraConfigurationFileObj.id = cameraConfigurationFile.getID();
    if (cameraConfigurationFile.getFileName() != null) cameraConfigurationFileObj.fileName = cameraConfigurationFile.getFileName();
    if (cameraConfigurationFile.getFileContent() != null) cameraConfigurationFileObj.fileContent = cameraConfigurationFile.getFileContent();
    return cameraConfigurationFileObj;
  }

  static freezeList(cameraConfigurationFiles) {
    if (cameraConfigurationFiles == null) return [];
    var cameraConfigurationFileObjList = [];
    for (var i = 0; i < cameraConfigurationFiles.length; i++) {
      cameraConfigurationFileObjList.push(CameraConfigurationFile.freeze(cameraConfigurationFiles[i]));
    }
    return cameraConfigurationFileObjList;
  }

  constructor() {
    this.setID(null);
    this.setFileName(null);
    this.setFileContent(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setFileName(fileName) {
    this.fileName = fileName;
  }

  setFileContent(fileContent) {
    this.fileContent = fileContent;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getFileName() {
    return this.fileName;
  }

  getFileContent() {
    return this.fileContent;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import { Camera, Business, Trip } from './';

export default class Boat {
  static thaw(boatObj) {
    if (boatObj == null) return null;
    const boat = new Boat();
    if (boatObj.id != null) boat.setID(boatObj.id);
    if (boatObj.name != null) boat.setName(boatObj.name);
    if (boatObj.awsIdentifier != null) boat.setAwsIdentifier(boatObj.awsIdentifier);
    if (boatObj.isActive != null) boat.setIsActive(boatObj.isActive == null ? null : (boatObj.isActive ? true : false));
    if (boatObj.businessId != null) boat.setBusinessID(boatObj.businessId);
    if (boatObj.cameras != null) boat.setCameras(Camera.thawList(boatObj.cameras));
    if (boatObj.business != null) boat.setBusiness(Business.thaw(boatObj.business));
    if (boatObj.trips != null) boat.setTrips(Trip.thawList(boatObj.trips));
    return boat;
  }

  static thawList(boatObjList) {
    if (boatObjList == null) return [];
    var boats = [];
    for (var i = 0; i < boatObjList.length; i++) {
      boats.push(Boat.thaw(boatObjList[i]));
    }
    return boats;
  }

  static freeze(boat) {
    var boatObj = {};
    if (boat.getID() != null) boatObj.id = boat.getID();
    if (boat.getName() != null) boatObj.name = boat.getName();
    if (boat.getAwsIdentifier() != null) boatObj.awsIdentifier = boat.getAwsIdentifier();
    if (boat.getIsActive() != null) boatObj.isActive = boat.getIsActive() == null ? null : (boat.getIsActive() ? 1 : 0);
    if (boat.getBusinessID() != null) boatObj.businessId = boat.getBusinessID();
    if (boat.getCameras() != null) boatObj.cameras = Camera.freezeList(boat.getCameras());
    if (boat.getBusiness() != null) boatObj.business = Business.freeze(boat.getBusiness());
    if (boat.getTrips() != null) boatObj.trips = Trip.freezeList(boat.getTrips());
    return boatObj;
  }

  static freezeList(boats) {
    if (boats == null) return [];
    var boatObjList = [];
    for (var i = 0; i < boats.length; i++) {
      boatObjList.push(Boat.freeze(boats[i]));
    }
    return boatObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
    this.setAwsIdentifier(null);
    this.setIsActive(null);
    this.setBusinessID(null);
    this.setCameras(null);
    this.setBusiness(null);
    this.setTrips(null);
    this.setDisplayColor(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setAwsIdentifier(awsIdentifier) {
    this.awsIdentifier = awsIdentifier;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setBusinessID(businessID) {
    this.businessID = businessID;
  }

  setCameras(cameras) {
    this.cameras = cameras;
  }

  setBusiness(business) {
    this.business = business;
  }

  setTrips(trips) {
    this.trips = trips;
  }

  setDisplayColor(displayColor) {
    this.displayColor = displayColor;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getAwsIdentifier() {
    return this.awsIdentifier;
  }

  getIsActive() {
    return this.isActive;
  }

  getBusinessID() {
    return this.businessID;
  }

  getCameras() {
    return this.cameras;
  }

  getBusiness() {
    return this.business;
  }

  getTrips() {
    return this.trips;
  }

  getDisplayColor() {
    return this.displayColor;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

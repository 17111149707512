import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, TextField, Typography, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../../alerts';

// API
import { listUsers, listGuestInvitations, createGuestTripPermission, getTrip, listGuestTripPermissionsForTrip } from '../../../../../api/admin';
import { LineItem } from '@lexcelon/react-util';
import { USER_TYPES } from '../../../../../constants';
import { BackToButton } from '../../../../../components';

class ShareTrip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trip: null,
      guestUsers: [],
      guestUserInvitations: [],
      userSelection: null,
      isLoadingGuestUsers: true,
      isLoadingGuestUserInvitations: true,
      isLoading: false,
      redirectToTripPermissions: false,
      fishingPerformance: false,
      complianceReports: false,
      videos: false,
      images: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoadingGuestUsers: true, isLoadingGuestUserInvitations: true });
    listGuestTripPermissionsForTrip(this.props.match?.params?.id).then(guestTripPermissions => {
      listUsers().then(users => {
        const guestUsers = users.filter(user => user.getUserType() === USER_TYPES.GUEST);
        this.setState({
          guestUsers: guestUsers.filter(guestUser => guestTripPermissions.find(guestTripPermission => guestUser.getID() === guestTripPermission.getGuestID()) == null),
          isLoadingGuestUsers: false
        });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to retrieve guest users.');
        this.setState({ isLoadingGuestUsers: false });
      });

      listGuestInvitations().then(guestUserInvitations => {
        this.setState({
          guestUserInvitations: guestUserInvitations.filter(guestUserInvitation => guestTripPermissions.find(guestTripPermission => guestUserInvitation.getID() === guestTripPermission.getGuestInvitationID()) == null),
          isLoadingGuestUserInvitations: false
        });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to retrieve guest user invitations.');
        this.setState({ isLoadingGuestUserInvitations: false });
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve guest trip permissions.');
      this.setState({ isLoadingGuestUsers: false, isLoadingGuestUserInvitations: false });
    });

    getTrip(this.props.match?.params?.id).then(trip => {
      this.setState({ trip });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve trip.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { trip, userSelection, fishingPerformance, complianceReports, videos, images } = this.state;

    if (!fishingPerformance && !complianceReports && !videos && !images) {
      setError('You must select at least one permission.');
      return;
    }
    
    this.setState({ isLoading: true });
    createGuestTripPermission({ tripId: trip.getID(), fishingPerformance, complianceReports, videos, images, ...({ [userSelection.type === 'user' ? 'guestId' : 'guestInvitationId']: userSelection.id }) }).then(() => {
      setSuccess('Successfully created guest permission.');
      this.setState({ isLoading: false, redirectToTripPermissions: true });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to create guest permission.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    const userOptions = [];
    for (const guestUser of this.state.guestUsers) {
      userOptions.push({ label: `${guestUser.getName()} (${guestUser.getEmail()})`, type: 'user', id: guestUser.getID(), isInternal: guestUser.getAssociatedWithBusinessID() != null && guestUser.getAssociatedWithBusinessID() === this.state.trip?.getBoat()?.getBusinessID() });
    }
    for (const guestUserInvitation of this.state.guestUserInvitations) {
      userOptions.push({ label: `${guestUserInvitation.getEmail()} (Invited)`, type: 'invitation', id: guestUserInvitation.getID(), isInternal: guestUserInvitation.getAssociatedWithBusinessID() != null && guestUserInvitation.getAssociatedWithBusinessID() === this.state.trip?.getBoat()?.getBusinessID() });
    }
    return this.state.redirectToTripPermissions ? <Redirect to={`/admin/trips/${this.state.trip?.getID()}/shared`} /> : (
      <Container style={{ marginBottom: 30, marginTop: 20 }}>
        <BackToButton to={`/admin/trips/${this.state.trip?.getID()}/shared`} description='Shared Trip Data' />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <Typography variant='h1'>Share Trip</Typography>
          {this.state.isLoadingBusinesses && <CircularProgress style={{ marginLeft: '20px' }} />}
        </div>

        <LineItem
          description='Trip ID'
          value={this.state.trip?.getID()}
        />

        <LineItem
          description='Boat Name'
          value={this.state.trip?.getBoat()?.getName()}
        />

        <LineItem
          description='Departure Date'
          value={this.state.trip?.getStartTimestamp()?.toLocaleString()}
        />

        <form onSubmit={this.onSubmit}>
          <Autocomplete
            disablePortal
            value={this.state.userSelection}
            getOptionLabel={option => option?.label}
            options={userOptions ?? []}
            disabled={this.state.isLoading || this.state.isLoadingBusinesses}
            onChange={(_, userSelection) => this.setState({ userSelection })}
            style={{ width: '100%', marginBottom: '10px' }}
            renderInput={(params) => <TextField {...params} variant='filled' label='Guest User' />}
          />

          {this.state.userSelection != null && !this.state.userSelection.isInternal && (
            <Typography variant='body2' style={{ color: 'red' }}>This is an external user.</Typography>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.fishingPerformance}
                onChange={(val) => this.setState({ fishingPerformance: val.target.checked })}
              />
            }
            label='Fishing Performance'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.complianceReports}
                onChange={(val) => this.setState({ complianceReports: val.target.checked })}
              />
            }
            label='Compliance Reports'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.videos}
                onChange={(val) => this.setState({ videos: val.target.checked })}
              />
            }
            label='Videos'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.images}
                onChange={(val) => this.setState({ images: val.target.checked })}
              />
            }
            label='Images'
          />

          <Button style={{ marginTop: 20 }} type='submit' disabled={this.state.isLoading}>
            Save Permission
            {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
          </Button>
        </form>
      </Container>
    );
  }
}

ShareTrip.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ShareTrip);

import { createTheme } from '@mui/material/styles';

// Constants
import { BLUE, LIGHT_BLUE, NAVY, WHITE } from './constants';
export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536
};

// https://material-ui.com/customization/palette/
export default createTheme({
  breakpoints: {
    values: {
      xs: BREAKPOINTS.xs,
      sm: BREAKPOINTS.sm,
      md: BREAKPOINTS.md,
      lg: BREAKPOINTS.lg,
      xl: BREAKPOINTS.xl,
    },
  },
  palette: {
    primary: {
      main: BLUE,
      light: LIGHT_BLUE,
      dark: NAVY,
      contrastText: WHITE
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    h1: {
      fontSize: 50
    },
    h2: {
      fontSize: 38
    },
    h3: {
      fontSize: 32
    },
    h4: {
      fontSize: 26
    },
    h5: {
      fontSize: 20
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: NAVY,
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: 'red',
            color: 'red'
          }
        }
      ],
      styleOverrides: {
        root: {
          width: '100%'
        },
      }
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          boxSizing: 'border-box',
          margin: 0
        }
      }
    }
  }
});

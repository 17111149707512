export default class FishCatch {
  static thaw(fishCatchObj) {
    if (fishCatchObj == null) return null;
    const fishCatch = new FishCatch();
    if (fishCatchObj.id != null) fishCatch.setID(fishCatchObj.id);
    if (fishCatchObj.speciesName != null) fishCatch.setSpeciesName(fishCatchObj.speciesName);
    if (fishCatchObj.weight != null) fishCatch.setWeight(fishCatchObj.weight);
    if (fishCatchObj.unknown != null) fishCatch.setUnknown(fishCatchObj.unknown == null ? null : (fishCatchObj.unknown ? true : false));
    if (fishCatchObj.catchType != null) fishCatch.setCatchType(fishCatchObj.catchType);
    if (fishCatchObj.tripId != null) fishCatch.setTripID(fishCatchObj.tripId);
    if (fishCatchObj.tripHaulId != null) fishCatch.setTripHaulID(fishCatchObj.tripHaulId);
    return fishCatch;
  }

  static thawList(fishCatchObjList) {
    if (fishCatchObjList == null) return [];
    var fishCatches = [];
    for (var i = 0; i < fishCatchObjList.length; i++) {
      fishCatches.push(FishCatch.thaw(fishCatchObjList[i]));
    }
    return fishCatches;
  }

  static freeze(fishCatch) {
    var fishCatchObj = {};
    if (fishCatch.getID() != null) fishCatchObj.id = fishCatch.getID();
    if (fishCatch.getSpeciesName() != null) fishCatchObj.speciesName = fishCatch.getSpeciesName();
    if (fishCatch.getWeight() != null) fishCatchObj.weight = fishCatch.getWeight();
    if (fishCatch.getUnknown() != null) fishCatchObj.unknown = fishCatch.getUnknown() == null ? null : (fishCatch.getUnknown() ? 1 : 0);
    if (fishCatch.getCatchType() != null) fishCatchObj.catchType = fishCatch.getCatchType();
    if (fishCatch.getTripID() != null) fishCatchObj.tripId = fishCatch.getTripID();
    if (fishCatch.getTripHaulID() != null) fishCatchObj.tripHaulId = fishCatch.getTripHaulID();
    return fishCatchObj;
  }

  static freezeList(fishCatches) {
    if (fishCatches == null) return [];
    var fishCatchObjList = [];
    for (var i = 0; i < fishCatches.length; i++) {
      fishCatchObjList.push(FishCatch.freeze(fishCatches[i]));
    }
    return fishCatchObjList;
  }

  static groupList(fishCatches) {
    if (fishCatches == null) return [];
    var fishCatchObjList = [];

    for (let i = 0; i < fishCatches.length; i++) {
      var fishCatch = fishCatches[i];

      let index = fishCatchObjList.findIndex(elem => elem.getSpeciesName() == fishCatch.getSpeciesName());

      if (index != -1) {
        fishCatchObjList[index].weight += fishCatch.getWeight();
      }
      else {
        fishCatchObjList.push(FishCatch.thaw(FishCatch.freeze(fishCatch)));
      }
    }

    return fishCatchObjList;
  }

  constructor() {
    this.setID(null);
    this.setSpeciesName(null);
    this.setWeight(null);
    this.setUnknown(null);
    this.setCatchType(null);
    this.setTripID(null);
    this.setTripHaulID(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setSpeciesName(speciesName) {
    this.speciesName = speciesName;
  }

  setWeight(weight) {
    this.weight = weight;
  }

  setUnknown(unknown) {
    this.unknown = unknown;
  }

  setCatchType(catchType) {
    this.catchType = catchType;
  }

  setTripID(tripID) {
    this.tripID = tripID;
  }

  setTripHaulID(tripHaulID) {
    this.tripHaulID = tripHaulID;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getSpeciesName() {
    return this.speciesName;
  }

  getWeight() {
    return this.weight;
  }

  getUnknown() {
    return this.unknown;
  }

  getCatchType() {
    return this.catchType;
  }

  getTripID() {
    return this.tripID;
  }

  getTripHaulID() {
    return this.tripHaulID;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

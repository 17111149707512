import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Checkbox, Grid, Typography, Button } from '@mui/material';

// Alerts
import { setError } from '../../../../alerts';

// Components
import { LineItem, SteamMapWidget } from '../../../../components';

// API
import { listTripGPS, listTripSets, listTripHauls } from '../../../../api';

// Constants
import { DATE_TIME_ZONE_FORMAT } from '../../../../constants';
import { SET_COLOR, HAUL_COLOR, STEAM_COLOR } from '../../../../components/SteamMapWidget';
import { HAUL } from '../../../../lib/TripHaul';
import { SET } from '../../../../lib/TripSet';

import humanizeDuration from 'humanize-duration';
import { Chart } from 'react-google-charts';
import FishCatchDialog from '../../../../components/FishCatchDialog';
import SetHaulShiftDialog from '../../../../components/SetHaulShiftDialog';

import { LBS_TO_KG, MILES_TO_KM } from '../../../../constants';
const TIME_PIE_CHART_OPTIONS = {
  legend: {
    position: 'bottom',
    alignment: 'center',
    textStyle: {
      fontSize: 14
    }
  },
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    left: 0,
    top: 10,
    width: '100%',
    height: '80%'
  },
  slices: [
    { color: STEAM_COLOR },
    { color: SET_COLOR },
    { color: HAUL_COLOR }
  ]
};

class OverviewView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSteam: true,
      showSets: true,
      showHauls: true,
      isLoadingSets: false,
      isLoadingHauls: false,
      isLoadingSteam: false,
      isLoadingTrip: false,
      sets: [],
      hauls: [],
      steam: [],
      trip: null,
      selectedObject: null,
      fishCatch: [],
      showFishCatch: false,
      showSetHaulShift: false,
      tripStatistics: null
    };
  }

  componentDidMount() {
    if (this.state.showSets) this.retrieveSets();
    if (this.state.showHauls) this.retrieveHauls();
    if (this.state.showSteam) this.retrieveSteam();
  }

  onCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });

    if (event.target.checked) {
      switch (event.target.name) {
        case 'showSets':
          if (this.state.sets == null || this.state.sets.length === 0) this.retrieveSets();
          break;
        case 'showHauls':
          if (this.state.hauls == null || this.state.hauls.length === 0) this.retrieveHauls();
          break;
        case 'showSteam':
          if (this.state.steam == null || this.state.steam.length === 0) this.retrieveSteam();
      }
    }
  }

  retrieveSets() {
    this.setState({ isLoadingSets: true });
    listTripSets(this.props.trip?.getID()).then(sets => {
      this.setState({ isLoadingSets: false, sets });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve sets.');
      this.setState({ isLoadingSets: false });
    });
  }

  retrieveHauls() {
    this.setState({ isLoadingHauls: true });
    listTripHauls(this.props.trip?.getID()).then(hauls => {
      this.setState({ isLoadingHauls: false, hauls });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve hauls.');
      this.setState({ isLoadingHauls: false });
    });
  }

  retrieveSteam() {
    this.setState({ isLoadingSteam: true });
    listTripGPS(this.props.trip?.getID()).then(steam => {
      this.setState({ isLoadingSteam: false, steam });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve steam.');
      this.setState({ isLoadingSteam: false });
    });
  }

  clearSelection = () => {
    this.setState({ selectedObject: null });
  }

  onSetSelection = (setID) => {
    this.setState({ selectedObject: this.state.sets?.find(set => set.getID() === setID) });
  }

  onHaulSelection = (haulID) => {
    this.setState({ selectedObject: this.state.hauls?.find(haul => haul.getID() === haulID) });
  }

  displayDuration = (ms) => {
    return humanizeDuration(ms, { largest: 3, round: true, units: ['mo', 'w', 'd', 'h', 'm'] });
  }

  formatDurationForTooltip = (title, ms) => {
    return title + '\n' + this.displayDuration(ms);
  }

  getTimeSplitChart = () => {
    let steamTime = this.props.trip?.getStatistics()?.getElapsedSteamTime();
    let setTime = this.props.trip?.getSets()?.reduce((prev, current) => prev + (current?.getElapsedTime() ?? 0), 0);
    let haulTime = this.props.trip?.getSets()?.reduce((prev, current) => prev + (current?.getHaul()?.getElapsedTime() ?? 0), 0);

    let data = [['Type', 'Time', { role: 'tooltip', type: 'string' }],
      ['Steam', steamTime, this.formatDurationForTooltip('Steam', steamTime)],
      ['Set', setTime, this.formatDurationForTooltip('Set', setTime)],
      ['Haul', haulTime, this.formatDurationForTooltip('Haul', haulTime)]
    ];

    return (
      <Chart
        chartType="PieChart"
        data={data}
        options={TIME_PIE_CHART_OPTIONS}
        width={'100%'}
        height={'250px'}
        legend_toggle
      />
    );
  }

  tripInfo = () => {
    let gearAmount = 0;
    this.props.trip?.getSets()?.forEach(set => {
      gearAmount += set.getGearAmount() ?? 0;
    });

    // Calculate the Analyzed Haul Percentage (average of analyzed hauls and sets)
    let analyzedHaulCount = this.props.trip?.getSets()?.filter(set => set.getHaul()?.getIsAnalyzed())?.length ?? 0;
    let haulCount = this.props.trip?.getSets()?.filter(set => set.getHaul() != null)?.length ?? 0;
    let analyzedSetCount = this.props.trip?.getSets()?.filter(set => set.getIsAnalyzed())?.length ?? 0;
    let setCount = this.props.trip?.getSets()?.filter(set => set.getElapsedTime() != null)?.length ?? 0;
    let analyzedHaulPercentage = (haulCount > 0 && setCount > 0) ? (((analyzedHaulCount / haulCount) + (analyzedSetCount / setCount)) / 2) : null;
    return (
      <>
        <Typography variant='h4' style={{ textAlign: 'center' }}>Trip Summary</Typography>

        <LineItem
          value={this.props.trip?.getStartTimestamp()?.setZone(this.props.trip?.getTimezone())?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
          description={'Depart Date'}
        />

        <LineItem
          value={this.props.trip?.getStopTimestamp()?.setZone(this.props.trip?.getTimezone())?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
          description={'Return Date'}
        />

        {/** Total Trip Catch Viewer Here */}
        <LineItem
          value={
            <>
              <Typography>
                {this.props.trip?.getConvertToKg() ? (
                  (this.props.trip?.getStatistics()?.getTotalRetainedLbs() * LBS_TO_KG)?.toFixed(1) + ' kg'
                ) : (
                  (this.props.trip?.getStatistics()?.getTotalRetainedLbs()?.toFixed(1) ?? 'Unknown') + ' lbs'
                )}
              </Typography>
              <Button type='submit' disabled={this.state.isLoading} style={{ width: '100px' }} onClick={() =>
                this.setState({ fishCatchTitle: 'View Catch', showFishCatch: true, fishCatch: this.props.trip?.getCatch().filter(c => c.getCatchType() == 'Retained' && c.getTripHaulID() == null) })
              }>
                View
              </Button>
            </>
          }
          description={'Total Target Catch'}
        />

        <LineItem
          value={
            <>
              <Typography>
                {this.props.trip?.getConvertToKg() ? (
                  (this.props.trip?.getStatistics()?.getTotalDiscardLbs() * LBS_TO_KG)?.toFixed(1) + ' kg'
                ) : (
                  (this.props.trip?.getStatistics()?.getTotalDiscardLbs()?.toFixed(1) ?? 'Unknown') + ' lbs'
                )}
              </Typography>
              <Button type='submit' disabled={this.state.isLoading} style={{ width: '100px' }} onClick={() =>
                this.setState({ fishCatchTitle: 'View Bycatch', showFishCatch: true, fishCatch: this.props.trip?.getCatch().filter(c => c.getCatchType() == 'Discard' && c.getTripHaulID() == null) })
              }>
                View
              </Button>
            </>
          }
          description={'Total Bycatch'}
        />

        <LineItem
          value={this.props.trip?.getSets()?.filter(set => set.getHaul() != null)?.length ?? 'Unknown'}
          description={'Total Hauls'}
        />

        <LineItem
          value={analyzedHaulPercentage != null ? (analyzedHaulPercentage * 100)?.toFixed(1) + '%' : null}
          description={'Analyzed Haul Percentage'}
        />

        <Typography variant='h5' style={{ textAlign: 'center' }}>Fuel Efficiency</Typography>

        <LineItem
          value = {
            this.props.trip?.getStatistics()?.getTotalDistance() != null ? (
              this.props.trip?.getConvertToKg() ? (
                (this.props.trip.getStatistics().getTotalDistance() * MILES_TO_KM).toFixed(1) + ' km'
              ) : (
                this.props.trip.getStatistics().getTotalDistance()?.toFixed(1) + ' miles'
              )
            ) : null
          }
          description={'Trip Distance'}
        />

        {/** Time Split Pie Chart here */}
        <LineItem
          value={this.getTimeSplitChart()}
          description={'Time Split'}
        />

        <LineItem
          value={this.props.trip?.getStatistics()?.getAverageSteamSpeed() != null ? (this.props.trip?.getStatistics()?.getAverageSteamSpeed()?.toFixed(1) + ' knots') : null}
          description={'Average Steam Speed'}
        />

        <LineItem
          value={this.props.trip?.getStatistics()?.getAverageSetSpeed() != null ? (this.props.trip?.getStatistics()?.getAverageSetSpeed()?.toFixed(1) + ' knots') : null}
          description={'Average Set Speed'}
        />

        <LineItem
          value={this.props.trip?.getStatistics()?.getAverageHaulSpeed() != null ? (this.props.trip?.getStatistics()?.getAverageHaulSpeed()?.toFixed(1) + ' knots') : null}
          description={'Average Haul Speed'}
        />

        <Typography variant='h5' style={{ textAlign: 'center' }}>Catch Efficiency</Typography>

        {/** Catch lbs per drag minute or catch lbs per gear */}
        <LineItem
          value={gearAmount === 0 ? 'Unavailable' : (
            this.props.trip?.getStatistics()?.getRetainedLbsPerDragMinute() != null ? (
              this.props.trip?.getConvertToKg() ? (
                (this.props.trip?.getStatistics()?.getRetainedLbsPerDragMinute() * LBS_TO_KG)?.toFixed(1) + ' kg/min'
              ) : (
                this.props.trip?.getStatistics()?.getRetainedLbsPerDragMinute()?.toFixed(1) + ' lbs/min'
              )
            ) : (
              this.props.trip?.getConvertToKg() ? (
                (this.props.trip?.getStatistics()?.getRetainedLbsPerGear() * LBS_TO_KG)?.toFixed(1) + ' kg/gear'
              ) : (
                this.props.trip?.getStatistics()?.getRetainedLbsPerGear()?.toFixed(1) + ' lbs/gear'
              ) + (this.props.trip.getSets().length > 0 && this.props.trip.getSets()[0].getGearSubtype() != null ? ` (${this.props.trip.getSets()[0].getGearSubtype()})` : '')
            )
          )}
          description={'Target Catch Rate'}
        />

        {/** Discard lbs per drag minute or catch lbs per gear */}
        <LineItem
          value={gearAmount === 0 ? 'Unavailable' : (
            this.props.trip?.getStatistics()?.getDiscardLbsPerDragMinute() != null ? (
              this.props.trip?.getConvertToKg() ? (
                (this.props.trip?.getStatistics()?.getDiscardLbsPerDragMinute() * LBS_TO_KG)?.toFixed(1) + ' kg/min'
              ) : (
                this.props.trip?.getStatistics()?.getDiscardLbsPerDragMinute()?.toFixed(1) + ' lbs/min'
              )
            ) : (
              this.props.trip?.getConvertToKg() ? (
                (this.props.trip?.getStatistics()?.getDiscardLbsPerGear() * LBS_TO_KG)?.toFixed(1) + ' kg/gear'
              ) : (
                this.props.trip?.getStatistics()?.getDiscardLbsPerGear()?.toFixed(1) + ' lbs/gear'
              ) + (this.props.trip.getSets().length > 0 && this.props.trip.getSets()[0].getGearSubtype() != null ? ` (${this.props.trip.getSets()[0].getGearSubtype()})` : '')
            )
          )}
          description={'Bycatch Rate'}
        />

        {/** Catch lbs per processing hour */}
        <LineItem
          value={
            <>
              <Typography>
                {this.props.trip?.getConvertToKg() ? (
                  (this.props.trip?.getStatistics()?.getCatchLbsPerProcessingHour() * LBS_TO_KG)?.toFixed(1) + ' kg/crewhour'
                ): (
                  this.props.trip?.getStatistics()?.getCatchLbsPerProcessingHour()?.toFixed(1) + ' lbs/crewhour'
                )}
              </Typography>
              <Button disabled={this.state.isLoading || this.props.trip?.getStatistics() == null} style={{ width: '100px' }} onClick={() =>
                this.setState({ showSetHaulShift: true, tripStatistics: this.props.trip?.getStatistics() })
              }>
                View
              </Button>
            </>
          }
          description={'Catch Processing Rate'}
        />

        <Typography variant='h5' style={{ textAlign: 'center' }}>Catch Quality</Typography>

        {/** average catch time on deck */}
        <LineItem
          value={humanizeDuration(this.props.trip?.getStatistics()?.getCatchQuality(), { round: true })}
          description={'Avg. Time On-Deck'}
        />
      </>
    );
  }

  selectedObjectInfo = () => {
    return (
      <>
        {/* Set Info */}
        {this.state.selectedObject != null && this.state.selectedObject?.getObjectType() === SET && (
          <>
            <Typography variant='h4' style={{ textAlign: 'center' }}>Set Detail</Typography>
            <LineItem
              value={this.state.selectedObject?.getStartTimestamp()?.setZone(this.props.trip?.getTimezone())?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
              description={'Set Start'}
            />
            <LineItem
              value={this.state.selectedObject?.getElapsedTime() != null ? this.displayDuration(this.state.selectedObject?.getElapsedTime()) : null}
              description={'Set Time'}
            />
            {!this.state.selectedObject.getExcludeFromCalculations() &&
            <LineItem
              value={this.state.selectedObject?.getTotalDistance() != null ? (
                this.props.trip?.getConvertToKg() ? (
                  (this.state.selectedObject.getTotalDistance() * MILES_TO_KM).toFixed(1) + ' km'
                ) : (
                  this.state.selectedObject.getTotalDistance().toFixed(1) + ' miles'
                )
              ) : null}
              description={'Set Distance'}
            />}
            {!this.state.selectedObject.getExcludeFromCalculations() &&
            <LineItem
              value={this.state.selectedObject?.getAverageSpeed() != null ? (this.state.selectedObject?.getAverageSpeed()?.toFixed(1) + ' knots') : null}
              description={'Avg Set Speed'}
            />}
            {this.state.selectedObject?.getGearType() && <LineItem
              value={this.state.selectedObject?.getGearType()}
              description={'Gear Type'}
            />}
            {this.state.selectedObject?.getIsAnalyzed() && !this.state.selectedObject.getExcludeFromCalculations() && this.state.selectedObject?.getGearAmount() && <LineItem
              value={this.state.selectedObject?.getGearAmount()}
              description={'Gear Qty'}
            />}
            {this.state.selectedObject?.getGearSubtype() && <LineItem
              value={this.state.selectedObject?.getGearSubtype()}
              description={'Gear Subtype'}
            />}

            {this.state.selectedObject?.getDepth() && !this.state.selectedObject.getExcludeFromCalculations() && <LineItem
              value={this.state.selectedObject?.getDepth() + ' ft'}
              description={'Set Depth'}
            />}

            {this.state.selectedObject?.getIsAnalyzed() && !this.state.selectedObject.getExcludeFromCalculations() && this.state.selectedObject?.getCrewAmount() &&
            <LineItem
              value={this.state.selectedObject?.getCrewAmount()?.toFixed(1)}
              description={'Crewmen'}
            />}
          </>
        )}

        {/* Haul Info */}
        {this.state.selectedObject != null && this.state.selectedObject?.getObjectType() === HAUL && (
          <>
            <Typography variant='h4' style={{ textAlign: 'center' }}>Haul Detail</Typography>

            <LineItem
              value={this.state.selectedObject?.getStartTimestamp()?.setZone(this.props.trip?.getTimezone())?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
              description={'Haul Start'}
            />

            <LineItem
              value={this.state.selectedObject?.getElapsedTime() != null ? this.displayDuration(this.state.selectedObject?.getElapsedTime()) : null}
              description={'Haul Time'}
            />

            {!this.state.selectedObject.getExcludeFromCalculations() &&
            <>
              <LineItem
                value={this.state.selectedObject?.getTotalDistance() != null ? (
                  this.props.trip?.getConvertToKg() ? (
                    (this.state.selectedObject.getTotalDistance() * MILES_TO_KM).toFixed(1) + ' km'
                  ) : (
                    this.state.selectedObject.getTotalDistance().toFixed(1) + ' miles'
                  )
                ): null}
                description={'Haul Distance'}
              />

              <LineItem
                value={this.state.selectedObject?.getAverageSpeed() != null ? this.state.selectedObject?.getAverageSpeed()?.toFixed(1) + ' knots' : null}
                description={'Avg Haul Speed'}
              />

              {this.state.selectedObject?.getRetainedLbs() != null && this.state.selectedObject?.getIsAnalyzed() &&
              <LineItem
                value={
                  <>
                    <Typography>
                      {this.state.selectedObject?.getRetainedLbs() == null ? 'Unknown' : (
                        this.props.trip?.getConvertToKg() ? (
                          (this.state.selectedObject?.getRetainedLbs() * LBS_TO_KG)?.toFixed(1) + ' kg'
                        ) : (
                          this.state.selectedObject?.getRetainedLbs()?.toFixed(1) + ' lbs'
                        )
                      )}
                    </Typography>
                    <Button disabled={this.state.isLoading} style={{ width: '100px' }} onClick={() =>
                      this.setState({ fishCatchTitle: 'Catch', showFishCatch: true, fishCatch: this.state.selectedObject?.getCatch().filter(e => e.getCatchType() == 'Retained') })
                    }>
                      View
                    </Button>
                  </>
                }
                description={'Target Catch'}
              />}

              {/** Discard lbs here */}
              {this.state.selectedObject?.getDiscardLbs() != null && this.state.selectedObject?.getIsAnalyzed() &&
              <LineItem
                value={
                  <>
                    <Typography>
                      {this.state.selectedObject?.getDiscardLbs() == null ? 'Unknown' : (
                        this.props.trip?.getConvertToKg() ? (
                          (this.state.selectedObject?.getDiscardLbs() * LBS_TO_KG)?.toFixed(1) + ' kg'
                        ) : (
                          this.state.selectedObject?.getDiscardLbs()?.toFixed(1) + ' lbs'
                        )
                      )}
                    </Typography>
                    <Button disabled={this.state.isLoading} style={{ width: '100px' }} onClick={() =>
                      this.setState({ fishCatchTitle: 'Bycatch', showFishCatch: true, fishCatch: this.state.selectedObject?.getCatch().filter(e => e.getCatchType() == 'Discard') })
                    }>
                      View
                    </Button>
                  </>
                }
                description={'Bycatch'}
              />}

              {this.state.selectedObject?.getIsAnalyzed() &&
              <LineItem
                value={this.state.selectedObject?.getSet()?.getGearAmount() == null ? 'Unavailable' : (
                  this.state.selectedObject?.getRetainedLbsPerDragMinute() != null ? (
                    this.props.trip?.getConvertToKg() ? (
                      (this.state.selectedObject?.getRetainedLbsPerDragMinute() * LBS_TO_KG)?.toFixed(1) + ' kg/min'
                    ) : (
                      this.state.selectedObject?.getRetainedLbsPerDragMinute()?.toFixed(1) + ' lbs/min'
                    )
                  ) : (
                    this.props.trip?.getConvertToKg() ? (
                      (this.state.selectedObject?.getRetainedLbsPerGear() * LBS_TO_KG)?.toFixed(1) + ' kg/gear'
                    ) : (
                      this.state.selectedObject?.getRetainedLbsPerGear()?.toFixed(1) + ' lbs/gear'
                    ) + (this.props.trip.getSets().length > 0 && this.props.trip.getSets()[0].getGearSubtype() != null ? ` (${this.props.trip.getSets()[0].getGearSubtype()})` : '')
                  )
                )}
                description={'Target Catch Rate'}
              />}

              {this.state.selectedObject?.getIsAnalyzed() &&
              <LineItem
                value={this.state.selectedObject?.getSet()?.getGearAmount() == null ? 'Unavailable' : (
                  this.state.selectedObject?.getDiscardLbsPerDragMinute() != null ? (
                    this.props.trip?.getConvertToKg() ? (
                      (this.state.selectedObject?.getDiscardLbsPerDragMinute() * LBS_TO_KG)?.toFixed(1) + ' kg/min'
                    ) : (
                      this.state.selectedObject?.getDiscardLbsPerDragMinute()?.toFixed(1) + ' lbs/min'
                    )
                  ) : (
                    this.props.trip?.getConvertToKg() ? (
                      (this.state.selectedObject?.getDiscardLbsPerGear() * LBS_TO_KG)?.toFixed(1) + ' kg/gear'
                    ) : (
                      this.state.selectedObject?.getDiscardLbsPerGear()?.toFixed(1) + ' lbs/gear'
                    ) + (this.props.trip.getSets().length > 0 && this.props.trip.getSets()[0].getGearSubtype() != null ? ` (${this.props.trip.getSets()[0].getGearSubtype()})` : '')
                  )
                )}
                description={'Bycatch Rate'}
              />}

              {this.state.selectedObject?.getIsAnalyzed() && this.state.selectedObject?.getCrewAmount() != null &&
              <LineItem
                value={this.state.selectedObject?.getCrewAmount()?.toFixed(1)}
                description={'Crewmen'}
              />}

              {this.state.selectedObject?.getIsAnalyzed() && this.state.selectedObject?.getCatchLbsPerProcessingHour() != null &&
              <LineItem
                value={this.state.selectedObject?.getCatchLbsPerProcessingHour() != null ? (
                  this.props.trip?.getConvertToKg() ? (
                    (this.state.selectedObject?.getCatchLbsPerProcessingHour() * LBS_TO_KG)?.toFixed(1) + ' kg/crewhour'
                  ) : (
                    this.state.selectedObject?.getCatchLbsPerProcessingHour()?.toFixed(1) + ' lbs/crewhour'
                  )
                ) : null}
                description={'Catch Processing Rate'}
              />}

              {/** Catch time-on-deck */}
              {this.state.selectedObject?.getIsAnalyzed() && this.state.selectedObject.getCatchQuality() != null &&
              <LineItem
                value={humanizeDuration(this.state.selectedObject.getCatchQuality(), { round: true })}
                description={'Avg. Time On-Deck'}
              />}
            </>}
          </>
        )}
      </>
    );
  }

  render() {
    return (
      <div>

        <FishCatchDialog
          open={this.state.showFishCatch}
          onClose={() => this.setState({ showFishCatch: false, fishCatch: [] })}
          fishCatch={this.state.fishCatch}
          title={this.state.fishCatchTitle}
          convertToKg={this.props.trip?.getConvertToKg()}
        />

        <SetHaulShiftDialog
          open={this.state.showSetHaulShift}
          onClose={() => this.setState({ showSetHaulShift: false })}
          tripStatistics={this.state.tripStatistics}
        />

        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item md={9} xs={12}>
            <SteamMapWidget
              width={'100%'}
              height={600}
              sets={this.state.showSets ? this.state.sets : []}
              hauls={this.state.showHauls ? this.state.hauls : []}
              steam={this.state.showSteam ? this.state.steam : []}
              clearSelection={this.clearSelection}
              onSetSelection={this.onSetSelection}
              onHaulSelection={this.onHaulSelection}
              selectedObject={this.state.selectedObject}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <div style={{ width: '100%' }}>

              {/* Layer Selection */}
              <div style={{ border: '1px solid grey', padding: 20, borderRadius: 10, marginBottom: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox name='showSets' defaultChecked={this.state.showSets} onChange={this.onCheckboxChange} sx={{ color: SET_COLOR, '&.Mui-checked': { color: SET_COLOR } }} />
                  <Typography variant='body1'>
                    Sets
                    {this.state.isLoadingSets && <CircularProgress size={15} style={{ marginLeft: 10 }} />}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox name='showHauls' defaultChecked={this.state.showHauls} onChange={this.onCheckboxChange} sx={{ color: HAUL_COLOR, '&.Mui-checked': { color: HAUL_COLOR } }} />
                  <Typography variant='body1'>
                    Hauls
                    {this.state.isLoadingHauls && <CircularProgress size={15} style={{ marginLeft: 10 }} />}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox name='showSteam' defaultChecked={this.state.showSteam} onChange={this.onCheckboxChange} sx={{ color: STEAM_COLOR, '&.Mui-checked': { color: STEAM_COLOR } }} />
                  <Typography variant='body1'>
                    Steam
                    {this.state.isLoadingSteam && <CircularProgress size={15} style={{ marginLeft: 10 }} />}
                  </Typography>
                </div>
              </div>

              {/* Information */}
              <Box sx={{ height: { xs: '100%', md: '400px' }, overflow: 'scroll', border: '1px solid grey', padding: '15px 20px 10px 20px', borderRadius: '10px' }}>
                {/* Trip Info */}

                <Box sx={{ display: 'block' }}>
                  {this.state.selectedObject == null && this.props.trip != null && this.tripInfo()}

                  {this.selectedObjectInfo()}
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

OverviewView.propTypes = {
  trip: PropTypes.object.isRequired
};

export default OverviewView;

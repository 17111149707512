import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { removeAlert } from '../redux/actions/alertActions';
import { clearSkeletonUser } from '../redux/actions/authActions';

// Alerts
import { setInfo } from '../alerts';
const ALERT_ID = '0000000000';

class SkeletonKeyAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToHome: false
    };
  }

  componentDidMount() {
    this.checkSkeletonKey();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.skeletonUserID !== this.props.skeletonUserID) {
      this.checkSkeletonKey();
    }
  }

  checkSkeletonKey() {
    if (this.props.skeletonUserID != null) {
      setInfo({
        id: ALERT_ID,
        msg: 'Using Skeleton Key for User: ' + this.props.skeletonUserName + ' (id: ' + this.props.skeletonUserID + ')',
        showClose: false,
        onButtonPress: () => {
          this.props.removeAlert(ALERT_ID);
          this.props.clearSkeletonUser();
          this.setState({ redirectToHome: true });
        },
        buttonText: 'STOP'
      });
    }
    else {
      this.props.removeAlert(ALERT_ID);
    }
  }

  render() {
    return this.state.redirectToHome ? <Redirect to='/' /> : null;
  }
}

SkeletonKeyAlert.propTypes = {
  skeletonUserID: PropTypes.number,
  skeletonUserName: PropTypes.string,
  removeAlert: PropTypes.func.isRequired,
  clearSkeletonUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  skeletonUserID: state.auth.skeletonUserID,
  skeletonUserName: state.auth.skeletonUserName
});

const mapDispatchToProps = dispatch => ({
  removeAlert: (id) => dispatch(removeAlert(id)),
  clearSkeletonUser: () => dispatch(clearSkeletonUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(SkeletonKeyAlert);

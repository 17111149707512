import { DateTime } from 'luxon';
import { Boat, TripProject, TripStatistics, TripPosition, GuestTripPermission, FishCatch, TripSet, TripCamera } from './';

export default class Trip {
  static thaw(tripObj) {
    if (tripObj == null) return null;
    const trip = new Trip();
    if (tripObj.id != null) trip.setID(tripObj.id);
    if (tripObj.startTimestamp != null) trip.setStartTimestamp(DateTime.fromISO(tripObj.startTimestamp));
    if (tripObj.stopTimestamp != null) trip.setStopTimestamp(DateTime.fromISO(tripObj.stopTimestamp));
    if (tripObj.isActive != null) trip.setIsActive(tripObj.isActive == null ? null : (tripObj.isActive ? true : false));
    if (tripObj.boatId != null) trip.setBoatID(tripObj.boatId);
    if (tripObj.htmlReport != null) trip.setHtmlReport(tripObj.htmlReport);
    if (tripObj.pdfReport != null) trip.setPdfReport(tripObj.pdfReport);
    if (tripObj.reportUrls != null) trip.setReportUrls(tripObj.reportUrls);
    if (tripObj.timezone != null) trip.setTimezone(tripObj.timezone);
    if (tripObj.convertToKg != null) trip.setConvertToKg(tripObj.convertToKg == null ? null : (tripObj.convertToKg ? true : false));
    if (tripObj.boat != null) trip.setBoat(Boat.thaw(tripObj.boat));
    if (tripObj.projects != null) trip.setProjects(TripProject.thawList(tripObj.projects));
    if (tripObj.isProcessed != null) trip.setProcessed(tripObj.isProcessed);
    if (tripObj.statistics != null) trip.setStatistics(TripStatistics.thaw(tripObj.statistics));
    if (tripObj.positions != null) trip.setPositions(TripPosition.thawList(tripObj.positions));
    if (tripObj.sets != null) trip.setSets(TripSet.thawList(tripObj.sets));
    if (tripObj.catch != null) trip.setCatch(FishCatch.thawList(tripObj.catch));
    if (tripObj.guestPermissions != null) trip.setGuestPermissions(GuestTripPermission.thawList(tripObj.guestPermissions));
    if (tripObj.tripCameras != null) trip.setTripCameras(TripCamera.thawList(tripObj.tripCameras));
    if (tripObj.visibleToUser != null) trip.setVisibleToUser(tripObj.visibleToUser ? true : false);
    return trip;
  }

  static thawList(tripObjList) {
    if (tripObjList == null) return [];
    var trips = [];
    for (var i = 0; i < tripObjList.length; i++) {
      trips.push(Trip.thaw(tripObjList[i]));
    }
    return trips;
  }

  static freeze(trip) {
    var tripObj = {};
    if (trip.getID() != null) tripObj.id = trip.getID();
    if (trip.getStartTimestamp() != null) tripObj.startTimestamp = trip.getStartTimestamp();
    if (trip.getStopTimestamp() != null) tripObj.stopTimestamp = trip.getStopTimestamp();
    if (trip.getIsActive() != null) tripObj.isActive = trip.getIsActive() == null ? null : (trip.getIsActive() ? 1 : 0);
    if (trip.getBoatID() != null) tripObj.boatId = trip.getBoatID();
    if (trip.getTimezone() != null) tripObj.timezone = trip.getTimezone();
    if (trip.getConvertToKg() != null) tripObj.convertToKg = trip.getConvertToKg() == null ? null : (trip.getConvertToKg() ? 1 : 0);
    if (trip.getBoat() != null) tripObj.boat = Boat.freeze(trip.getBoat());
    if (trip.getProjects() != null) tripObj.projects = TripProject.freezeList(trip.getProjects());
    if (trip.isProcessed() != null) tripObj.isProcessed = trip.isProcessed();
    if (trip.getStatistics() != null) tripObj.statistics = TripStatistics.freeze(trip.getStatistics());
    if (trip.getPositions() != null) tripObj.positions = TripPosition.freezeList(trip.getPositions());
    if (trip.getSets() != null) tripObj.sets = TripSet.freezeList(trip.getSets());
    if (trip.getCatch() != null) tripObj.catch = FishCatch.freezeList(trip.getCatch());
    if (trip.getGuestPermissions() != null) tripObj.guestPermissions = GuestTripPermission.freezeList(trip.getGuestPermissions());
    if (trip.getTripCameras() != null) tripObj.tripCameras = TripCamera.freezeList(trip.getTripCameras());
    if (trip.getVisibleToUser() != null) tripObj.visibleToUser = trip.getVisibleToUser() ? 1 : 0;
    return tripObj;
  }

  static freezeList(trips) {
    if (trips == null) return [];
    var tripObjList = [];
    for (var i = 0; i < trips.length; i++) {
      tripObjList.push(Trip.freeze(trips[i]));
    }
    return tripObjList;
  }

  constructor() {
    this.setID(null);
    this.setStartTimestamp(null);
    this.setStopTimestamp(null);
    this.setIsActive(null);
    this.setBoatID(null);
    this.setHtmlReport(null);
    this.setPdfReport(null);
    this.setReportUrls(null);
    this.setTimezone(null);
    this.setConvertToKg(null);
    this.setBoat(null);
    this.setProjects(null);
    this.setProcessed(null);
    this.setStatistics(null);
    this.setPositions(null);
    this.setSets(null);
    this.setCatch(null);
    this.setGuestPermissions(null);
    this.setTripCameras(null);
    this.setVisibleToUser(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setStartTimestamp(startTimestamp) {
    this.startTimestamp = startTimestamp;
  }

  setStopTimestamp(stopTimestamp) {
    this.stopTimestamp = stopTimestamp;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setBoatID(boatID) {
    this.boatID = boatID;
  }

  setHtmlReport(htmlReport) {
    this.htmlReport = htmlReport;
  }

  setPdfReport(pdfReport) {
    this.pdfReport = pdfReport;
  }

  setReportUrls(reportUrls) {
    this.reportUrls = reportUrls;
  }

  setTimezone(timezone) {
    this.timezone = timezone;
  }

  setConvertToKg(convertToKg) {
    this.convertToKg = convertToKg;
  }

  setBoat(boat) {
    this.boat = boat;
  }

  setProjects(projects) {
    this.projects = projects;
  }

  setProcessed(processed) {
    this.processed = processed;
  }

  setStatistics(statistics) {
    this.statistics = statistics;
  }

  setPositions(positions) {
    this.positions = positions;
  }

  setSets(sets) {
    this.sets = sets;
  }

  setCatch(catchValue) {
    this.catch = catchValue;
  }

  setGuestPermissions(guestPermissions) {
    this.guestPermissions = guestPermissions;
  }

  setTripCameras(tripCameras) {
    this.tripCameras = tripCameras;
  }

  setVisibleToUser(visibleToUser) {
    this.visibleToUser = visibleToUser;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getStartTimestamp() {
    return this.startTimestamp;
  }

  getStopTimestamp() {
    return this.stopTimestamp;
  }

  getIsActive() {
    return this.isActive;
  }

  getBoatID() {
    return this.boatID;
  }

  getHtmlReport() {
    return this.htmlReport;
  }

  getPdfReport() {
    return this.pdfReport;
  }

  getReportUrls() {
    return this.reportUrls;
  }

  getTimezone() {
    return this.timezone;
  }

  getConvertToKg() {
    return this.convertToKg;
  }

  getBoat() {
    return this.boat;
  }

  getProjects() {
    return this.projects;
  }

  isProcessed() {
    return this.processed;
  }

  getStatistics() {
    return this.statistics;
  }

  getPositions() {
    return this.positions;
  }

  getSets() {
    return this.sets;
  }

  getCatch() {
    return this.catch;
  }

  getGuestPermissions() {
    return this.guestPermissions;
  }

  getTripCameras() {
    return this.tripCameras;
  }

  getVisibleToUser() {
    return this.visibleToUser;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import { User, Boat, UserInvitation } from './';

export default class Business {
  static thaw(businessObj) {
    if (businessObj == null) return null;
    const business = new Business();
    if (businessObj.id != null) business.setID(businessObj.id);
    if (businessObj.name != null) business.setName(businessObj.name);
    if (businessObj.contactName != null) business.setContactName(businessObj.contactName);
    if (businessObj.contactEmail != null) business.setContactEmail(businessObj.contactEmail);
    if (businessObj.isActive != null) business.setIsActive(businessObj.isActive == null ? null : (businessObj.isActive ? true : false));
    if (businessObj.user != null) business.setUser(User.thaw(businessObj.user));
    if (businessObj.boats != null) business.setBoats(Boat.thawList(businessObj.boats));
    if (businessObj.invitations != null) business.setInvitations(UserInvitation.thawList(businessObj.invitations));
    if (businessObj.associatedGuests != null) business.setAssociatedGuests(User.thawList(businessObj.associatedGuests));
    return business;
  }

  static thawList(businessObjList) {
    if (businessObjList == null) return [];
    var businesses = [];
    for (var i = 0; i < businessObjList.length; i++) {
      businesses.push(Business.thaw(businessObjList[i]));
    }
    return businesses;
  }

  static freeze(business) {
    var businessObj = {};
    if (business.getID() != null) businessObj.id = business.getID();
    if (business.getName() != null) businessObj.name = business.getName();
    if (business.getContactName() != null) businessObj.contactName = business.getContactName();
    if (business.getContactEmail() != null) businessObj.contactEmail = business.getContactEmail();
    if (business.getIsActive() != null) businessObj.isActive = business.getIsActive() == null ? null : (business.getIsActive() ? 1 : 0);
    if (business.getUser() != null) businessObj.user = User.freeze(business.getUser());
    if (business.getBoats() != null) businessObj.boats = Boat.freezeList(business.getBoats());
    if (business.getInvitations() != null) businessObj.invitations = UserInvitation.freezeList(business.getInvitations());
    if (business.getAssociatedGuests() != null) businessObj.associatedGuests = User.freezeList(business.getAssociatedGuests());
    return businessObj;
  }

  static freezeList(businesses) {
    if (businesses == null) return [];
    var businessObjList = [];
    for (var i = 0; i < businesses.length; i++) {
      businessObjList.push(Business.freeze(businesses[i]));
    }
    return businessObjList;
  }

  constructor() {
    this.setID(null);
    this.setName(null);
    this.setContactName(null);
    this.setContactEmail(null);
    this.setIsActive(null);
    this.setUser(null);
    this.setBoats(null);
    this.setInvitations(null);
    this.setAssociatedGuests(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setName(name) {
    this.name = name;
  }

  setContactName(contactName) {
    this.contactName = contactName;
  }

  setContactEmail(contactEmail) {
    this.contactEmail = contactEmail;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setUser(user) {
    this.user = user;
  }

  setBoats(boats) {
    this.boats = boats;
  }

  setInvitations(invitations) {
    this.invitations = invitations;
  }

  setAssociatedGuests(associatedGuests) {
    this.associatedGuests = associatedGuests;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getContactName() {
    return this.contactName;
  }

  getContactEmail() {
    return this.contactEmail;
  }

  getIsActive() {
    return this.isActive;
  }

  getUser() {
    return this.user;
  }

  getBoats() {
    return this.boats;
  }

  getInvitations() {
    return this.invitations;
  }

  getAssociatedGuests() {
    return this.associatedGuests;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import { DateTime } from 'luxon';
import { Business, Role, ChangeEmailRequest, ForgotPasswordRequest, GuestTripPermission } from './';
import { USER_TYPES } from '../constants';

export default class User {
  static thaw(userObj) {
    if (userObj == null) return null;
    const user = new User();
    if (userObj.id != null) user.setID(userObj.id);
    if (userObj.firstName != null) user.setFirstName(userObj.firstName);
    if (userObj.lastName != null) user.setLastName(userObj.lastName);
    if (userObj.email != null) user.setEmail(userObj.email);
    if (userObj.phoneNumber != null) user.setPhoneNumber(userObj.phoneNumber);
    if (userObj.hash != null) user.setHash(userObj.hash);
    if (userObj.twoFactorSetupRequired != null) user.setTwoFactorSetupRequired(userObj.twoFactorSetupRequired == null ? null : (userObj.twoFactorSetupRequired ? true : false));
    if (userObj.lastLoginTimestamp != null) user.setLastLoginTimestamp(DateTime.fromISO(userObj.lastLoginTimestamp));
    if (userObj.isActive != null) user.setIsActive(userObj.isActive == null ? null : (userObj.isActive ? true : false));
    if (userObj.businessId != null) user.setBusinessID(userObj.businessId);
    if (userObj.roleId != null) user.setRoleID(userObj.roleId);
    if (userObj.mfaMethod != null) user.setMfaMethod(userObj.mfaMethod);
    if (userObj.lastMfaSentTimestamp != null) user.setLastMfaSentTimestamp(DateTime.fromISO(userObj.lastMfaSentTimestamp));
    if (userObj.associatedWithBusinessId != null) user.setAssociatedWithBusinessID(userObj.associatedWithBusinessId);
    if (userObj.associatedWithBusiness != null) user.setAssociatedWithBusiness(Business.thaw(userObj.associatedWithBusiness));
    if (userObj.business != null) user.setBusiness(Business.thaw(userObj.business));
    if (userObj.role != null) user.setRole(Role.thaw(userObj.role));
    if (userObj.changeEmailRequest != null) user.setChangeEmailRequest(ChangeEmailRequest.thawList(userObj.changeEmailRequest));
    if (userObj.forgotPasswordRequest != null) user.setForgotPasswordRequest(ForgotPasswordRequest.thawList(userObj.forgotPasswordRequest));
    if (userObj.guestPermissions != null) user.setGuestPermissions(GuestTripPermission.thawList(userObj.guestPermissions));
    return user;
  }

  static thawList(userObjList) {
    if (userObjList == null) return [];
    var users = [];
    for (var i = 0; i < userObjList.length; i++) {
      users.push(User.thaw(userObjList[i]));
    }
    return users;
  }

  static freeze(user) {
    var userObj = {};
    if (user.getID() != null) userObj.id = user.getID();
    if (user.getFirstName() != null) userObj.firstName = user.getFirstName();
    if (user.getLastName() != null) userObj.lastName = user.getLastName();
    if (user.getEmail() != null) userObj.email = user.getEmail();
    if (user.getPhoneNumber() != null) userObj.phoneNumber = user.getPhoneNumber();
    if (user.getHash() != null) userObj.hash = user.getHash();
    if (user.getTwoFactorSetupRequired() != null) userObj.twoFactorSetupRequired = user.getTwoFactorSetupRequired() == null ? null : (user.getTwoFactorSetupRequired() ? 1 : 0);
    if (user.getLastLoginTimestamp() != null) userObj.lastLoginTimestamp = user.getLastLoginTimestamp();
    if (user.getIsActive() != null) userObj.isActive = user.getIsActive() == null ? null : (user.getIsActive() ? 1 : 0);
    if (user.getBusinessID() != null) userObj.businessId = user.getBusinessID();
    if (user.getRoleID() != null) userObj.roleId = user.getRoleID();
    if (user.getMfaMethod() != null) userObj.mfaMethod = user.getMfaMethod();
    if (user.getLastMfaSentTimestamp() != null) userObj.lastMfaSentTimestamp = user.getLastMfaSentTimestamp();
    if (user.getAssociatedWithBusinessID() != null) userObj.associatedWithBusinessId = user.getAssociatedWithBusinessID();
    if (user.getAssociatedWithBusiness() != null) userObj.associatedWithBusiness = Business.freeze(user.getAssociatedWithBusiness());
    if (user.getBusiness() != null) userObj.business = Business.freeze(user.getBusiness());
    if (user.getRole() != null) userObj.role = Role.freeze(user.getRole());
    if (user.getChangeEmailRequest() != null) userObj.changeEmailRequest = ChangeEmailRequest.freezeList(user.getChangeEmailRequest());
    if (user.getForgotPasswordRequest() != null) userObj.forgotPasswordRequest = ForgotPasswordRequest.freezeList(user.getForgotPasswordRequest());
    if (user.getGuestPermissions() != null) userObj.guestPermissions = GuestTripPermission.freezeList(user.getGuestPermissions());
    return userObj;
  }

  static freezeList(users) {
    if (users == null) return [];
    var userObjList = [];
    for (var i = 0; i < users.length; i++) {
      userObjList.push(User.freeze(users[i]));
    }
    return userObjList;
  }

  constructor() {
    this.setID(null);
    this.setFirstName(null);
    this.setLastName(null);
    this.setEmail(null);
    this.setPhoneNumber(null);
    this.setHash(null);
    this.setTwoFactorSetupRequired(null);
    this.setLastLoginTimestamp(null);
    this.setIsActive(null);
    this.setBusinessID(null);
    this.setRoleID(null);
    this.setMfaMethod(null);
    this.setLastMfaSentTimestamp(null);
    this.setAssociatedWithBusinessID(null);
    this.setAssociatedWithBusiness(null);
    this.setBusiness(null);
    this.setRole(null);
    this.setChangeEmailRequest(null);
    this.setForgotPasswordRequest(null);
    this.setGuestPermissions(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setFirstName(firstName) {
    this.firstName = firstName;
  }

  setLastName(lastName) {
    this.lastName = lastName;
  }

  setEmail(email) {
    this.email = email;
  }

  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  setHash(hash) {
    this.hash = hash;
  }

  setTwoFactorSetupRequired(twoFactorSetupRequired) {
    this.twoFactorSetupRequired = twoFactorSetupRequired;
  }

  setLastLoginTimestamp(lastLoginTimestamp) {
    this.lastLoginTimestamp = lastLoginTimestamp;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setBusinessID(businessID) {
    this.businessID = businessID;
  }

  setRoleID(roleID) {
    this.roleID = roleID;
  }

  setMfaMethod(mfaMethod) {
    this.mfaMethod = mfaMethod;
  }

  setLastMfaSentTimestamp(lastMfaSentTimestamp) {
    this.lastMfaSentTimestamp = lastMfaSentTimestamp;
  }

  setAssociatedWithBusinessID(associatedWithBusinessID) {
    this.associatedWithBusinessID = associatedWithBusinessID;
  }

  setAssociatedWithBusiness(associatedWithBusiness) {
    this.associatedWithBusiness = associatedWithBusiness;
  }

  setBusiness(business) {
    this.business = business;
  }

  setRole(role) {
    this.role = role;
  }

  setChangeEmailRequest(changeEmailRequest) {
    this.changeEmailRequest = changeEmailRequest;
  }

  setForgotPasswordRequest(forgotPasswordRequest) {
    this.forgotPasswordRequest = forgotPasswordRequest;
  }

  setGuestPermissions(guestPermissions) {
    this.guestPermissions = guestPermissions;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getEmail() {
    return this.email;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getHash() {
    return this.hash;
  }

  getTwoFactorSetupRequired() {
    return this.twoFactorSetupRequired;
  }

  getLastLoginTimestamp() {
    return this.lastLoginTimestamp;
  }

  getIsActive() {
    return this.isActive;
  }

  getBusinessID() {
    return this.businessID;
  }

  getRoleID() {
    return this.roleID;
  }

  getMfaMethod() {
    return this.mfaMethod;
  }

  getLastMfaSentTimestamp() {
    return this.lastMfaSentTimestamp;
  }

  getAssociatedWithBusinessID() {
    return this.associatedWithBusinessID;
  }

  getAssociatedWithBusiness() {
    return this.associatedWithBusiness;
  }

  getBusiness() {
    return this.business;
  }

  getRole() {
    return this.role;
  }

  getChangeEmailRequest() {
    return this.changeEmailRequest;
  }

  getForgotPasswordRequest() {
    return this.forgotPasswordRequest;
  }

  getGuestPermissions() {
    return this.guestPermissions;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getName() {
    return (this.firstName != null && this.firstName !== '' ? this.firstName + ' ' : '') + this.lastName;
  }

  isAdmin() {
    return this.role?.name?.toLowerCase() === 'admin';
  }

  getUserType() {
    if (Object.keys(USER_TYPES).includes(this.role?.name?.toUpperCase())) {
      return USER_TYPES[this.role.name.toUpperCase()];
    }
    else return null;
  }
}

import { Trip, Image, Camera, VideoFolder } from './';

export default class TripProject {
  static thaw(tripProjectObj) {
    if (tripProjectObj == null) return null;
    const tripProject = new TripProject();
    if (tripProjectObj.id != null) tripProject.setID(tripProjectObj.id);
    if (tripProjectObj.projectId != null) tripProject.setProjectID(tripProjectObj.projectId);
    if (tripProjectObj.s3Path != null) tripProject.setS3Path(tripProjectObj.s3Path);
    if (tripProjectObj.isActive != null) tripProject.setIsActive(tripProjectObj.isActive == null ? null : (tripProjectObj.isActive ? true : false));
    if (tripProjectObj.tripId != null) tripProject.setTripID(tripProjectObj.tripId);
    if (tripProjectObj.cameraId != null) tripProject.setCameraID(tripProjectObj.cameraId);
    if (tripProjectObj.glacier != null) tripProject.setGlacier(tripProjectObj.glacier);
    if (tripProjectObj.trip != null) tripProject.setTrip(Trip.thaw(tripProjectObj.trip));
    if (tripProjectObj.camera != null) tripProject.setCamera(Camera.thaw(tripProjectObj.camera));
    if (tripProjectObj.foundMatchingBoat != null) tripProject.setFoundMatchingBoat(tripProjectObj.foundMatchingBoat ? true : false);
    if (tripProjectObj.foundMatchingCamera != null) tripProject.setFoundMatchingCamera(tripProjectObj.foundMatchingCamera ? true : false);
    if (tripProjectObj.videoFolders != null) tripProject.setVideoFolders(VideoFolder.thawList(tripProjectObj.videoFolders));
    if (tripProjectObj.images != null) tripProject.setImages(Image.thawList(tripProjectObj.images));
    return tripProject;
  }

  static thawList(tripProjectObjList) {
    if (tripProjectObjList == null) return [];
    var tripProjects = [];
    for (var i = 0; i < tripProjectObjList.length; i++) {
      tripProjects.push(TripProject.thaw(tripProjectObjList[i]));
    }
    return tripProjects;
  }

  static freeze(tripProject) {
    var tripProjectObj = {};
    if (tripProject.getID() != null) tripProjectObj.id = tripProject.getID();
    if (tripProject.getProjectID() != null) tripProjectObj.projectId = tripProject.getProjectID();
    if (tripProject.getS3Path() != null) tripProjectObj.s3Path = tripProject.getS3Path();
    if (tripProject.getIsActive() != null) tripProjectObj.isActive = tripProject.getIsActive() == null ? null : (tripProject.getIsActive() ? 1 : 0);
    if (tripProject.getTripID() != null) tripProjectObj.tripId = tripProject.getTripID();
    if (tripProject.getCameraID() != null) tripProjectObj.cameraId = tripProject.getCameraID();
    if (tripProject.getTrip() != null) tripProjectObj.trip = Trip.freeze(tripProject.getTrip());
    if (tripProject.getCamera() != null) tripProjectObj.camera = Camera.freeze(tripProject.getCamera());
    return tripProjectObj;
  }

  static freezeList(tripProjects) {
    if (tripProjects == null) return [];
    var tripProjectObjList = [];
    for (var i = 0; i < tripProjects.length; i++) {
      tripProjectObjList.push(TripProject.freeze(tripProjects[i]));
    }
    return tripProjectObjList;
  }

  constructor() {
    this.setID(null);
    this.setProjectID(null);
    this.setS3Path(null);
    this.setIsActive(null);
    this.setTripID(null);
    this.setCameraID(null);
    this.setGlacier(null);
    this.setTrip(null);
    this.setCamera(null);
    this.setFoundMatchingBoat(null);
    this.setFoundMatchingCamera(null);
    this.setVideoFolders(null);
    this.setImages(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setProjectID(projectID) {
    this.projectID = projectID;
  }

  setS3Path(s3Path) {
    this.s3Path = s3Path;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setGlacier(glacier) {
    this.glacier = glacier;
  }

  setTripID(tripID) {
    this.tripID = tripID;
  }

  setCameraID(cameraID) {
    this.cameraID = cameraID;
  }

  setTrip(trip) {
    this.trip = trip;
  }

  setCamera(camera) {
    this.camera = camera;
  }

  setFoundMatchingBoat(foundMatchingBoat) {
    this.foundMatchingBoat = foundMatchingBoat;
  }

  setFoundMatchingCamera(foundMatchingCamera) {
    this.foundMatchingCamera = foundMatchingCamera;
  }

  setVideoFolders(videoFolders) {
    this.videoFolders = videoFolders;
  }

  setImages(images) {
    this.images = images;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getProjectID() {
    return this.projectID;
  }

  getS3Path() {
    return this.s3Path;
  }

  getIsActive() {
    return this.isActive;
  }

  getTripID() {
    return this.tripID;
  }

  getCameraID() {
    return this.cameraID;
  }

  getGlacier() {
    return this.glacier;
  }

  getTrip() {
    return this.trip;
  }

  getCamera() {
    return this.camera;
  }

  getFoundMatchingBoat() {
    return this.foundMatchingBoat;
  }

  getFoundMatchingCamera() {
    return this.foundMatchingCamera;
  }

  getVideoFolders() {
    return this.videoFolders;
  }

  getImages() {
    return this.images;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Container, Paper, TextField, Typography } from '@mui/material';

// Alerts
import { setError, setSuccess } from '../alerts';

// API
import { createCamera, getBoat, listCameraConfigurations, updateCamera } from '../api/admin';

// Components
import { LineItem } from '../components';
import { CameraConfigurationFile } from '../lib';
import CameraConfigurationFileFormEntry from './CameraConfigurationFileFormEntry';

class CameraForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isBoatLoading: false,
      isConfigsLoading: false,
      name: '',
      awsIdentifier: '',
      rockBlockImei: '',
      configurationFileEntries: [],
      isReplacingDefaultConfigurationFile: false,
      defaultFileName: null,
      boat: null,
      camera: null
    };
  }

  componentDidMount() {
    if (this.props.camera != null) {
      this.setState({
        name: this.props.camera.getName(),
        awsIdentifier: this.props.camera.getAwsIdentifier(),
        rockBlockImei: this.props.camera.getRockBlockImei(),
      });

      // Retrieve boat object and configuration files
      this.setState({ isBoatLoading: true, isConfigsLoading: true });
      let boatID = this.props.camera != null ? this.props.camera.getBoatID() : this.props.boatID;

      if (this.props.camera != null) {
        listCameraConfigurations(this.props.camera.getID()).then(configurations => {
          const configurationFileEntries = [];
          for (const configuration of configurations) {
            if (configuration.getFileName() === 'default') {
              configurationFileEntries.unshift(configuration);
            }
            else {
              configurationFileEntries.push(configuration);
            }
          }
          this.setState({ isConfigsLoading: false, configurationFileEntries });
        }).catch(error => {
          this.setState({ isConfigsLoading: false });
          setError(error ? error : 'Error: Unable to retrieve boat.');
        });
      }
      else {
        setError('Error: Unable to retrieve camera configurations.');
      }

      getBoat(boatID).then(boat => {
        this.setState({ isBoatLoading: false, boat });
      }).catch(error => {
        this.setState({ isBoatLoading: false });
        setError(error ? error : 'Error: Unable to retrieve boat.');
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createCamera = (e) => {
    e.preventDefault();

    const { name, awsIdentifier, rockBlockImei, configurationFileEntries } = this.state;

    if (name === '' || awsIdentifier === '' || rockBlockImei === '') {
      setError('Error: All fields are required.');
      return;
    }

    this.setState({ isLoading: true });

    for (const entry of configurationFileEntries) {
      entry.setID(undefined);
      if (entry.getFileName() == null) {
        setError('Error: All configuration files must have a name.');
        this.setState({ isLoading: false });
        return;
      }
      else if (entry.getFileContent() != null && entry.getFileContent() !== '') {
        try {
          // This serves the dual purpose of validating the file content and minifying it.
          entry.setFileContent(JSON.stringify(JSON.parse(entry.getFileContent())));
        }
        catch (error) {
          setError(`Error: Configuration file "${entry.getFileName()}" has invalid JSON content: ${error.message}`);
          return;
        }
        finally {
          this.setState({ isLoading: false });
        }
      }
    }

    if (this.props.camera == null) {
      createCamera({ name, awsIdentifier, rockBlockImei, configurationFiles: CameraConfigurationFile.freezeList(configurationFileEntries), boatID: this.props.boatID }).then(camera => {
        setSuccess('Successfully created the camera.');
        this.setState({ isLoading: false, camera: camera });
      }).catch(error => {
        setError(error ? error : 'Error: Unable to create camera.');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateCamera(this.props.camera?.getID(), { name, awsIdentifier, rockBlockImei, configurationFiles: CameraConfigurationFile.freezeList(configurationFileEntries) }).then(() => {
        setSuccess('Successfully updated camera.');
        this.setState({ isLoading: false });
        this.props.onClose();
      }).catch(error => {
        setError(error ? error : 'Error: Unable to update camera.');
        this.setState({ isLoading: false });
      });
    }
  }

  addConfigurationFile = () => {
    const configFile = new CameraConfigurationFile();
    this.setState({
      configurationFileEntries: [...this.state.configurationFileEntries, configFile]
    });
  }

  /** @param {File} file */
  defaultFileUploaded = async (file) => {
    let defaultEntry;
    let defaultEntryIdx = this.state.configurationFileEntries.findIndex(entry => entry.getFileName() === 'Default');
    if (defaultEntryIdx === -1) {
      defaultEntry = new CameraConfigurationFile();
      defaultEntryIdx = this.state.configurationFileEntries.length;
      defaultEntry.setFileName('Default');
    }
    else {
      defaultEntry = this.state.configurationFileEntries[defaultEntryIdx];
    }

    defaultEntry.setFileContent((await file?.text()) ?? defaultEntry.getFileContent());

    const configurationFileEntries = [...this.state.configurationFileEntries];
    configurationFileEntries[defaultEntryIdx] = defaultEntry;
    this.setState({
      configurationFileEntries,
      defaultFileName: file?.name,
      isReplacingDefaultConfigurationFile: true
    });
  }

  render() {
    return this.state.camera != null ? <Redirect to={'/admin/cameras/' + this.state.camera?.getID()} /> : (
      <form onSubmit={this.createCamera}>
        <LineItem
          value={this.state.boat?.getBusiness()?.getName()}
          description='Business'
        />

        <LineItem
          value={this.state.boat?.getName()}
          description='Boat'
        />

        {/* Name Input */}
        <TextField
          required
          name='name'
          label='Name'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.name}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        {/* AWS Identifier */}
        <TextField
          required
          name='awsIdentifier'
          label='AWS Identifier'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.awsIdentifier}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        {/* Satellite IMEI */}
        <TextField
          required
          name='rockBlockImei'
          label='Satellite IMEI'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.rockBlockImei}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <Paper sx={{ display: 'flex', flexDirection: 'column', my: '1em', p: '0.5em', backgroundColor: 'lightgrey' }}>
          <Typography
            sx={{ margin: '0.5rem', ml: '1rem' }}
          >
            Default Configuration File
          </Typography>
          <Container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'space-between', my: '0.5rem' }}>
            <Button
              variant="contained"
              component="label"
              sx={{ flex: 1, mr: '0.5rem' }}
            >
              Select
              <input
                type="file"
                accept='application/json'
                onChange={(e) => this.defaultFileUploaded(e.target.files[0])}
                hidden
              />
            </Button>
            <Typography sx={{ flex: 4 }}>
              {this.state.defaultFileName ?? 'Upload a File'}
            </Typography>
          </Container>
        </Paper>

        {
          this.state.configurationFileEntries.map((entry, index) => (
            entry.getFileName() !== 'Default' ?
              <CameraConfigurationFileFormEntry
                key={index}
                setConfigurationFile={(configurationFile) => {
                  let configurationFiles = [...this.state.configurationFileEntries];
                  if (configurationFile != null) {
                    configurationFiles[index] = configurationFile;
                  }
                  else {
                    configurationFiles.splice(index, 1);
                  }
                  this.setState({ configurationFileEntries: configurationFiles });
                }}
                configurationFile={entry}
              />
              : null
          ))
        }

        <Button
          onClick={this.addConfigurationFile}
          disabled={this.state.isConfigsLoading}
        >
          Add New Config File
        </Button>

        {/* Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 50 }}>
          {this.props.onClose &&
          <Button onClick={this.props.onClose} disabled={this.state.isLoading} style={{ backgroundColor: 'grey', marginRight: 10 }}>
            Cancel
          </Button>}
          <Button type='submit' disabled={this.state.isLoading}>
            {this.props.camera != null ? 'Update' : 'Create'} Camera
            {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
          </Button>
        </div>
      </form>
    );
  }
}

CameraForm.propTypes = {
  boatID: PropTypes.number,
  onClose: PropTypes.func,
  camera: PropTypes.object
};

export default CameraForm;

import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import { CircularProgress, Container, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../../alerts';

// Components
import { CameraForm } from '../../../../components';

class CreateCamera extends Component {
  constructor(props) {
    super(props);

    this.boatID = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.id;

    if (this.boatID == null) {
      setError('Error: Something went wrong. Please try again.');
    }

    this.state = {
      redirectToBusinesses: this.boatID == null
    };
  }

  render() {
    return this.state.redirectToBusinesses ? <Redirect to='/admin/businesses' /> : (
      <Container style={{ marginTop: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Create New Camera</Typography>
          {this.state.isBoatLoading && <CircularProgress />}
        </div>

        <CameraForm
          boatID={this.boatID}
        />
      </Container>
    );
  }
}

CreateCamera.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(CreateCamera);

/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import { ADD_ALERT, REMOVE_ALERT, CLEAR_ERRORS, CLEAR_ALERTS, AlertSeverity } from '../actions/alertActions';

const initialState = [];

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT:
      const { id, msg, severity } = action; // eslint-disable-line

      // Remove any alerts with matching ids
      let currentState = state.filter(alert => alert.id !== id); // eslint-disable-line

      // Prevent more than 5 alerts to appear simultaneously or have duplicate errors (or ids)
      return currentState.length < 5 && currentState.filter(alert => alert.msg === msg).length === 0 ? [...currentState, action] : currentState;
    case REMOVE_ALERT:
      return state.filter(alert => alert.id !== action.id);
    case CLEAR_ERRORS:
      return state.filter(alert => alert.severity !== AlertSeverity.ERROR);
    case CLEAR_ALERTS:
      return initialState;
    default:
      return state;
  }
};

export default alerts;

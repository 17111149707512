export default class CameraKeyTypeDisplay {
  static thaw(cameraKeyTypeDisplayObj) {
    if (cameraKeyTypeDisplayObj == null) return null;
    const cameraKeyTypeDisplay = new CameraKeyTypeDisplay();
    if (cameraKeyTypeDisplayObj.id != null) cameraKeyTypeDisplay.setID(cameraKeyTypeDisplayObj.id);
    if (cameraKeyTypeDisplayObj.value != null) cameraKeyTypeDisplay.setValue(cameraKeyTypeDisplayObj.value);
    if (cameraKeyTypeDisplayObj.text != null) cameraKeyTypeDisplay.setText(cameraKeyTypeDisplayObj.text);
    if (cameraKeyTypeDisplayObj.cameraKeyTypeId != null) cameraKeyTypeDisplay.setCameraKeyTypeId(cameraKeyTypeDisplayObj.cameraKeyTypeId);
    return cameraKeyTypeDisplay;
  }

  static thawList(cameraKeyTypeDisplayObjList) {
    if (cameraKeyTypeDisplayObjList == null) return [];
    var cameraKeyTypeDisplays = [];
    for (var i = 0; i < cameraKeyTypeDisplayObjList.length; i++) {
      cameraKeyTypeDisplays.push(CameraKeyTypeDisplay.thaw(cameraKeyTypeDisplayObjList[i]));
    }
    return cameraKeyTypeDisplays;
  }

  static freeze(cameraKeyTypeDisplay) {
    var cameraKeyTypeDisplayObj = {};
    if (cameraKeyTypeDisplay.getID() != null) cameraKeyTypeDisplayObj.id = cameraKeyTypeDisplay.getID();
    if (cameraKeyTypeDisplay.getValue() != null) cameraKeyTypeDisplayObj.value = cameraKeyTypeDisplay.getValue();
    if (cameraKeyTypeDisplay.getText() != null) cameraKeyTypeDisplayObj.text = cameraKeyTypeDisplay.getText();
    if (cameraKeyTypeDisplay.getCameraKeyTypeId() != null) cameraKeyTypeDisplayObj.cameraKeyTypeId = cameraKeyTypeDisplay.getCameraKeyTypeId();
    return cameraKeyTypeDisplayObj;
  }

  static freezeList(cameraKeyTypeDisplays) {
    if (cameraKeyTypeDisplays == null) return [];
    var cameraKeyTypeDisplayObjList = [];
    for (var i = 0; i < cameraKeyTypeDisplays.length; i++) {
      cameraKeyTypeDisplayObjList.push(CameraKeyTypeDisplay.freeze(cameraKeyTypeDisplays[i]));
    }
    return cameraKeyTypeDisplayObjList;
  }

  constructor() {
    this.setID(null);
    this.setValue(null);
    this.setText(null);
    this.setCameraKeyTypeId(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setValue(value) {
    this.value = value;
  }

  setText(text) {
    this.text = text;
  }

  setCameraKeyTypeId(cameraKeyTypeId) {
    this.cameraKeyTypeId = cameraKeyTypeId;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getValue() {
    return this.value;
  }

  getText() {
    return this.text;
  }

  getCameraKeyTypeId() {
    return this.cameraKeyTypeId;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header } from '@lexcelon/react-util';

// Redux
import { connect } from 'react-redux';
import { checkAuthentication, logoutUser } from '../redux/actions/authActions';

// Images
import WhiteLogo from '../images/WhiteLogo.png';

// Constants
import { NAVY, USER_TYPES, WHITE } from '../constants';

const LOGGED_OUT_LINKS = [
  {
    slug: '/auth/login',
    text: 'Login'
  }
];

const BUSINESS_LINKS = [
  {
    slug: '/',
    text: 'Home'
  },
  {
    slug: '/history',
    text: 'History'
  }
];

const LOGGED_IN_LINKS = [
  {
    slug: '/account',
    text: 'Account',
  }
];

const USER_LINKS = [
  ...BUSINESS_LINKS,
  ...LOGGED_IN_LINKS
];

const ADMIN_ONLY_LINKS = [
  {
    slug: '/admin/users',
    text: 'Users'
  },
  {
    slug: '/admin',
    text: 'Admin'
  }
];

const ADMIN_LINKS = [
  ...BUSINESS_LINKS,
  ...LOGGED_IN_LINKS,
  ...ADMIN_ONLY_LINKS
];

const GUEST_LINKS = [
  {
    slug: '/history/guest',
    text: 'History'
  },
  ...LOGGED_IN_LINKS
];

class AppBar extends Component {
  componentDidMount() {
    this.props.checkAuthentication(this.props.isSoftAuthenticated, this.props.isFullyAuthenticated);
  }

  render() {
    return (
      <Header
        logo={WhiteLogo}
        logoDescription='FlyWire Logo'
        links={this.props.isFullyAuthenticated ? (
          this.props.userType === USER_TYPES.ADMIN ? (
            this.props.skeletonUserType === USER_TYPES.GUEST ? GUEST_LINKS : (
              this.props.skeletonUserType === USER_TYPES.USER ? USER_LINKS : ADMIN_LINKS
            )
          ) : (
            this.props.userType === USER_TYPES.USER ? USER_LINKS : GUEST_LINKS
          )
        ) : LOGGED_OUT_LINKS}
        backgroundColor={NAVY}
        textColor={WHITE}
        onLogout={this.props.isFullyAuthenticated ? this.props.onLogout : null}
      />
    );
  }
}

const mapStateToProps = state => ({
  isSoftAuthenticated: state.auth.isSoftAuthenticated,
  isFullyAuthenticated: state.auth.isFullyAuthenticated,
  userType: state.auth.userType,
  skeletonUserType: state.auth.skeletonUserUserType
});

const mapDispatchToProps = dispatch => ({
  checkAuthentication: (isSoftAuthenticated, isFullyAuthenticated) => checkAuthentication(dispatch, isSoftAuthenticated, isFullyAuthenticated),
  onLogout: () => logoutUser(dispatch)
});

AppBar.propTypes = {
  isSoftAuthenticated: PropTypes.bool.isRequired,
  isFullyAuthenticated: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  skeletonUserType: PropTypes.string.isRequired,
  checkAuthentication: PropTypes.func.isRequired,
  onLogout: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);

import { DateTime } from 'luxon';
import { Camera } from '.';

export default class SatelliteLogMessage {
  static thaw(satelliteMessageObj) {
    if (satelliteMessageObj == null) return null;
    const satelliteMessage = new SatelliteLogMessage();
    if (satelliteMessageObj.id != null) satelliteMessage.setID(satelliteMessageObj.id);
    if (satelliteMessageObj.cameraID != null) satelliteMessage.setCameraID(satelliteMessageObj.cameraID);
    if (satelliteMessageObj.camera != null) satelliteMessage.setCamera(Camera.thaw(satelliteMessageObj.camera));
    if (satelliteMessageObj.createdAt != null) satelliteMessage.setReceivedAt(DateTime.fromISO(satelliteMessageObj.createdAt));
    if (satelliteMessageObj.message != null) satelliteMessage.setMessage(satelliteMessageObj.message);
    return satelliteMessage;
  }

  static thawList(satelliteMessageObjList) {
    if (satelliteMessageObjList == null) return [];
    var satelliteMessages = [];
    for (var i = 0; i < satelliteMessageObjList.length; i++) {
      satelliteMessages.push(SatelliteLogMessage.thaw(satelliteMessageObjList[i]));
    }
    return satelliteMessages;
  }

  static freeze(satelliteMessage) {
    var satelliteMessageObj = {};
    if (satelliteMessage.getID() != null) satelliteMessageObj.id = satelliteMessage.getID();
    if (satelliteMessage.getCameraID() != null) satelliteMessageObj.cameraID = satelliteMessage.getCameraID();
    if (satelliteMessage.getCamera() != null) satelliteMessageObj.camera = Camera.freeze(satelliteMessage.getCamera());
    if (satelliteMessage.getReceivedAt() != null) satelliteMessageObj.createdAt = satelliteMessage.getReceivedAt();
    if (satelliteMessage.getMessage() != null) satelliteMessageObj.message = satelliteMessage.getMessage();
    return satelliteMessageObj;
  }

  static freezeList(satelliteMessages) {
    if (satelliteMessages == null) return [];
    var satelliteMessageObjList = [];
    for (var i = 0; i < satelliteMessages.length; i++) {
      satelliteMessageObjList.push(SatelliteLogMessage.freeze(satelliteMessages[i]));
    }
    return satelliteMessageObjList;
  }

  constructor() {
    this.setID(null);
    this.setCameraID(null);
    this.setCamera(null);
    this.setReceivedAt(null);
    this.setMessage(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setCameraID(cameraID) {
    this.cameraID = cameraID;
  }

  setCamera(camera) {
    this.camera = camera;
  }

  setReceivedAt(receivedAt) {
    this.receivedAt = receivedAt;
  }

  setMessage(message) {
    this.message = message;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getCameraID() {
    return this.cameraID;
  }

  getCamera() {
    return this.camera;
  }

  getReceivedAt() {
    return this.receivedAt;
  }

  getMessage() {
    return this.message;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

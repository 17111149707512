import Camera from './Camera';

export default class TripCamera {
  static thaw(tripCameraObj) {
    if (tripCameraObj == null) return null;
    const tripCamera = new TripCamera();
    if (tripCameraObj.id != null) tripCamera.setID(tripCameraObj.id);
    if (tripCameraObj.type != null) tripCamera.setType(tripCameraObj.type);
    if (tripCameraObj.tripId != null) tripCamera.setTripID(tripCameraObj.tripId);
    if (tripCameraObj.cameraId != null) tripCamera.setCameraID(tripCameraObj.cameraId);
    if (tripCameraObj.camera != null) tripCamera.setCamera(Camera.thaw(tripCameraObj.camera));
    return tripCamera;
  }

  static thawList(tripCameraObjList) {
    if (tripCameraObjList == null) return [];
    var tripCameras = [];
    for (var i = 0; i < tripCameraObjList.length; i++) {
      tripCameras.push(TripCamera.thaw(tripCameraObjList[i]));
    }
    return tripCameras;
  }

  static freeze(tripCamera) {
    var tripCameraObj = {};
    if (tripCamera.getID() != null) tripCameraObj.id = tripCamera.getID();
    if (tripCamera.getType() != null) tripCameraObj.type = tripCamera.getType();
    if (tripCamera.getTripID() != null) tripCameraObj.tripId = tripCamera.getTripID();
    if (tripCamera.getCameraID() != null) tripCameraObj.cameraId = tripCamera.getCameraID();
    if (tripCamera.getCamera() != null) tripCameraObj.camera = Camera.freeze(tripCamera.getCamera());
    return tripCameraObj;
  }

  static freezeList(tripCameras) {
    if (tripCameras == null) return [];
    var tripCameraObjList = [];
    for (var i = 0; i < tripCameras.length; i++) {
      tripCameraObjList.push(TripCamera.freeze(tripCameras[i]));
    }
    return tripCameraObjList;
  }

  constructor() {
    this.setID(null);
    this.setType(null);
    this.setTripID(null);
    this.setCameraID(null);
    this.setCamera(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setType(type) {
    this.type = type;
  }

  setTripID(tripID) {
    this.tripID = tripID;
  }

  setCameraID(cameraID) {
    this.cameraID = cameraID;
  }

  setCamera(camera) {
    this.camera = camera;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getTripID() {
    return this.tripID;
  }

  getCameraID() {
    return this.cameraID;
  }

  getCamera() {
    return this.camera;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */
}

import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapWidget } from './';

// Lib
import { TripPosition } from '../lib';
import { SET } from '../lib/TripSet';
import { HAUL } from '../lib/TripHaul';

// Constants
export const SET_COLOR = 'goldenrod';
export const HAUL_COLOR = 'green';
export const STEAM_COLOR = '#808080';
const ICON_SIZES = {
  SMALL: { width: 17.6, height: 32 },
  MEDIUM: { width: 22, height: 40 },
  LARGE: { width: 26.4, height: 48 }
};
const SET_ICON_URL = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_orange.png';
const HAUL_ICON_URL = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_green.png';
const STEAM_ICON_URL = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_black.png';
const SELECTED_ICON_URL = 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_white.png';

const ICON = (maps, url, size = ICON_SIZES.MEDIUM) => ({
  url: url,
  scaledSize: new maps.Size(size.width, size.height), // scaled size
  origin: new maps.Point(0,0), // origin
  anchor: new maps.Point(size.width / 2, size.height) // anchor
});

const NORMAL_LINE_THICKNESS = 5;
const HOVER_LINE_THICKNESS = 9;
const THIN_LINE_THICKNESS = 2;
const THIN_HOVER_LINE_THICKNESS = 5;

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};

class SteamMapWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      map: null,
      maps: null,
      steamMapObject: null, // { line, marker }
      setMapObjects: [], // { line, marker }
      haulMapObjects: [] // { line, marker }
    };
  }

  componentDidMount() {
    this.plotSteam();
    this.plotSets();
    this.plotHauls();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.steam !== this.props.steam) {
      this.plotSteam();
    }
    if (prevProps.sets !== this.props.sets) {
      this.plotSets();
    }
    if (prevProps.hauls !== this.props.hauls) {
      this.plotHauls();
    }

    if (prevProps.selectedObject !== this.props.selectedObject) {
      // Change appropriate line to dashed
      this.selectLine(prevProps.selectedObject);
    }
  }

  selectLine = (prevSelectedObject = null) => {
    const { maps } = this.state;
    let newMapObject = null;
    let oldMapObject = null;

    // Figure out which object is which
    if (this.props.selectedObject == null) newMapObject = this.state.steamMapObject;
    if (prevSelectedObject == null) oldMapObject = this.state.steamMapObject;
    if (this.props.selectedObject?.getObjectType() === SET) newMapObject = this.state.setMapObjects?.find(({ line }) => line.id === this.props.selectedObject.getID());
    if (prevSelectedObject?.getObjectType() === SET) oldMapObject = this.state.setMapObjects?.find(({ line }) => line.id === prevSelectedObject.getID());
    if (this.props.selectedObject?.getObjectType() === HAUL) newMapObject = this.state.haulMapObjects?.find(({ line }) => line.id === this.props.selectedObject.getID());
    if (prevSelectedObject?.getObjectType() === HAUL) oldMapObject = this.state.haulMapObjects?.find(({ line }) => line.id === prevSelectedObject.getID());

    // Return the previous line back to solid
    oldMapObject?.line?.setOptions({
      strokeOpacity: 1,
      icons: []
    });

    // Return the previous marker back to its appropriate color
    let newIcon = ICON(maps, STEAM_ICON_URL, ICON_SIZES.MEDIUM);
    if (prevSelectedObject?.getObjectType() === SET) newIcon = ICON(maps, SET_ICON_URL, prevSelectedObject?.getIsAnalyzed() && !prevSelectedObject?.getExcludeFromCalculations() ? ICON_SIZES.LARGE : ICON_SIZES.SMALL);
    else if (prevSelectedObject?.getObjectType() === HAUL) newIcon = ICON(maps, HAUL_ICON_URL, prevSelectedObject?.getIsAnalyzed() && !prevSelectedObject?.getExcludeFromCalculations() ? ICON_SIZES.LARGE : ICON_SIZES.SMALL);
    oldMapObject?.marker?.setOptions({
      icon: newIcon
    });

    // Set the newly-clicked-on line to dashed
    newMapObject?.line?.setOptions({
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: '0',
          repeat: '20px',
        }
      ]
    });

    // Set the newly-clicked-on marker to white
    newMapObject?.marker?.setOptions({
      icon: ICON(maps, SELECTED_ICON_URL, this.props.selectedObject?.getIsAnalyzed() && !this.props.selectedObject?.getExcludeFromCalculations() ? ICON_SIZES.LARGE : ICON_SIZES.SMALL)
    });

  }

  plotSets = () => {
    const { map, maps } = this.state;
    const { sets } = this.props;

    if (map && maps && sets?.length > 0) {
      sets.forEach(set => {
        const positions = set.listGoogleMapsPositions();
        const line = new maps.Polyline({
          id: set.getID(),
          path: positions,
          geodesic: true,
          strokeColor: SET_COLOR,
          strokeOpacity: 1,
          strokeWeight: NORMAL_LINE_THICKNESS,
          zIndex: 2,
          map
        });

        const marker = new maps.Marker({
          position: positions.length > 0 ? positions[0] : null,
          icon: ICON(maps, SET_ICON_URL, set.getIsAnalyzed() && !set.getExcludeFromCalculations() ? ICON_SIZES.LARGE : ICON_SIZES.SMALL),
          map
        });

        // Set onClick Events
        let onClick = () => {
          if (this.props.selectedObject?.getObjectType() === SET && this.props.selectedObject?.getID() === line.id) this.props.clearSelection();
          else this.props.onSetSelection(line.id);
        };
        line.addListener('click', () => {
          onClick();
        });
        marker.addListener('click', () => {
          onClick();
        });

        // Set onHover Events
        let onHover = () => {
          line.setOptions({
            strokeWeight: HOVER_LINE_THICKNESS
          });
        };
        line.addListener('mouseover', () => {
          onHover();
        });
        marker.addListener('mouseover', () => {
          onHover();
        });

        // Set onHoverEnd Events
        let onHoverEnd = () => {
          line.setOptions({
            strokeWeight: NORMAL_LINE_THICKNESS
          });
        };
        line.addListener('mouseout', () => {
          onHoverEnd();
        });
        marker.addListener('mouseout', () => {
          onHoverEnd();
        });

        this.state.setMapObjects.push({ line, marker });
        this.setState({ setMapObjects: this.state.setMapObjects });
      });
    }
    else if ((sets == null || sets.length === 0) && this.state.setMapObjects?.length > 0) {
      this.state.setMapObjects.forEach(({ line, marker }) => {
        line?.setMap(null);
        marker?.setMap(null);
      });
      this.setState({ setMapObjects: [] });
    }
  }

  plotHauls = () => {
    const { map, maps } = this.state;
    const { hauls } = this.props;

    if (map && maps && hauls?.length > 0) {
      hauls.forEach(haul => {
        const positions = haul.listGoogleMapsPositions();
        const line = new maps.Polyline({
          id: haul.getID(),
          path: positions,
          geodesic: true,
          strokeColor: HAUL_COLOR,
          strokeOpacity: 1,
          strokeWeight: haul.getIsAnalyzed() && !haul.getExcludeFromCalculations() ? NORMAL_LINE_THICKNESS : THIN_LINE_THICKNESS,
          zIndex: 3,
          map
        });

        const marker = new maps.Marker({
          position: positions.length > 0 ? positions[0] : null,
          icon: ICON(maps, HAUL_ICON_URL, haul.getIsAnalyzed() && !haul.getExcludeFromCalculations() ? ICON_SIZES.LARGE : ICON_SIZES.SMALL),
          map
        });

        // Set onClick Events
        let onClick = () => {
          if (this.props.selectedObject?.getObjectType() === HAUL && this.props.selectedObject?.getID() === line.id) this.props.clearSelection();
          else this.props.onHaulSelection(line.id);
        };
        line.addListener('click', () => {
          onClick();
        });
        marker.addListener('click', () => {
          onClick();
        });

        // Set onHover Events
        let onHover = () => {
          line.setOptions({
            strokeWeight: haul.getIsAnalyzed() && !haul.getExcludeFromCalculations() ? HOVER_LINE_THICKNESS : THIN_HOVER_LINE_THICKNESS
          });
        };
        line.addListener('mouseover', () => {
          onHover();
        });
        marker.addListener('mouseover', () => {
          onHover();
        });

        // Set onHoverEnd Events
        let onHoverEnd = () => {
          line.setOptions({
            strokeWeight: haul.getIsAnalyzed() && !haul.getExcludeFromCalculations() ? NORMAL_LINE_THICKNESS : THIN_LINE_THICKNESS
          });
        };
        line.addListener('mouseout', () => {
          onHoverEnd();
        });
        marker.addListener('mouseout', () => {
          onHoverEnd();
        });

        this.state.haulMapObjects.push({ line, marker });
        this.setState({ haulMapObjects: this.state.haulMapObjects });
      });
    }
    else if ((hauls == null || hauls.length === 0) && this.state.haulMapObjects?.length > 0) {
      this.state.haulMapObjects.forEach(({ line, marker }) => {
        line?.setMap(null);
        marker?.setMap(null);
      });
      this.setState({ haulMapObjects: [] });
    }
  }

  plotSteam = () => {
    const { map, maps } = this.state;
    const { steam } = this.props;
    if (map && maps && steam?.length > 0) {
      const positions = TripPosition.listGoogleMapsPositions(steam);
      const line = new maps.Polyline({
        path: positions,
        geodesic: true,
        strokeColor: '#808080',
        strokeOpacity: 1.0,
        strokeWeight: 1,
        zIndex: THIN_LINE_THICKNESS,
        map
      });

      const marker = new maps.Marker({
        position: positions.length > 0 ? positions[0] : null,
        icon: ICON(maps, STEAM_ICON_URL, ICON_SIZES.MEDIUM),
        map
      });

      // Set onClick Events
      let onClick = () => {
        this.props.clearSelection();
      };
      line.addListener('click', () => {
        onClick();
      });
      marker.addListener('click', () => {
        onClick();
      });

      // Set onHover Events
      let onHover = () => {
        line.setOptions({
          strokeWeight: THIN_HOVER_LINE_THICKNESS
        });
      };
      line.addListener('mouseover', () => {
        onHover();
      });
      marker.addListener('mouseover', () => {
        onHover();
      });

      // Set onHoverEnd Events
      let onHoverEnd = () => {
        line.setOptions({
          strokeWeight: THIN_LINE_THICKNESS
        });
      };
      line.addListener('mouseout', () => {
        onHoverEnd();
      });
      marker.addListener('mouseout', () => {
        onHoverEnd();
      });

      this.setState({ steamMapObject: { line, marker } }, () => this.selectLine());
    }
    else if ((steam == null || steam.length === 0) && this.state.steamMapObject != null) {
      this.state.steamMapObject.line?.setMap(null);
      this.state.steamMapObject.marker?.setMap(null);
    }
  }

  handleApiLoaded = ({ map, maps }) => {
    this.setState({ map, maps }, () => {
      this.plotSteam();
      this.plotSets();
      this.plotHauls();
    });
  }

  render() {
    return (
      <MapWidget
        height={this.props.height}
        width={this.props.width}
        style={this.props.style}
        defaultCenter={{ lng: -84.522, lat: 38.048 }}
        defaultZoom={4}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={this.handleApiLoaded}
      >
      </MapWidget>
    );
  }
}

SteamMapWidget.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  style: PropTypes.object,
  sets: PropTypes.array,
  hauls: PropTypes.array,
  steam: PropTypes.array,
  clearSelection: PropTypes.func.isRequired,
  onHaulSelection: PropTypes.func.isRequired,
  onSetSelection: PropTypes.func.isRequired,
  selectedObject: PropTypes.object
};

export default SteamMapWidget;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setError } from '../../../alerts';
import { softAuthenticateUser } from '../../../redux/actions/authActions';

// Components
import CustomLogin from './CustomLogin';

// Images
import ColorLogo from '../../../images/ColorLogo.png';

// Constants
import { NAVY } from '../../../constants';

class Login extends Component {
  render() {
    return (
      <CustomLogin
        {...this.props}
        color={NAVY}
        logo={ColorLogo}
        onLogin={this.props.onLogin}
        redirect={this.props.isHalfAuthenticated}
        createAccountLink='/auth/create-account'
        resetPasswordLink='/auth/forgot-password/request'
        redirectLink={'/auth/login/redirect'}
        hideCreateAccount
        setError={setError}
      />
    );
  }
}

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  isHalfAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isHalfAuthenticated: !state.auth.isLoading && state.auth.userID != null
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (email, password) => softAuthenticateUser(dispatch, email, password)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router';
import {
  CircularProgress,
  Container
} from '@mui/material';
import PropTypes from 'prop-types';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// API
import { confirmChangeEmail } from '../../../../api';

class ConfirmChangeEmail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  componentDidMount() {
    const token = this.props.match?.params?.token;
    confirmChangeEmail(token).then(() => {
      setSuccess('Successfully confirmed email change.');
      this.setState({ redirect: true });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to confirm email change.');
      this.setState({ redirect: true });
    });
  }

  render() {
    return this.state.redirect ? <Redirect to='/' /> : (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </Container>
    );
  }
}

ConfirmChangeEmail.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ConfirmChangeEmail);

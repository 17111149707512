import React from 'react';

// Navigation
import { useRouteMatch } from 'react-router-dom';
import { setError } from '../../../../../../alerts';
import { listCameraConfigurations } from '../../../../../../api/admin';

// Components
import { Container, Tab, Box, CircularProgress, Paper, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { BackToButton } from '../../../../../../components';
import ConfigFilePreview from '../../../../../../components/ConfigFilePreview';

const CameraConfigRecords = () => {
  const { params: { id: cameraId } } = useRouteMatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [configurations, setConfigurations] = React.useState([]);
  const [selectedConfiguration, setSelectedConfiguration] = React.useState('0');

  React.useEffect(() => {
    if (cameraId != null) {
      setIsLoading(true);
      listCameraConfigurations(cameraId).then(configurations => {
        const configurationFileEntries = [];
        for (const configuration of configurations) {
          if (configuration.getFileName() === 'default') {
            configurationFileEntries.unshift(configuration);
          }
          else {
            configurationFileEntries.push(configuration);
          }
        }
        setConfigurations(configurationFileEntries);
      }).catch(error => {
        setError(error ? error : 'Error: Unable to retrieve boat.');
      }).finally(() => setIsLoading(false));
    }
    else {
      setError('Error: Unable to retrieve camera configurations.');
    }
  }, [cameraId]);

  return (
    <Container style={{ marginTop: 30 }}>
      <BackToButton to={'/admin/cameras/' + cameraId} description={'Camera'} />

      <Typography variant='h1' style={{ margin: '1em', textAlign: 'center' }}>
        Configuration Files
      </Typography>
      <Paper sx={{ m: '2rem' }} elevation={5}>
        {configurations.length === 0 && (
          <Typography variant='h5' sx={{ textAlign: 'center', py: '2em', color: 'Highlight' }}>
            No configuration files found.
          </Typography>
        )}
        {configurations.length > 0 && (
          <TabContext value={selectedConfiguration}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(_,newSelection) => setSelectedConfiguration(newSelection)} aria-label="lab API tabs example">
                {configurations.map((configuration, index) => (
                  <Tab key={index} value={index.toString()} label={configuration.getFileName()} />
                ))}
              </TabList>
            </Box>
            {configurations.map((configuration, index) => (
              <TabPanel key={index} value={index.toString()}>
                <ConfigFilePreview fileName={configuration.getFileName()} fileContent={configuration.getFileContent()} />
              </TabPanel>
            ))}
          </TabContext>
        )}
      </Paper>
      {isLoading && <CircularProgress sx={{ position: 'absolute', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0 }} />}
    </Container>
  );
};

export default CameraConfigRecords;
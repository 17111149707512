import { Boat, CameraConfigurationFile, SatelliteMessage } from './';

export default class Camera {
  static thaw(cameraObj) {
    if (cameraObj == null) return null;
    const camera = new Camera();
    if (cameraObj.id != null) camera.setID(cameraObj.id);
    if (cameraObj.awsIdentifier != null) camera.setAwsIdentifier(cameraObj.awsIdentifier);
    if (cameraObj.name != null) camera.setName(cameraObj.name);
    if (cameraObj.isActive != null) camera.setIsActive(cameraObj.isActive == null ? null : (cameraObj.isActive ? true : false));
    if (cameraObj.boatId != null) camera.setBoatID(cameraObj.boatId);
    if (cameraObj.boat != null) camera.setBoat(Boat.thaw(cameraObj.boat));
    if (cameraObj.rockBlockImei != null) camera.setRockBlockImei(cameraObj.rockBlockImei);
    if (cameraObj.reportingInterval != null) camera.setReportingInterval(cameraObj.reportingInterval);
    if (cameraObj.mailboxInterval != null) camera.setMailboxInterval(cameraObj.mailboxInterval);
    if (cameraObj.autoReset != null) camera.setAutoReset(cameraObj.autoReset);
    if (cameraObj.configurationFiles != null) camera.setConfigurationFiles(CameraConfigurationFile.thawList(cameraObj.configurationFiles));
    if (cameraObj.satelliteMessages != null) camera.setSatelliteMessages(SatelliteMessage.thawList(cameraObj.satelliteMessages));
    return camera;
  }

  static thawList(cameraObjList) {
    if (cameraObjList == null) return [];
    var cameras = [];
    for (var i = 0; i < cameraObjList.length; i++) {
      cameras.push(Camera.thaw(cameraObjList[i]));
    }
    return cameras;
  }

  static freeze(camera) {
    var cameraObj = {};
    if (camera.getID() != null) cameraObj.id = camera.getID();
    if (camera.getAwsIdentifier() != null) cameraObj.awsIdentifier = camera.getAwsIdentifier();
    if (camera.getName() != null) cameraObj.name = camera.getName();
    if (camera.getIsActive() != null) cameraObj.isActive = camera.getIsActive() == null ? null : (camera.getIsActive() ? 1 : 0);
    if (camera.getBoatID() != null) cameraObj.boatId = camera.getBoatID();
    if (camera.getBoat() != null) cameraObj.boat = Boat.freeze(camera.getBoat());
    if (camera.getRockBlockImei() != null) cameraObj.rockBlockImei = camera.getRockBlockImei();
    if (camera.getReportingInterval() != null) cameraObj.reportingInterval = camera.getReportingInterval();
    if (camera.getMailboxInterval() != null) cameraObj.mailboxInterval = camera.getMailboxInterval();
    if (camera.getAutoReset() != null) cameraObj.autoReset = camera.getAutoReset();
    if (camera.getConfigurationFiles() != null) cameraObj.configurationFiles = camera.getConfigurationFiles();
    if (camera.getSatelliteMessages() != null) cameraObj.satelliteMessages = SatelliteMessage.freezeList(camera.getSatelliteMessages());
    return cameraObj;
  }

  static freezeList(cameras) {
    if (cameras == null) return [];
    var cameraObjList = [];
    for (var i = 0; i < cameras.length; i++) {
      cameraObjList.push(Camera.freeze(cameras[i]));
    }
    return cameraObjList;
  }

  constructor() {
    this.setID(null);
    this.setAwsIdentifier(null);
    this.setName(null);
    this.setIsActive(null);
    this.setBoatID(null);
    this.setBoat(null);
    this.setRockBlockImei(null);
    this.setReportingInterval(null);
    this.setMailboxInterval(null);
    this.setAutoReset(null);
    this.setConfigurationFiles(null);
    this.setSatelliteMessages(null);
    this.setDisplayColor(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setAwsIdentifier(awsIdentifier) {
    this.awsIdentifier = awsIdentifier;
  }

  setName(name) {
    this.name = name;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setBoatID(boatID) {
    this.boatID = boatID;
  }

  setBoat(boat) {
    this.boat = boat;
  }

  setRockBlockImei(rockBlockImei) {
    this.rockBlockImei = rockBlockImei;
  }

  setReportingInterval(reportingInterval) {
    this.reportingInterval = reportingInterval;
  }

  setMailboxInterval(mailboxInterval) {
    this.mailboxInterval = mailboxInterval;
  }

  setAutoReset(autoReset) {
    this.autoReset = autoReset;
  }

  setConfigurationFiles(configurationFiles) {
    this.configurationFiles = configurationFiles;
  }

  setSatelliteMessages(satelliteMessages) {
    this.satelliteMessages = satelliteMessages;
  }

  setDisplayColor(displayColor) {
    this.displayColor = displayColor;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getAwsIdentifier() {
    return this.awsIdentifier;
  }

  getName() {
    return this.name;
  }

  getIsActive() {
    return this.isActive;
  }

  getBoatID() {
    return this.boatID;
  }

  getBoat() {
    return this.boat;
  }

  getRockBlockImei() {
    return this.rockBlockImei;
  }

  getReportingInterval() {
    return this.reportingInterval;
  }

  getMailboxInterval() {
    return this.mailboxInterval;
  }

  getAutoReset() {
    return this.autoReset;
  }
  
  getConfigurationFiles() {
    return this.configurationFiles;
  }

  getSatelliteMessages() {
    return this.satelliteMessages;
  }

  getDisplayColor() {
    return this.displayColor;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Button, CircularProgress, Container, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../alerts';

// API
import { getUser, resetUser2FA, toggleUserActivation, updateUser, listBusinesses } from '../../../../api/admin';

// Components
import { BackToButton, LineItem, Table } from '../../../../components';
import { USER_TYPES } from '../../../../constants';

// Icons
import VisibilityIcon from '@mui/icons-material/Edit';

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      isLoadingUser: true,
      isLoading: false,
      inEditMode: false,
      firstName: '',
      lastName: '',
      role: 'User',
      phoneNumber: '',
      email: '',
      associatedWithBusinessID: null,
      businesses: []
    };
  }

  componentDidMount() {
    listBusinesses().then(businesses => {
      this.setState({ businesses }, () => {
        this.refreshUser();
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve businesses.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  refreshUser() {
    this.setState({ isLoadingUser: true });
    const userID = this.props.match?.params?.id;
    getUser(userID).then(user => {
      this.setState({
        user,
        firstName: user.getFirstName(),
        lastName: user.getLastName(),
        role: user.getRole()?.getName() ?? '',
        phoneNumber: user.getPhoneNumber(),
        email: user.getEmail(),
        isLoadingUser: false,
        associatedWithBusinessID: user.getAssociatedWithBusinessID() ?? '',
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve user.');
      this.setState({ isLoadingUser: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateProfile = (e) => {
    e.preventDefault();

    const { firstName, lastName, role, phoneNumber, email, associatedWithBusinessID } = this.state;

    if (phoneNumber?.length <= 3) {
      setError('Error: Invalid phone number');
      return;
    }

    let newAssociatedWithBusinessID = associatedWithBusinessID === '' ? null : associatedWithBusinessID;

    this.setState({ isLoading: true });
    updateUser(this.state.user?.getID(), { firstName, lastName, role, phoneNumber, email, associatedWithBusinessId: newAssociatedWithBusinessID }).then(() => {
      let successMessage = 'Successfully updated user.';
      if (email !== this.state.user?.getEmail()) successMessage += ' In order for the new email to take effect, the user must confirm their new email.';
      setSuccess(successMessage);

      this.setState({ isLoading: false });
      this.refreshUser();
      this.closeForms();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to update user.');
      this.setState({ isLoading: false });
    });
  }

  closeForms = () => {
    this.setState({
      inEditMode: false,
      firstName: this.state.user.getFirstName(),
      lastName: this.state.user.getLastName(),
      role: this.state.user.getRole()?.getName(),
      phoneNumber: this.state.user.getPhoneNumber(),
      email: this.state.user.getEmail()
    });
    clearErrors();
  }

  toggleUserActivation = (isActive) => {
    this.setState({ isLoading: true });
    toggleUserActivation(this.state.user?.getID(), isActive).then(() => {
      setSuccess('Successfully ' + (isActive ? '' : 'de') + 'activated user.');

      this.setState({ isLoading: false });
      this.refreshUser();
      this.closeForms();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to ' + (isActive ? '' : 'de') + 'activate user.');
      this.setState({ isLoading: false });
    });
  }

  reset2FA = () => {
    this.setState({ isLoading: true });
    resetUser2FA(this.state.user?.getID()).then(() => {
      setSuccess('Successfully reset user\'s 2FA.');

      this.setState({ isLoading: false });
      this.refreshUser();
      this.closeForms();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to reset user\'s 2FA.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <Container style={{ marginTop: '20px' }}>
        <BackToButton to='/admin/users' description='Users' />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h1'>{this.state.inEditMode && 'Edit'} {this.state.user?.getName()}</Typography>
            {this.state.isLoadingUser && <CircularProgress style={{ marginLeft: '20px' }} />}
          </div>
          <Typography variant='subtitle' style={{ color: this.state.user?.getIsActive() ? 'green' : 'red', fontStyle: 'italic' }}>{this.state.user?.getIsActive() ? 'Active' : 'Inactive'}</Typography>
        </div>

        {/* View Mode */}
        {!this.state.inEditMode &&
        <>
          <LineItem value={this.state.user?.getName()} description='Name' />
          <LineItem value={this.state.user?.getRole()?.getName()} description='Role' />
          <LineItem value={this.state.user?.getPhoneNumber()} description='Phone Number' />
          <LineItem value={this.state.user?.getEmail()} description='Email' />
          {this.state.user?.getBusiness() != null &&
          <LineItem value={this.state.user?.getBusiness()?.getName()} description='Business' />}
          {this.state.user?.getAssociatedWithBusiness() != null &&
          <LineItem value={this.state.user?.getAssociatedWithBusiness()?.getName()} description='Associated With Business' />}

          {/* Buttons */}
          <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
            <Button onClick={() => this.setState({ inEditMode: true })} style={{ marginBottom: 10 }} disabled={this.state.isLoadingUser || !this.state.user}>Edit User</Button>
            <Tooltip title={this.state.user?.getIsActive() ? 'Warning: This will prevent the user from being able to log in.' : 'Warning: This will allow the user to log in.'}>
              <Button variant='outlined' onClick={() => this.toggleUserActivation(!this.state.user?.getIsActive())} style={{ marginBottom: 10 }} disabled={this.state.isLoadingUser || !this.state.user}>{this.state.user?.getIsActive() ? 'Deactivate' : 'Activate'} User</Button>
            </Tooltip>
            <Tooltip title={'Warning: This will allow the user to reset their 2FA, which will temporarily be a security vulnerability.'}>
              <Button variant='outlined' onClick={this.reset2FA} style={{ marginBottom: 10 }} disabled={this.state.isLoadingUser || !this.state.user}>Reset 2FA</Button>
            </Tooltip>
          </div>

          {this.state.user?.getUserType() === USER_TYPES.GUEST &&
          <Table
            title='Shared Trips'
            data={this.state.user?.getGuestPermissions() ?? []}
            columns={[
              {
                title: 'Business',
                render: rowData => rowData.getTrip()?.getBoat()?.getBusiness()?.getName()
              },
              {
                title: 'Trip ID',
                field: 'tripID'
              },
              {
                title: 'Boat Name',
                render: rowData => rowData.getTrip()?.getBoat()?.getName()
              },
              {
                title: 'Departure Date',
                render: rowData => rowData.getTrip()?.getStartTimestamp()?.toLocaleString()
              },
              {
                title: 'Permissions',
                render: rowData => rowData.getPermissionListString()
              },
              {
                title: 'Edit',
                render: rowData => <Link to={`/admin/guest-trip-permission/${rowData.getID()}`}><VisibilityIcon /></Link>
              }
            ]}
            options={{ pageSize: 20 }}
          />}
        </>}

        {/* Edit Mode */}
        {this.state.inEditMode &&
        <>
          <form onSubmit={this.updateProfile}>
            <Typography variant='h2' style={{ marginBottom: '1em' }}>Profile</Typography>

            {/* Name Input */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
              <TextField
                required
                error={this.state.formError && this.state.firstName === ''}
                name='firstName'
                label='First Name'
                style={{ width: '49%' }}
                value={this.state.firstName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
              />

              <TextField
                required
                error={this.state.formError && this.state.lastName === ''}
                name='lastName'
                label='Last Name'
                style={{ width: '49%' }}
                value={this.state.lastName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
              />
            </div>

            {/* Role */}
            {this.state.user?.getUserType() !== USER_TYPES.GUEST &&
            <TextField
              select
              name="role"
              label="Role"
              value={this.state.role}
              style={{ width: '100%', marginBottom: '10px' }}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              error={this.state.formError && this.state.role === ''}>
              <MenuItem value='User'>
                User
              </MenuItem>
              <MenuItem value='Admin'>
                Admin
              </MenuItem>
              <MenuItem value='Guest'>
                Guest
              </MenuItem>
            </TextField>}

            {/* Associated With Business */}
            {this.state.role === 'Guest' &&
            <TextField
              select
              name="associatedWithBusinessID"
              label="Associated With Business"
              value={this.state.associatedWithBusinessID}
              style={{ width: '100%', marginBottom: '10px' }}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              error={this.state.formError && this.state.associatedWithBusinessID === ''}>
              <MenuItem value={''}>
                None
              </MenuItem>
              {this.state.businesses.map(business => (
                <MenuItem key={business.getID()} value={business.getID()}>
                  {business.getName()}
                </MenuItem>
              ))}
            </TextField>}

            {/* Phone Number */}
            <ReactPhoneInput
              country='us'
              preferredCountries={['us', 'cn']}
              value={this.state.phoneNumber}
              onChange={(phoneNumber) => this.setState({ phoneNumber })}
              component={TextField}
              inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.state.isLoading, error: this.state.formError && this.state.phoneNumber?.length <= 3 }}
              containerStyle={{ width: '100%', marginBottom: '10px' }}
            />

            {/* Email Input */}
            <TextField
              name='email'
              label='Email'
              type='email'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.email}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
              <Button style={{ width: '49%', backgroundColor: 'grey' }} onClick={this.closeForms} disabled={this.state.isLoading}>Cancel</Button>
              <Button style={{ width: '49%' }} type='submit' disabled={this.state.isLoading}>
                Save
                {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
              </Button>
            </div>
          </form>
        </>}
      </Container>
    );
  }
}

User.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(User);

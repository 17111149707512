import React, { Component } from 'react';
import { AppBar } from '@mui/material';

import Icon from '../images/Icon.png';

// Constants
import { LIGHT_BLUE } from '../constants';

class Footer extends Component {
  render() {
    return (
      <AppBar position='absolute' style={{ position: 'absolute', height: '100px', top: 'auto', bottom: 0, backgroundColor: LIGHT_BLUE, marginTop: '20px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Icon} alt='FlyWire Camera Icon' style={{ height: 100 }} />
      </AppBar>
    );
  }
}

export default Footer;

import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';

// Component
import { BusinessForm } from '../../../../components';

class CreateBusiness extends Component {
  render() {
    return (
      <Container style={{ marginTop: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Create New Business</Typography>

        <BusinessForm />
      </Container>
    );
  }
}

export default CreateBusiness;

import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, IconButton, Typography } from '@mui/material';
import Table from '../../../../../components/Table';

// Redux
import { clearErrors, setError, setSuccess } from '../../../../../alerts';

// API
import { deleteGuestTripPermission, listGuestTripPermissionsForTrip } from '../../../../../api/admin';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getTrip } from '../../../../../api/admin';
import { LineItem } from '@lexcelon/react-util';
import { BackToButton } from '../../../../../components';

class ShareTrip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trip: null,
      guestTripPermissions: [],
      isDeletingGuestTripPermissionId: null
    };
  }

  componentDidMount() {
    getTrip(this.props.match?.params?.id).then(trip => {
      this.setState({ trip });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve trip.');
    });
  
    this.refreshList();
  }

  refreshList() {
    listGuestTripPermissionsForTrip(this.props.match?.params?.id).then(guestTripPermissions => {
      this.setState({ guestTripPermissions });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve guest trip permissions.');
    });
  }

  deleteGuestTripPermission(guestTripPermission) {
    deleteGuestTripPermission(guestTripPermission.getID()).then(() => {
      setSuccess('Successfully deleted guest trip permission.');
      this.refreshList();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to delete guest trip permission.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  render() {
    return (
      <div style={{ margin: '20px' }}>
        <BackToButton to={`/admin/businesses/${this.state.trip?.getBoat()?.getBusinessID()}/shared-trips`} description={`${this.state.trip?.getBoat()?.getBusiness()?.getName()}'s Guest Permissions`} />
        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Shared Trip Data</Typography>

        <LineItem
          description='Trip ID'
          value={this.state.trip?.getID()}
        />

        <LineItem
          description='Boat Name'
          value={this.state.trip?.getBoat()?.getName()}
        />

        <LineItem
          description='Departure Date'
          value={this.state.trip?.getStartTimestamp()?.toLocaleString()}
        />

        <Table
          title={
            <Button variant='contained' component={Link} style={{ width: 200 }} to={`/admin/trips/${this.props.match?.params?.id}/share`}>Share Trip</Button>
          }
          data={this.state.guestTripPermissions}
          columns={[
            {
              title: 'User',
              render: rowData => rowData.getGuest() != null ? (
                `${rowData.getGuest().getName()}${rowData.getGuest()?.getAssociatedWithBusiness() != null ? ` (${rowData.getGuest().getAssociatedWithBusiness().getName()})` : ''}`
              ) : (
                `${rowData.getGuestInvitation()?.getEmail()}${rowData.getGuestInvitation()?.getAssociatedWithBusiness() != null ? ` (${rowData.getGuestInvitation().getAssociatedWithBusiness().getName()})` : ''}`
              )
            },
            {
              title: 'Fishing Performance',
              render: rowData => rowData.getFishingPerformance() ? 'Yes' : 'No'
            },
            {
              title: 'Compliance Reports',
              render: rowData => rowData.getComplianceReports() ? 'Yes' : 'No'
            },
            {
              title: 'Videos',
              render: rowData => rowData.getVideos() ? 'Yes' : 'No'
            },
            {
              title: 'Images',
              render: rowData => rowData.getImages() ? 'Yes' : 'No'
            },
            {
              title: 'Actions',
              render: rowData => (
                <>
                  <IconButton component={Link} to={`/admin/guest-trip-permission/${rowData.getID()}`}><EditIcon /></IconButton>
                  <IconButton onClick={() => this.deleteGuestTripPermission(rowData)}><DeleteIcon /></IconButton>
                </>
              )
            }
          ]}
          options={{ pageSize: 20 }}
        />
      </div>
    );
  }
}

ShareTrip.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ShareTrip);

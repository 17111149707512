import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Table from '../../components/Table';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// Alerts
import { setError } from '../../alerts';

// API
import { getLatestSatelliteLogMessages, getSatelliteOutgoingCommands, listBusinesses } from '../../api/admin';
import { DATE_TIME_ZONE_FORMAT } from '../../constants';

// Constants
const BUSINESS_COLUMNS = [
  {
    title: 'View',
    render: rowData => (
      <Link to={'/admin/businesses/' + rowData?.id}><VisibilityIcon /></Link>
    )
  },
  { title: 'Name', field: 'name' },
  { title: 'Contact Name', field: 'contactName' },
  { title: 'Contact Email', field: 'contactEmail' },
  {
    title: 'Is Active',
    field: 'isActive',
    customFilterAndSearch: (term, rowData) => (rowData.isActive && term.toLowerCase() === 'active') || (!rowData.isActive && term.toLowerCase() === 'inactive')
  }
];

const SATELLITE_ERROR_COLUMNS = [
  {
    title: 'Received At',
    render: rowData => (
      rowData?.getReceivedAt()?.toLocaleString(DATE_TIME_ZONE_FORMAT)
    )
  },
  { title: 'Message', field: 'message' },
  {
    title: 'Camera',
    render: rowData => (
      rowData?.getCamera()?.getName()
    ),
    customFilterAndSearch: (term, rowData) => {
      let cameraName = rowData?.getCamera()?.getName()?.toLowerCase();

      return cameraName?.includes(term?.toLowerCase());
    }
  },
  {
    title: 'Boat',
    render: rowData => (
      rowData?.getCamera()?.getBoat()?.getName()
    ),
    customFilterAndSearch: (term, rowData) => {
      let boatName = rowData?.getCamera()?.getBoat()?.getName()?.toLowerCase();

      return boatName?.includes(term?.toLowerCase());
    }
  },
  {
    title: 'Business',
    render: rowData => (
      rowData?.getCamera()?.getBoat()?.getBusiness()?.getName()
    ),
    customFilterAndSearch: (term, rowData) => {
      let businessName = rowData?.getCamera()?.getBoat()?.getBusiness()?.getName()?.toLowerCase();

      return businessName?.includes(term?.toLowerCase());
    }
  }
];

const OUTGOING_SATELLITE_COMMAND_COLUMNS = [
  {
    title: 'Sent At',
    render: rowData => (
      rowData?.getTimestamp()?.toLocaleString(DATE_TIME_ZONE_FORMAT)
    )
  },
  { title: 'Data', field: 'data' },
  { title: 'Response', field: 'response' },
  {
    title: 'Camera',
    render: rowData => (
      rowData?.getCamera()?.getName()
    ),
    customFilterAndSearch: (term, rowData) => {
      let cameraName = rowData?.getCamera()?.getName()?.toLowerCase();

      return cameraName?.includes(term?.toLowerCase());
    }
  },
  {
    title: 'Boat',
    render: rowData => (
      rowData?.getCamera()?.getBoat()?.getName()
    ),
    customFilterAndSearch: (term, rowData) => {
      let boatName = rowData?.getCamera()?.getBoat()?.getName()?.toLowerCase();

      return boatName?.includes(term?.toLowerCase());
    }
  },
  {
    title: 'Business',
    render: rowData => (
      rowData?.getCamera()?.getBoat()?.getBusiness()?.getName()
    ),
    customFilterAndSearch: (term, rowData) => {
      let businessName = rowData?.getCamera()?.getBoat()?.getBusiness()?.getName()?.toLowerCase();

      return businessName?.includes(term?.toLowerCase());
    }
  }
];
class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businesses: [],
      satelliteErrors: [],
      satelliteOutgoingCommands: []
    };
  }

  componentDidMount() {
    listBusinesses().then(businesses => {
      this.setState({ businesses });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve businesses.');
    });

    getLatestSatelliteLogMessages().then(satelliteErrors => {
      this.setState({ satelliteErrors });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve satellite logs.');
    });

    getSatelliteOutgoingCommands().then(satelliteOutgoingCommands => {
      this.setState({ satelliteOutgoingCommands });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve satellite outgoing commands.');
    });
  }

  render() {
    return (
      <div style={{ margin: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em' }}>Admin</Typography>

        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Businesses</Typography>
        <Table
          title={<Button component={Link} to='/admin/businesses/create' style={{ width: 200 }}>Create New</Button>}
          data={this.state.businesses}
          columns={BUSINESS_COLUMNS}
          options={{ pageSize: 20 }}
        />

        <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1.5em' }}>Recent Satellite Log Messages</Typography>
        <Table
          title={''}
          data={this.state.satelliteErrors}
          columns={SATELLITE_ERROR_COLUMNS}
          options={{ pageSize: 10 }}
        />
        <Typography variant='caption' style={{ marginTop: '0.3em' }}>Limited to 100 entries.</Typography>

        <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1.5em' }}>Recent Outgoing Satellite Commands</Typography>
        <Table
          title={''}
          data={this.state.satelliteOutgoingCommands}
          columns={OUTGOING_SATELLITE_COMMAND_COLUMNS}
          options={{ pageSize: 10 }}
        />
        <Typography variant='caption' style={{ marginTop: '0.3em' }}>Limited to 50 entries.</Typography>
      </div>
    );
  }
}

export default Admin;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { LineItem } from '@lexcelon/react-util';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../../alerts';

// API
import { getGuestTripPermission, updateGuestTripPermission } from '../../../../api/admin';

class GuestTripPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guestTripPermission: null,
      isLoading: false,
      fishingPerformance: false,
      complianceReports: false,
      videos: false,
      images: false,
      redirectToTripPermissions: false
    };
  }

  componentDidMount() {
    getGuestTripPermission(this.props.match?.params?.id).then(guestTripPermission => {
      this.setState({ guestTripPermission, fishingPerformance: guestTripPermission.getFishingPerformance(), complianceReports: guestTripPermission.getComplianceReports(), videos: guestTripPermission.getVideos(), images: guestTripPermission.getImages() });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve existing permission.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { guestTripPermission, fishingPerformance, complianceReports, videos, images } = this.state;

    if (!fishingPerformance && !complianceReports && !videos && !images) {
      setError('You must select at least one permission.');
      return;
    }
    
    this.setState({ isLoading: true });
    updateGuestTripPermission({ guestTripPermissionId: guestTripPermission.getID(), fishingPerformance, complianceReports, videos, images }).then(() => {
      setSuccess('Successfully updated guest permission.');
      this.setState({ isLoading: false, redirectToTripPermissions: true });
    }).catch(error => {
      setError(error ?? 'Error: Unable to update guest permission.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.redirectToTripPermissions ? <Redirect to={`/admin/trips/${this.state.guestTripPermission?.getTripID()}/shared`} /> : (
      <Container style={{ marginBottom: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <Typography variant='h1'>Share Trip</Typography>
          {this.state.isLoadingBusinesses && <CircularProgress style={{ marginLeft: '20px' }} />}
        </div>

        <LineItem
          description='Trip ID'
          value={this.state.guestTripPermission?.getTrip()?.getID()}
        />

        <LineItem
          description='Boat Name'
          value={this.state.guestTripPermission?.getTrip()?.getBoat()?.getName()}
        />

        <LineItem
          description='Departure Date'
          value={this.state.guestTripPermission?.getTrip()?.getStartTimestamp()?.toLocaleString()}
        />

        <LineItem
          description='Guest User'
          value={this.state.guestTripPermission?.getGuest()?.getName() ?? this.state.guestTripPermission?.getGuestInvitation()?.getEmail()}
        />

        <form onSubmit={this.onSubmit}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.fishingPerformance}
                onChange={(val) => this.setState({ fishingPerformance: val.target.checked })}
              />
            }
            label='Fishing Performance'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.complianceReports}
                onChange={(val) => this.setState({ complianceReports: val.target.checked })}
              />
            }
            label='Compliance Reports'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.videos}
                onChange={(val) => this.setState({ videos: val.target.checked })}
              />
            }
            label='Videos'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.images}
                onChange={(val) => this.setState({ images: val.target.checked })}
              />
            }
            label='Images'
          />

          {/* Buttons */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 50 }}>
            <Button style={{ marginRight: 10, backgroundColor: 'grey' }} onClick={() => this.setState({ redirectToTripPermissions: true })}>
              Cancel
            </Button>
            <Button type='submit' disabled={this.state.isLoading}>
              Update Permission
              {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

GuestTripPermission.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GuestTripPermission);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, Tooltip, Typography } from '@mui/material';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// API
import { clearTrip, getTrip, toggleTripActivation, toggleTripVisibleToUser } from '../../../../api/admin';

// Components
import { BackToButton, ConfirmationModal, LineItem, TripForm } from '../../../../components';

// Constants
import { DATE_TIME_ZONE_FORMAT } from '../../../../constants';

class Trip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trip: null,
      isLoading: false,
      inEditMode: false
    };
  }

  componentDidMount() {
    this.loadTrip();
  }

  loadTrip() {
    this.setState({ isLoading: true });
    const tripID = this.props.match?.params?.id;
    getTrip(tripID).then(trip => {
      this.setState({ trip, isLoading: false });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to load trip');
      this.setState({ isLoading: false });
    });
  }

  clearTrip() {
    this.setState({ clearTripIsLoading: true });
    const cameraID = this.props.match?.params?.id;

    clearTrip(cameraID).then(() => {
      setSuccess('Successfully cleared trip.');
      this.setState({ confirmClearTrip: false, clearTripIsLoading: false });
      this.loadTrip();
    }).catch(error => {
      this.setState({ confirmClearTrip: false, clearTripIsLoading: false });
      setError(error ? error : 'Error: Unable to clear trip.');
    });
  }

  toggleTripActivation = () => {
    const isActive = !this.state.trip?.getIsActive();
    this.setState({ isLoading: true });
    toggleTripActivation(this.state.trip?.getID(), isActive).then(() => {
      setSuccess('Successfully ' + (isActive ? '' : 'de') + 'activated trip.');

      this.setState({ isLoading: false });
      this.loadTrip();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to ' + (isActive ? '' : 'de') + 'activate trip.');
      this.setState({ isLoading: false });
    });
  }

  toggleTripVisibleToUser = () => {
    const visibleToUser = !this.state.trip?.getVisibleToUser();
    this.setState({ isLoading: true });
    toggleTripVisibleToUser(this.state.trip?.getID(), visibleToUser).then(() => {
      setSuccess('Successfully ' + (visibleToUser ? 'made visible to' : 'hid from') + ' user.');

      this.setState({ isLoading: false });
      this.loadTrip();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to ' + (visibleToUser ? 'make visible to' : 'hide from') + ' user.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <>
        <ConfirmationModal
          title='Clear Trip Data'
          body='Are you sure you want to clear this trip?'
          open={this.state.confirmClearTrip}
          onClose={() => this.setState({ confirmClearTrip: false })}
          onConfirm={() => this.clearTrip()}
          isLoading={this.state.clearTripIsLoading}
        />
        <Container style={{ marginTop: 30 }}>
          <BackToButton to={'/admin/boats/' + this.state.trip?.getBoatID()} description={this.state.trip?.getBoat()?.getName()} />

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h1'>Trip</Typography>
              {this.state.isLoading && <CircularProgress style={{ marginLeft: '20px' }} />}
            </div>
            <Typography variant='subtitle' style={{ color: this.state.trip?.getIsActive() ? 'green' : 'red', fontStyle: 'italic' }}>{this.state.trip?.getIsActive() ? 'Active' : 'Inactive'}</Typography>
            <Typography variant='subtitle' style={{ color: this.state.trip?.getVisibleToUser() ? 'green' : 'red', fontStyle: 'italic' }}>{this.state.trip?.getVisibleToUser() ? 'Visible to User' : 'NOT Visible to User'}</Typography>
          </div>

          {/* View Mode */}
          {this.state.inEditMode &&
        <>
          <TripForm boatID={this.state.trip?.getBoatID()} trip={this.state.trip} onClose={() => this.setState({ inEditMode: false })} />
        </>}

          {/* Trip Info */}
          {this.state.trip != null && !this.state.inEditMode &&
        <div>
          <LineItem
            description='Business'
            value={this.state.trip.getBoat()?.getBusiness()?.getName()}
          />
          <LineItem
            description='Boat'
            value={this.state.trip.getBoat()?.getName()}
          />
          <LineItem
            description='Trip Start Timestamp'
            value={this.state.trip.getStartTimestamp()?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
          />
          <LineItem
            description='Trip Stop Timestamp'
            value={this.state.trip.getStopTimestamp()?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
          />
          <LineItem
            description='Units Displayed'
            value={this.state.trip.getConvertToKg() ? 'kg' : 'lbs'}
          />
          <LineItem
            description='Business ID'
            value={this.state.trip.getBoat()?.getBusiness()?.getID()}
          />
          <LineItem
            description='Trip ID'
            value={this.state.trip.getID()}
          />

          <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trip Cameras</Typography>
          {this.state.trip.getTripCameras()?.map((tripCamera, index) => (
            <Typography key={index} variant='body1' style={{ marginBottom: '0.5em' }}>{tripCamera.getCamera()?.getName()}: {tripCamera.getType() ?? 'OFF'}</Typography>
          ))}

          {this.state.trip.getProjects()?.length > 0 &&
          <>
            <Typography variant='h2' style={{ marginTop: '1em' }}>Trip Projects</Typography>
            {this.state.trip.getProjects().map((tripProject, index) => (
              <div key={index} style={{ backgroundColor: 'lightgrey', padding: 10, margin: 5, borderRadius: 10, width: '100%' }}>
                <LineItem
                  description='Project ID'
                  value={tripProject.getProjectID()}
                />

                <LineItem
                  description='S3 Path'
                  value={tripProject.getS3Path()}
                />
              </div>
            ))}
          </>}

          {/* Buttons */}
          <div style={{ display: 'flex', flexDirection: 'column', width: 200, marginTop: 40 }}>
            <Button
              onClick={() => this.setState({ inEditMode: true })}
              style={{ marginBottom: 10 }}
              disabled={this.state.isLoading}>
              Edit Trip
            </Button>
            <Tooltip title={this.state.trip?.getIsActive() ? 'Warning: This will soft delete the trip.' : 'Warning: This will allow users to view this trip.'}>
              <Button
                variant='outlined'
                onClick={this.toggleTripActivation}
                style={{ marginBottom: 10 }}
                disabled={this.state.isLoading || !this.state.trip}>
                {this.state.trip?.getIsActive() ? 'Deactivate' : 'Activate'} Trip
              </Button>
            </Tooltip>
            <Tooltip title={this.state.trip?.getVisibleToUser() ? 'Warning: This will prevent users from viewing this trip.' : 'Warning: This will allow users to view this trip.'}>
              <Button
                variant='outlined'
                onClick={this.toggleTripVisibleToUser}
                style={{ marginBottom: 10 }}
                disabled={this.state.isLoading || !this.state.trip}>
                {this.state.trip?.getVisibleToUser() ? 'Hide Trip From User' : 'Make Trip Visible to User'}
              </Button>
            </Tooltip>
            {this.state.trip?.isProcessed() &&
            <Button
              variant='outlined'
              onClick={() => this.setState({ confirmClearTrip: true })}
              style={{ marginBottom: 10 }}
              disabled={this.state.isLoading}>
            Clear Trip Data
            </Button>}
          </div>
        </div>}
        </Container>
      </>
    );
  }
}

Trip.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Trip);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Button, CircularProgress, Container, Typography, TextField } from '@mui/material';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// Images
import ColorLogo from '../../../../images/ColorLogo.png';

// API
import { confirmForgotPassword } from '../../../../api';

// Constants
import { NAVY } from '../../../../constants';

class ConfirmForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      redirect: false,
      isLoading: false
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      setError('Error: Passwords must match.');
      return;
    }

    this.setState({ isLoading: true });
    const token = this.props.match?.params?.token;
    confirmForgotPassword(token, password).then(() => {
      setSuccess('Successfully changed your password.');
      this.setState({ isLoading: false, redirect: true });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to change your password.');
      this.setState({ isLoading: false });
    });
  };

  render() {
    return this.state.redirect ? <Redirect to='/auth/login' /> : (
      <Container>
        <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
          {/* ----- LOGO & TITLE ----- */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2em', marginBottom: '1em' }}>
            <img
              width={250}
              src={ColorLogo}
              alt={'Logo'}
              title={'Logo'}
              style={{ objectFit: 'contain' }}
            />
          </div>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em' }}>Forgot Password</Typography>

          {/* Password Inputs */}
          <TextField
            required
            name='password'
            label='Password'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.password}
            onChange={this.onChange}
            variant='filled'
            type='password'
            disabled={this.state.isLoading}
          />

          <TextField
            required
            name='confirmPassword'
            label='Confirm Password'
            style={{ width: '100%', marginBottom: '20px' }}
            value={this.state.confirmPassword}
            onChange={this.onChange}
            variant='filled'
            type='password'
            disabled={this.state.isLoading}
          />

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
            <Button type='submit' variant='contained' disabled={this.state.isLoading} style={{ width: '50%', backgroundColor: NAVY, color: 'white' }}>
              Submit
              {this.state.isLoading && <CircularProgress size={25} style={{ marginLeft: '1em' }} />}
            </Button>

            <Typography variant='button' style={{ marginTop: '10px' }}>Know your password?{' '}
              <Link to='/auth/login' style={{ color: NAVY }}>
                Login now
              </Link>.
            </Typography>
          </div>
        </form>
      </Container>
    );
  }
}

ConfirmForgotPassword.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ConfirmForgotPassword);

import { Camera, CameraInput } from './';
import { DateTime } from 'luxon';

export default class SatelliteMessage {
  static thaw(satelliteMessageObj) {
    if (satelliteMessageObj == null) return null;
    const satelliteMessage = new SatelliteMessage();
    if (satelliteMessageObj.id != null) satelliteMessage.setID(satelliteMessageObj.id);
    if (satelliteMessageObj.recordedTimestamp != null) satelliteMessage.setRecordedTimestamp(DateTime.fromISO(satelliteMessageObj.recordedTimestamp));
    if (satelliteMessageObj.cameraID != null) satelliteMessage.setCameraID(satelliteMessageObj.cameraID);
    if (satelliteMessageObj.latitude != null) satelliteMessage.setLatitude(satelliteMessageObj.latitude);
    if (satelliteMessageObj.longitude != null) satelliteMessage.setLongitude(satelliteMessageObj.longitude);
    if (satelliteMessageObj.cep != null) satelliteMessage.setCep(satelliteMessageObj.cep);
    if (satelliteMessageObj.usbUtilizedPercentage != null) satelliteMessage.setUsbUtilizedPercentage(satelliteMessageObj.usbUtilizedPercentage);
    if (satelliteMessageObj.shutdownSwitch != null) satelliteMessage.setShutdownSwitch(satelliteMessageObj.shutdownSwitch);
    if (satelliteMessageObj.firmwareState != null) satelliteMessage.setFirmwareState(satelliteMessageObj.firmwareState);
    if (satelliteMessageObj.cameraInputs != null) satelliteMessage.setCameraInputs(CameraInput.thawList(satelliteMessageObj.cameraInputs));
    if (satelliteMessageObj.camera != null) satelliteMessage.setCamera(Camera.thaw(satelliteMessageObj.camera));
    return satelliteMessage;
  }

  static thawList(satelliteMessageObjList) {
    if (satelliteMessageObjList == null) return [];
    var satelliteMessages = [];
    for (var i = 0; i < satelliteMessageObjList.length; i++) {
      satelliteMessages.push(SatelliteMessage.thaw(satelliteMessageObjList[i]));
    }
    return satelliteMessages;
  }

  static freeze(satelliteMessage) {
    var satelliteMessageObj = {};
    if (satelliteMessage.getID() != null) satelliteMessageObj.id = satelliteMessage.getID();
    if (satelliteMessage.getRecordedTimestamp() != null) satelliteMessageObj.recordedTimestamp = satelliteMessage.getRecordedTimestamp();
    if (satelliteMessage.getCameraID() != null) satelliteMessageObj.cameraID = satelliteMessage.getCameraID();
    if (satelliteMessage.getLatitude() != null) satelliteMessageObj.latitude = satelliteMessage.getLatitude();
    if (satelliteMessage.getLongitude() != null) satelliteMessageObj.longitude = satelliteMessage.getLongitude();
    if (satelliteMessage.getCep() != null) satelliteMessageObj.cep = satelliteMessage.getCep();
    if (satelliteMessage.getUsbUtilizedPercentage() != null) satelliteMessageObj.usbUtilizedPercentage = satelliteMessage.getUsbUtilizedPercentage();
    if (satelliteMessage.getShutdownSwitch() != null) satelliteMessageObj.shutdownSwitch = satelliteMessage.getShutdownSwitch();
    if (satelliteMessage.getFirmwareState() != null) satelliteMessageObj.firmwareState = satelliteMessage.getFirmwareState();
    if (satelliteMessage.getCameraInputs() != null) satelliteMessageObj.cameraInputs = CameraInput.freezeList(satelliteMessage.getCameraInputs());
    if (satelliteMessage.getCamera() != null) satelliteMessageObj.camera = Camera.freeze(satelliteMessage.getCamera());
    return satelliteMessageObj;
  }

  static freezeList(satelliteMessages) {
    if (satelliteMessages == null) return [];
    var satelliteMessageObjList = [];
    for (var i = 0; i < satelliteMessages.length; i++) {
      satelliteMessageObjList.push(SatelliteMessage.freeze(satelliteMessages[i]));
    }
    return satelliteMessageObjList;
  }

  constructor() {
    this.setID(null);
    this.setRecordedTimestamp(null);
    this.setCameraID(null);
    this.setLatitude(null);
    this.setLongitude(null);
    this.setCep(null);
    this.setUsbUtilizedPercentage(null);
    this.setShutdownSwitch(null);
    this.setFirmwareState(null);
    this.setCameraInputs(null);
    this.setCamera(null);
    this.setDisplayColor(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setRecordedTimestamp(recordedTimestamp) {
    this.recordedTimestamp = recordedTimestamp;
  }

  setCameraID(cameraID) {
    this.cameraID = cameraID;
  }

  setLatitude(latitude) {
    this.latitude = latitude;
  }

  setLongitude(longitude) {
    this.longitude = longitude;
  }

  setCep(cep) {
    this.cep = cep;
  }

  setUsbUtilizedPercentage(usbUtilizedPercentage) {
    this.usbUtilizedPercentage = usbUtilizedPercentage;
  }

  setShutdownSwitch(shutdownSwitch) {
    this.shutdownSwitch = shutdownSwitch;
  }

  setFirmwareState(firmwareState) {
    this.firmwareState = firmwareState;
  }

  setCameraInputs(cameraInputs) {
    this.cameraInputs = cameraInputs;
  }

  setCamera(camera) {
    this.camera = camera;
  }

  setDisplayColor(displayColor) {
    this.displayColor = displayColor;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getRecordedTimestamp() {
    return this.recordedTimestamp;
  }

  getCameraID() {
    return this.cameraID;
  }

  getLatitude() {
    return this.latitude;
  }

  getLongitude() {
    return this.longitude;
  }

  getCep() {
    return this.cep;
  }

  getUsbUtilizedPercentage() {
    return this.usbUtilizedPercentage;
  }

  getShutdownSwitch() {
    return this.shutdownSwitch;
  }

  getFirmwareState() {
    return this.firmwareState;
  }

  getCameraInputs() {
    return this.cameraInputs;
  }

  getCamera() {
    return this.camera;
  }

  getDisplayColor() {
    return this.displayColor;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getFirmwareStateText() {
    if (this.getFirmwareState() == null) return null;
    switch (this.getFirmwareState()) {
      case 0: return 'Standby State';
      case 1: return 'Recording State';
      case 2: return 'Recording State + Video';
      case 3: return 'Service State';
      case 4: return 'Hibernate State';
      case 5: return 'Error State';
      default: return null;
    }
  }

}

import { SatelliteMessage } from './';
import CameraKeyType from './CameraKeyType';

export default class CameraInput {
  static thaw(cameraInputObj) {
    if (cameraInputObj == null) return null;
    const cameraInput = new CameraInput();
    if (cameraInputObj.id != null) cameraInput.setID(cameraInputObj.id);
    if (cameraInputObj.value != null) cameraInput.setValue(cameraInputObj.value);
    if (cameraInputObj.inputNumber != null) cameraInput.setInputNumber(cameraInputObj.inputNumber);
    if (cameraInputObj.type != null) cameraInput.setType(cameraInputObj.type);
    if (cameraInputObj.satelliteMessageID != null) cameraInput.setSatelliteMessageID(cameraInputObj.satelliteMessageID);
    if (cameraInputObj.satelliteMessage != null) cameraInput.setSatelliteMessage(SatelliteMessage.thaw(cameraInputObj.satelliteMessage));
    if (cameraInputObj.cameraKeyTypeId != null) cameraInput.setCameraKeyTypeID(cameraInputObj.cameraKeyTypeId);
    if (cameraInputObj.cameraKeyType != null) cameraInput.setCameraKeyType(CameraKeyType.thaw(cameraInputObj.cameraKeyType));
    return cameraInput;
  }

  static thawList(cameraInputObjList) {
    if (cameraInputObjList == null) return [];
    var cameraInputs = [];
    for (var i = 0; i < cameraInputObjList.length; i++) {
      cameraInputs.push(CameraInput.thaw(cameraInputObjList[i]));
    }
    return cameraInputs;
  }

  static freeze(cameraInput) {
    var cameraInputObj = {};
    if (cameraInput.getID() != null) cameraInputObj.id = cameraInput.getID();
    if (cameraInput.getValue() != null) cameraInputObj.value = cameraInput.getValue();
    if (cameraInput.getInputNumber() != null) cameraInputObj.inputNumber = cameraInput.getInputNumber();
    if (cameraInput.getType() != null) cameraInputObj.type = cameraInput.getType();
    if (cameraInput.getSatelliteMessageID() != null) cameraInputObj.satelliteMessageID = cameraInput.getSatelliteMessageID();
    if (cameraInput.getSatelliteMessage() != null) cameraInputObj.satelliteMessage = SatelliteMessage.freeze(cameraInput.getSatelliteMessage());
    if (cameraInput.getCameraKeyTypeID() != null) cameraInputObj.cameraKeyTypeId = cameraInput.getCameraKeyTypeID();
    if (cameraInput.getCameraKeyType() != null) cameraInputObj.cameraKeyType = CameraKeyType.freeze(cameraInput.getCameraKeyType());
    return cameraInputObj;
  }

  static freezeList(cameraInputs) {
    if (cameraInputs == null) return [];
    var cameraInputObjList = [];
    for (var i = 0; i < cameraInputs.length; i++) {
      cameraInputObjList.push(CameraInput.freeze(cameraInputs[i]));
    }
    return cameraInputObjList;
  }

  constructor() {
    this.setID(null);
    this.setValue(null);
    this.setInputNumber(null);
    this.setType(null);
    this.setSatelliteMessageID(null);
    this.setSatelliteMessage(null);
    this.setCameraKeyTypeID(null);
    this.setCameraKeyType(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setValue(value) {
    this.value = value;
  }

  setInputNumber(inputNumber) {
    this.inputNumber = inputNumber;
  }

  setType(type) {
    this.type = type;
  }

  setSatelliteMessageID(satelliteMessageID) {
    this.satelliteMessageID = satelliteMessageID;
  }

  setSatelliteMessage(satelliteMessage) {
    this.satelliteMessage = satelliteMessage;
  }

  setCameraKeyTypeID(cameraKeyTypeId) {
    this.cameraKeyTypeId = cameraKeyTypeId;
  }

  setCameraKeyType(cameraKeyType) {
    this.cameraKeyType = cameraKeyType;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getValue() {
    if ((this.type === 'Longitude' || this.type === 'Latitude') && this.value != null) return parseFloat(this.value);
    return this.value;
  }

  getInputNumber() {
    return this.inputNumber;
  }

  getType() {
    return this.type;
  }

  getSatelliteMessageID() {
    return this.satelliteMessageID;
  }

  getSatelliteMessage() {
    return this.satelliteMessage;
  }

  getCameraKeyTypeID() {
    return this.cameraKeyTypeId;
  }

  getCameraKeyType() {
    return this.cameraKeyType;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getDisplayValue() {
    // Check if the value is in the cameraKeyTypeDisplays array
    // If not, return the value
    return this.cameraKeyType?.getCameraKeyTypeDisplays()?.find((cameraKeyTypeDisplay) => cameraKeyTypeDisplay.value === this.getValue())?.getText() ?? this.getValue();
  }

}

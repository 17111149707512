import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CameraConfigurationFile } from '../lib';
import { Container, IconButton, Paper, TextField, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import { Clear } from '@mui/icons-material';

/**
 * @param {object} props The props to pass to the component.
 * @param {(newConfig: import('../lib/CameraConfigurationFile').default | null) => void} props.setConfigurationFile The function to call once the config is changed is uploaded, called with null to delete.
 * @param {import('../lib/CameraConfigurationFile').default} [props.configurationFile] The config of the file that is currently selected, nullish for none.
 */
const CameraConfigurationFileFormEntry = ({ setConfigurationFile, configurationFile }) => {
  const [configurationName, setConfigurationName] = React.useState(configurationFile.getFileName() ?? '');
  const [uploadedFile, setUploadedFile] = React.useState(null);

  useEffect(() => {
    let shouldUpdate = true;
    (async () => {
      const configFile = new CameraConfigurationFile();
      configFile.setFileContent(await uploadedFile?.text() ?? configurationFile.getFileContent());
      configFile.setFileName(configurationName ?? configurationFile.getFileName());

      if (shouldUpdate) {
        setConfigurationFile(configFile);
      }
    })();
    return () => {
      shouldUpdate = false;
    };
  }, [uploadedFile, configurationName]);

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', my: '1em', p: '0.5em', backgroundColor: 'lightgrey' }}>
      <TextField
        sx={{ margin: '0.5rem', backgroundColor: 'white' }}
        variant='filled'
        label="Configuration Name"
        value={configurationName}
        onChange={(e) => {
          setConfigurationName(e.target.value);
        }}
        required
      />
      <Container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'space-between', my: '0.5rem' }}>
        <Button
          variant="contained"
          component="label"
          sx={{ flex: 1, mr: '0.5rem' }}
        >
          Select
          <input
            type="file"
            accept='application/json'
            onChange={(e) => setUploadedFile(e.target.files[0])}
            hidden
          />
        </Button>
        <Typography sx={{ flex: 4 }}>
          {uploadedFile?.name ?? 'Upload a New File'}
        </Typography>
        <IconButton
          aria-label="Clear"
          onClick={() => setConfigurationFile(null)}
          sx={{ width: '1.5rem', height: '1.5rem' }}
        >
          <Clear />
        </IconButton>
      </Container>
    </Paper>
  );
};

CameraConfigurationFileFormEntry.propTypes = {
  setConfigurationFile: PropTypes.func.isRequired,
  configurationFile: PropTypes.object.isRequired
};

export default CameraConfigurationFileFormEntry;
// Colors
export const GREY = '#6a8591';
export const NAVY = '#001f54';
export const BLUE = '#004480';
export const LIGHT_BLUE = '#56a6d8';
export const LIGHT_GREY = '#dfe7e7';
export const WHITE = '#ffffff';
export const TEAL = '#008d9c';

export const DATE_TIME_ZONE_FORMAT = { timeZoneName: 'short', weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };

export const LBS_TO_KG = 0.453592;
export const MILES_TO_KM = 1.60934;

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  GUEST: 'GUEST'
};
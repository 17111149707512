import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

function LineItem({ value, description, centered = false, fixed = null }) {
  // If fixed is set, assume the value passed is a float (or string that can be made a float)
  let displayValue = value;
  if (fixed != null && value != null) {
    if (typeof value === 'string') {
      if (!isNaN(parseFloat(value))) displayValue = parseFloat(value).toFixed(2);
    }
    else displayValue = value.toFixed(2);
  }

  return (
    <div style={{ marginTop: '1em', marginBottom: '1em', display: 'flex', flexDirection: 'column', justifyContent: centered ? 'center' : 'flex-start' }}>
      <Typography variant='body1' style={{ textAlign: centered ? 'center' : 'left' }}>{displayValue == null || displayValue === '' ? '-----' : displayValue}</Typography>
      <Typography variant='overline' style={{ textAlign: centered ? 'center' : 'left' }}>{description}</Typography>
    </div>
  );
}
LineItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  description: PropTypes.string.isRequired,
  centered: PropTypes.bool,
  fixed: PropTypes.number
};

export default LineItem;

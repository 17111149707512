import { store } from '../redux/store';
import { AlertSeverity, clearErrors as reduxClearErrors, setAlert as reduxSetAlert, setSuccess as reduxSetSuccess, removeAlert as reduxRemoveAlert } from '../redux/actions/alertActions';

export function setSuccess(msg) {
  if (store != null) reduxSetSuccess(store.dispatch, msg);
}

export function clearErrors() {
  if (store != null) store.dispatch(reduxClearErrors());
}

export function setError(msg) {
  if (store != null) reduxSetAlert({ dispatch: store.dispatch, msg });
}

export function setInfo(props) {
  if (store != null) reduxSetAlert({ dispatch: store.dispatch, severity: AlertSeverity.INFO, haveTimeout: false, ...props });
}

export function removeAlert(...props) {
  if (store != null) store.dispatch(reduxRemoveAlert(...props));
}

import React from 'react';
import PropTypes from 'prop-types';
import SailingIcon from '@mui/icons-material/Sailing';
import { IconButton, Tooltip } from '@mui/material';
import { MapWidget } from './';

// Constants
import { NAVY, DATE_TIME_ZONE_FORMAT } from '../constants';

function getOpacity(length, index) {
  return Math.round((1 - ((1 / length) * index)) * 100) / 100;
}

class SSSMapWidget extends React.Component {
  render() {

    // Retrieve the locations
    const locations = this.props.satelliteMessages.map(satelliteMessage => {
      let longitude = satelliteMessage.getCameraInputs()?.find(cameraInput => cameraInput.getCameraKeyType().getDescription() === 'GPS Longitude')?.getValue();
      let latitude = satelliteMessage.getCameraInputs()?.find(cameraInput => cameraInput.getCameraKeyType().getDescription() === 'GPS Latitude')?.getValue();
      let isEstimated = false;

      // -255 is reported if unable to get GPS data, so check that first
      if (longitude == '-255' || longitude == '255' || latitude == '-255' || latitude == '255') longitude = null;

      // Try to use the camera input GPS data
      else if (longitude != null && typeof longitude == 'string' && latitude != null && typeof latitude == 'string') {
        longitude = Number(longitude);
        latitude = Number(latitude);
      }

      // If all else fails, use the satelliteMessage GPS coordinates as a backup
      if (longitude == null || latitude == null) {
        longitude = parseFloat(satelliteMessage.getLongitude());
        latitude = parseFloat(satelliteMessage.getLatitude());
        isEstimated = true;
      }
      return { coordinates: { lng: longitude, lat: latitude }, id: satelliteMessage.getID(), timestamp: satelliteMessage.getRecordedTimestamp(), displayColor: satelliteMessage.getDisplayColor(), isEstimated };
    })?.filter(satelliteMessage => satelliteMessage != null);

    return (
      <MapWidget
        height={this.props.height}
        width={this.props.width}
        style={this.props.style}
        defaultCenter={locations?.length > 0 ? locations[0].coordinates : null}
        defaultZoom={11}
      >
        {locations?.map((location, index) => (
          <div
            style={{ marginLeft: -15, marginTop: -15 }} // Necessary to set anchor for marker more appropriately
            key={location.id}
            lat={location.coordinates.lat}
            lng={location.coordinates.lng}>
            <Tooltip title={location.timestamp.toLocaleString(DATE_TIME_ZONE_FORMAT) + (location.isEstimated ? ' [Estimated Location]' : '')}>
              <IconButton onClick={() => this.props.onSelect(location.id)} style={{ border: this.props.selectedSatelliteMessageID === location.id ? '1px solid rgba(175, 175, 175, 0.4)' : 'none' }}>
                <SailingIcon style={{ color: location.displayColor || NAVY, opacity: getOpacity(locations.length, index), fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          </div>
        ))}
      </MapWidget>
    );
  }
}

SSSMapWidget.propTypes = {
  satelliteMessages: PropTypes.array.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  style: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  selectedSatelliteMessageID: PropTypes.number
};

export default SSSMapWidget;

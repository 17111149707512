/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use to FlyWire
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Button, CircularProgress, Container, Typography, TextField } from '@mui/material';

class CustomLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: 'sydney.norman@lexcelon.com',
      password: 'password',
      isLoading: false,
      redirect: false
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      email,
      password
    } = this.state;

    // Validate entry
    if (email == null || email === '' || password == null || password === '') {
      this.props.setError('Please fill in both email and password.');
      return;
    }

    this.props.onLogin(email, password);
  };

  render() {
    if (this.props.redirect) return <Redirect to={this.props.redirectLink} />;
    return (
      <Container>
        <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
          {/* ----- LOGO & TITLE ----- */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2em', marginBottom: '1em' }}>
            {this.props.logo != null &&
            <img
              width={250}
              src={this.props.logo}
              alt={'Logo'}
              title={'Logo'}
              style={{ objectFit: 'contain' }}
            />}
          </div>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em' }}>Login</Typography>

          {/* Email Input */}
          <TextField
            required
            error={this.state.formError && this.state.email === ''}
            name="email"
            label="Email"
            type='email'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
          />

          {/* ----- PASSWORD ----- */}
          <TextField
            required
            error={this.state.formError && this.state.password === ''}
            name="password"
            label="Password"
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.password}
            onChange={this.onChange}
            variant='filled'
            type='password'
          />

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
            <Button type='submit' variant='contained' disabled={this.state.isLoading} style={{ width: '50%', backgroundColor: this.props.color, color: 'white' }}>
              Login
              {this.state.isLoading && <CircularProgress size={25} style={{ marginLeft: '1em' }} />}
            </Button>

            {!this.props.hideCreateAccount &&
            <Typography variant='button' style={{ marginTop: '10px' }}>Need to make an account?{' '}
              <Link to={this.props.createAccountLink} style={{ color: this.props.color }}>
                Create one now
              </Link>.
            </Typography>}

            <Typography variant='button' style={{ marginTop: '10px' }}>Forgot your password?{' '}
              <Link to={this.props.resetPasswordLink} style={{ color: this.props.color }}>
                Reset Now
              </Link>.
            </Typography>
          </div>
        </form>
      </Container>
    );
  }
}

CustomLogin.propTypes = {
  setError: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  redirectLink: PropTypes.string.isRequired,
  createAccountLink: PropTypes.string.isRequired,
  resetPasswordLink: PropTypes.string.isRequired,
  logo: PropTypes.any,
  color: PropTypes.string.isRequired,
  redirect: PropTypes.bool.isRequired,
  hideCreateAccount: PropTypes.bool
};

export default CustomLogin;

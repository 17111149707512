import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { fullyAuthenticateUser, logoutUser } from '../../../redux/actions/authActions';
import { clearErrors, setError, setSuccess } from '../../../alerts';

// Constants
import { NAVY } from '../../../constants';
import { getMultiFactorUser, sendMultiFactorAuthentication } from '../../../api';

class TwoFactor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSending: false,
      user: null,
      mfaMethod: null,
      sentCode: false,
      code: '',
    };
  }

  componentDidMount() {
    getMultiFactorUser().then(user => {
      this.setState({ user, mfaMethod: user.getMfaMethod() });
    }).catch(error => {
      setError(error ?? 'Unable to retrieve user information.');
    });
  }

  onSendCode = (e) => {
    e?.preventDefault();

    this.sendCode();
  }

  sendCode = (resent = false) => {
    this.setState({ isSending: true });
    sendMultiFactorAuthentication(this.state.mfaMethod).then(() => {
      if (resent) setSuccess('Code resent.');
      this.setState({ sentCode: true, isSending: false });
    }).catch(error => {
      setError(error ?? 'Unable to send 2FA code.');
      this.setState({ isSending: false });
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.fullyAuthenticateUser(this.state.code);
  }

  render() {
    return (
      <Container>
        {!this.state.sentCode ? (
          <form autoComplete='off' onSubmit={this.onSendCode} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='h1' style={{ margin: '1em', textAlign: 'center' }}>Two Factor Authentication</Typography>

            <FormControl disabled={this.state.isSending}>
              <FormLabel>How would you like to receive your 2FA code?</FormLabel>
              <RadioGroup
                value={this.state.mfaMethod}
                onChange={(e) => this.setState({ mfaMethod: e.target.value })}
              >
                <FormControlLabel value='SMS' control={<Radio />} label='Text Message' />
                <FormControlLabel value='VOICE' control={<Radio />} label='Phone Call' />
                <FormControlLabel value='WHATSAPP' control={<Radio />} label='WhatsApp' />
              </RadioGroup>
            </FormControl>

            <Button type='submit' variant='contained' style={{ backgroundColor: NAVY, width: 300, marginTop: '2em', color: 'white' }} disabled={this.state.isSending}>
              Send Code to {this.state.user?.getPhoneNumber()}
              {this.state.isSending && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
            </Button>
            <Button variant='text' disabled={this.state.isSending} style={{ color: NAVY, width: 200, marginTop: '0.5em' }} onClick={this.props.logout}>Log in as different user</Button>
            {this.state.user?.getTwoFactorSetupRequired() &&
            <Button variant='text' disabled={this.state.isSending} style={{ color: NAVY, width: 200, marginTop: '0.5em' }} component={Link} to='/auth/2fa/phone-number'>Change phone number</Button>}
          </form>
        ) : (
          <form autoComplete='off' onSubmit={this.onSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='h1' style={{ margin: '1em', textAlign: 'center' }}>Two Factor Authentication</Typography>
            <Typography variant='body1' style={{ textAlign: 'center' }}>Your 2FA code was sent to {this.state.user?.getPhoneNumber()} via {this.state.mfaMethod === 'SMS' ? 'text message' : this.state.mfaMethod === 'VOICE' ? 'phone call' : 'WhatsApp'}. Please wait 2 minutes to receive the code before resending.</Typography>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
              <TextField
                required
                error={this.state.formError && this.state.code === ''}
                name='code'
                label='Code'
                style={{ margin: '15px', width: '200px' }}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading || this.state.isSending}
              />
              <Button onClick={() => this.sendCode(true)} disabled={this.state.isLoading || this.state.isSending} style={{ backgroundColor: 'grey', color: 'white', margin: '15px', width: '100px' }}>
                Resend
                {this.state.isSending && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
              </Button>
            </div>

            <Button type='submit' variant='contained' disabled={this.state.isLoading || this.state.isSending} style={{ backgroundColor: NAVY, width: 150, marginTop: '2em', color: 'white' }}>
              Submit
              {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
            </Button>
            <Button variant='text' disabled={this.state.isLoading || this.state.isSending} style={{ color: NAVY, width: 200, marginTop: '0.5em' }} onClick={this.props.logout}>Log in as different user</Button>
          </form>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  fullyAuthenticateUser: (token) => fullyAuthenticateUser(dispatch, token),
  logout: () => logoutUser(dispatch)
});

TwoFactor.propTypes = {
  fullyAuthenticateUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactor);

/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import { v4 as uuidv4 } from 'uuid';

// Constants
const DEFAULT_TIMEOUT = 6000;
const SUCCESS_TIMEOUT = 3000;

/* -------------------------
   -------------------------
   ----- ACTION TYPES ------
   -------------------------
   ------------------------- */

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const CLEAR_INFOS = 'CLEAR_INFOS';
export const REMOVE_ALERT_FROM_MESSAGE = 'REMOVE_ALERT_FROM_MESSAGE';
export const AlertSeverity = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info'
};

/* -------------------------
   -------------------------
   ---- ACTION OBJECTS -----
   -------------------------
   ------------------------- */

export const addAlert = ({
  id = uuidv4(),
  msg,
  severity = AlertSeverity.ERROR,
  onButtonPress = null,
  buttonText = null,
  showClose = true
}) => ({
  type: ADD_ALERT,
  id,
  msg,
  severity,
  onButtonPress,
  buttonText,
  showClose
});

export const removeAlert = (id) => ({
  type: REMOVE_ALERT,
  id
});

export const removeAlertFromMessage = (msg) => ({
  type: REMOVE_ALERT_FROM_MESSAGE,
  msg
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

export const clearAlerts = () => ({
  type: CLEAR_ALERTS
});

export const clearInfos = () => ({
  type: CLEAR_INFOS
});

/* -------------------------
   -------------------------
   -------- HELPERS --------
   -------------------------
   ------------------------- */

export const setSuccess = (dispatch, msg) => {
  dispatch(clearErrors());
  setAlert({ dispatch, msg, severity: AlertSeverity.SUCCESS, haveTimeout: true });
};

export const setAlert = ({
  id = null,
  dispatch,
  msg = '',
  severity = AlertSeverity.ERROR,
  haveTimeout = true,
  showClose = true,
  onButtonPress = null,
  buttonText = null
}) => {
  window.scrollTo(0, 0);

  if (msg?.message != null && msg?.message !== '') msg = msg.message;
  else if (msg == null || msg === '') {
    switch (severity) {
      case AlertSeverity.ERROR:
        msg = 'Error: something went wrong. Please try again later.';
        break;
      case AlertSeverity.WARNING:
        msg = 'Warning!';
        break;
      case AlertSeverity.SUCCESS:
        msg = 'Success!';
        break;
      default:
        msg = 'An unknown event occurred.';
    }
  }

  // Add New Alert to App State
  const alertOptions = {
    msg,
    severity,
    showClose
  };
  if (id != null) alertOptions.id = id;
  if (onButtonPress != null) alertOptions.onButtonPress = onButtonPress;
  if (buttonText != null) alertOptions.buttonText = buttonText;
  const newAlert = addAlert(alertOptions);
  dispatch(newAlert);

  // Remove the New Alert After Timeout (3 sec default)
  if (haveTimeout)
    setTimeout(() => dispatch(removeAlert(newAlert.id)), severity === AlertSeverity.SUCCESS ? SUCCESS_TIMEOUT : DEFAULT_TIMEOUT);
};

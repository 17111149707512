import React, { Component } from 'react';
import { Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Alerts
import { clearErrors, setError, setSuccess } from '../../alerts';

// API
import { changePassword, getUser, requestChangeEmail, updateUser, listAllTrips } from '../../api';

// Components
import { LineItem, Table } from '../../components';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      isLoadingUser: true,
      isLoading: false,
      inEditMode: false,
      inChangeEmailMode: false,
      inChangePasswordMode: false,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      trips: []
    };
  }

  componentDidMount() {
    this.refreshUser();
  }

  componentWillUnmount() {
    clearErrors();
  }

  refreshUser() {
    this.setState({ isLoadingUser: true });
    getUser().then(user => {
      this.setState({
        user,
        firstName: user.getFirstName(),
        lastName: user.getLastName(),
        phoneNumber: user.getPhoneNumber(),
        email: user.getEmail(),
        isLoadingUser: false
      });

      // If user belongs to a business, get the shared trip information
      if (user.getBusinessID() != null) {
        listAllTrips().then(trips => {
          this.setState({ trips });
        }).catch(error => {
          setError(error ? error : 'Error: Unable to retrieve shared trip data.');
        });
      }
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve user object.');
      this.setState({ isLoadingUser: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateProfile = (e) => {
    e.preventDefault();

    const { firstName, lastName, phoneNumber } = this.state;

    if (phoneNumber?.length <= 3) {
      setError('Error: Invalid phone number');
      return;
    }

    this.setState({ isLoading: true });
    updateUser({ firstName, lastName, phoneNumber }).then(() => {
      setSuccess('Successfully updated user.');
      this.setState({ isLoading: false });
      this.refreshUser();
      this.closeForms();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to update user.');
      this.setState({ isLoading: false });
    });
  }

  changeEmail = (e) => {
    e.preventDefault();

    const { email } = this.state;

    this.setState({ isLoading: true });
    requestChangeEmail(email).then(() => {
      setSuccess('Successfully requested email change. Please check your email for your confirmation email. It will not be changed until you approve the change.');
      this.setState({ isLoading: false });
      this.closeForms();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to change email.');
      this.setState({ isLoading: false });
    });
  }

  changePassword = (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    if (newPassword !== confirmNewPassword) {
      setError('Error: Passwords must match.');
      return;
    }

    this.setState({ isLoading: true });
    changePassword(oldPassword, newPassword).then(() => {
      setSuccess('Successfully updated password.');
      this.setState({ isLoading: false });
      this.closeForms();
    }).catch(error => {
      setError(error ? error : 'Error: Unable to change password.');
      this.setState({ isLoading: false });
    });
  }

  closeForms = () => {
    this.setState({
      inEditMode: false,
      inChangeEmailMode: false,
      inChangePasswordMode: false,
      firstName: this.state.user.getFirstName(),
      lastName: this.state.user.getLastName(),
      phoneNumber: this.state.user.getPhoneNumber(),
      email: this.state.user.getEmail(),
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    });
    clearErrors();
  }

  render() {
    return (
      <Container>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
          <Typography variant='h1' style={{ textAlign: 'center' }}>{this.state.inEditMode ? 'Edit' : 'My'} Account</Typography>
          {this.state.isLoadingUser && <CircularProgress style={{ marginLeft: '20px' }} />}
        </div>

        {/* View Mode */}
        {!this.state.inEditMode && !this.state.inChangeEmailMode && !this.state.inChangePasswordMode &&
        <>
          <LineItem value={this.state.user?.getName()} description='Name' />
          <LineItem value={this.state.user?.getPhoneNumber()} description='Phone Number' />
          <LineItem value={this.state.user?.getEmail()} description='Email' />
          <LineItem value={this.state.user?.getBusiness()?.getName()} description='Business' />

          <div style={{ display: 'flex', flexDirection: 'column', width: 200 }}>
            <Button onClick={() => this.setState({ inEditMode: true })} style={{ marginBottom: 10 }} disabled={this.state.isLoadingUser || !this.state.user}>Edit Profile</Button>
            <Button onClick={() => this.setState({ inChangeEmailMode: true })} style={{ marginBottom: 10 }} disabled={this.state.isLoadingUser || !this.state.user}>Change Email</Button>
            <Button onClick={() => this.setState({ inChangePasswordMode: true })} style={{ marginBottom: 10 }} disabled={this.state.isLoadingUser || !this.state.user}>Change Password</Button>
          </div>

          {/* Shared Business Data */}
          {this.state.user?.getBusinessID() != null &&
          <>
            <Typography variant='h2' style={{ marginTop: '3em' }}>Shared Trip Data</Typography>

            <Table
              title='Shared Trips'
              data={this.state.trips ?? []}
              columns={[
                {
                  title: 'Trip ID',
                  field: 'id'
                },
                {
                  title: 'Boat Name',
                  render: rowData => rowData?.getBoat()?.getName()
                },
                {
                  title: 'Departure Date',
                  field: 'startTimestamp',
                  render: rowData => rowData.startTimestamp ? rowData.startTimestamp.toLocaleString() : ''
                },
                {
                  title: 'Guests',
                  render: rowData => (
                    <>
                      {rowData.getGuestPermissions()?.map(guestPermission => (
                        <Typography key={guestPermission.getID()} variant='body2' style={{ fontSize: 14, color: 'slategrey' }}>
                          <b>{guestPermission.getGuest() != null ? (
                            guestPermission.getGuest().getName() + (guestPermission.getGuest()?.getAssociatedWithBusiness() != null ? (' (' + guestPermission.getGuest().getAssociatedWithBusiness().getName() + ')') : '')
                          ) : (
                            guestPermission.getGuestInvitation().getEmail() + (guestPermission.getGuestInvitation()?.getAssociatedWithBusiness() != null ? (' (' + guestPermission.getGuestInvitation().getAssociatedWithBusiness().getName() + ')') : '')
                          )}</b>:
                          {guestPermission.getPermissionListString()}
                        </Typography>
                      ))}
                    </>
                  )
                },
              ]}
              options={{ pageSize: 20 }}
            />
          </>}
        </>}

        {/* Edit Mode */}
        {this.state.inEditMode &&
        <>
          <form onSubmit={this.updateProfile}>
            <Typography variant='h2' style={{ marginBottom: '1em' }}>Profile</Typography>

            {/* Name Input */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
              <TextField
                required
                error={this.state.formError && this.state.firstName === ''}
                name='firstName'
                label='First Name'
                style={{ width: '49%' }}
                value={this.state.firstName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
              />

              <TextField
                required
                error={this.state.formError && this.state.lastName === ''}
                name='lastName'
                label='Last Name'
                style={{ width: '49%' }}
                value={this.state.lastName}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
              />
            </div>

            {/* Phone Number */}
            <ReactPhoneInput
              country='us'
              preferredCountries={['us', 'cn']}
              value={this.state.phoneNumber}
              onChange={(phoneNumber) => this.setState({ phoneNumber })}
              component={TextField}
              inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.state.isLoading, error: this.state.formError && this.state.phoneNumber?.length <= 3 }}
              containerStyle={{ width: '100%', marginBottom: '10px' }}
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
              <Button style={{ width: '49%', backgroundColor: 'grey' }} onClick={this.closeForms} disabled={this.state.isLoading}>Cancel</Button>
              <Button style={{ width: '49%' }} type='submit' disabled={this.state.isLoading}>
                Save
                {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
              </Button>
            </div>
          </form>
        </>}

        {/* Change Email Mode */}
        {this.state.inChangeEmailMode &&
        <>
          <form onSubmit={this.changeEmail}>
            <Typography variant='h2' style={{ marginBottom: '1em' }}>Change Email</Typography>

            {/* Email Input */}
            <TextField
              name='email'
              label='Email'
              type='email'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.email}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
              <Button style={{ width: '49%', backgroundColor: 'grey' }} onClick={this.closeForms} disabled={this.state.isLoading}>Cancel</Button>
              <Button style={{ width: '49%' }} type='submit' disabled={this.state.isLoading}>
                Save
                {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
              </Button>
            </div>
          </form>
        </>}

        {/* Change Password Mode */}
        {this.state.inChangePasswordMode &&
        <>
          <form onSubmit={this.changePassword}>
            <Typography variant='h2' style={{ marginBottom: '1em' }}>Change Password</Typography>

            <TextField
              required
              error={this.state.formError && this.state.oldPassword === ''}
              name='oldPassword'
              label='Old Password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.oldPassword}
              onChange={this.onChange}
              variant='filled'
              type='password'
              disabled={this.state.isLoading}
            />
            <TextField
              required
              error={this.state.formError && this.state.newPassword === ''}
              name='newPassword'
              label='New Password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.newPassword}
              onChange={this.onChange}
              variant='filled'
              type='password'
              disabled={this.state.isLoading}
            />
            <TextField
              required
              error={this.state.formError && this.state.confirmNewPassword === ''}
              name='confirmNewPassword'
              label='Confirm New Password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.confirmNewPassword}
              onChange={this.onChange}
              variant='filled'
              type='password'
              disabled={this.state.isLoading}
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
              <Button style={{ width: '49%', backgroundColor: 'grey' }} onClick={this.closeForms} disabled={this.state.isLoading}>Cancel</Button>
              <Button style={{ width: '49%' }} type='submit' disabled={this.state.isLoading}>
                Save
                {this.state.isLoading && <CircularProgress style={{ width: 20, height: 20, marginLeft: 10, color: 'white' }} />}
              </Button>
            </div>
          </form>
        </>}
      </Container>
    );
  }
}

export default Account;

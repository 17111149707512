import { DateTime } from 'luxon';
import { Camera } from '.';

export default class OutgoingSatelliteCommand {
  static thaw(outgoingSatelliteCommandObj) {
    if (outgoingSatelliteCommandObj == null) return null;
    const outgoingSatelliteCommand = new OutgoingSatelliteCommand();
    if (outgoingSatelliteCommandObj.id != null) outgoingSatelliteCommand.setID(outgoingSatelliteCommandObj.id);
    if (outgoingSatelliteCommandObj.cameraID != null) outgoingSatelliteCommand.setCameraID(outgoingSatelliteCommandObj.cameraID);
    if (outgoingSatelliteCommandObj.camera != null) outgoingSatelliteCommand.setCamera(Camera.thaw(outgoingSatelliteCommandObj.camera));
    if (outgoingSatelliteCommandObj.data != null) outgoingSatelliteCommand.setData(outgoingSatelliteCommandObj.data);
    if (outgoingSatelliteCommandObj.response != null) outgoingSatelliteCommand.setResponse(outgoingSatelliteCommandObj.response);
    if (outgoingSatelliteCommandObj.timestamp != null) outgoingSatelliteCommand.setTimestamp(DateTime.fromISO(outgoingSatelliteCommandObj.timestamp));
    return outgoingSatelliteCommand;
  }

  static thawList(outgoingSatelliteCommandObjList) {
    if (outgoingSatelliteCommandObjList == null) return [];
    var outgoingSatelliteCommands = [];
    for (var i = 0; i < outgoingSatelliteCommandObjList.length; i++) {
      outgoingSatelliteCommands.push(OutgoingSatelliteCommand.thaw(outgoingSatelliteCommandObjList[i]));
    }
    return outgoingSatelliteCommands;
  }

  static freeze(outgoingSatelliteCommand) {
    var outgoingSatelliteCommandObj = {};
    if (outgoingSatelliteCommand.getID() != null) outgoingSatelliteCommandObj.id = outgoingSatelliteCommand.getID();
    if (outgoingSatelliteCommand.getCameraID() != null) outgoingSatelliteCommandObj.cameraID = outgoingSatelliteCommand.getCameraID();
    if (outgoingSatelliteCommand.getCamera() != null) outgoingSatelliteCommandObj.camera = Camera.freeze(outgoingSatelliteCommand.getCamera());
    if (outgoingSatelliteCommand.getData() != null) outgoingSatelliteCommandObj.data = outgoingSatelliteCommand.getData();
    if (outgoingSatelliteCommand.getResponse() != null) outgoingSatelliteCommandObj.response = outgoingSatelliteCommand.getResponse();
    if (outgoingSatelliteCommand.getTimestamp() != null) outgoingSatelliteCommandObj.timestamp = outgoingSatelliteCommand.getTimestamp().toISO();
    return outgoingSatelliteCommandObj;
  }

  static freezeList(outgoingSatelliteCommands) {
    if (outgoingSatelliteCommands == null) return [];
    var outgoingSatelliteCommandObjList = [];
    for (var i = 0; i < outgoingSatelliteCommands.length; i++) {
      outgoingSatelliteCommandObjList.push(OutgoingSatelliteCommand.freeze(outgoingSatelliteCommands[i]));
    }
    return outgoingSatelliteCommandObjList;
  }

  constructor() {
    this.setID(null);
    this.setCameraID(null);
    this.setCamera(null);
    this.setData(null);
    this.setResponse(null);
    this.setTimestamp(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setID(id) {
    this.id = id;
  }

  setCameraID(cameraID) {
    this.cameraID = cameraID;
  }

  setCamera(camera) {
    this.camera = camera;
  }

  setData(data) {
    this.data = data;
  }

  setResponse(response) {
    this.response = response;
  }

  setTimestamp(timestamp) {
    this.timestamp = timestamp;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getID() {
    return this.id;
  }

  getCameraID() {
    return this.cameraID;
  }

  getCamera() {
    return this.camera;
  }

  getData() {
    return this.data;
  }
  
  getResponse() {
    return this.response;
  }
  
  getTimestamp() {
    return this.timestamp;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

}

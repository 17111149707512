import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Navigation from './navigation';
import ScrollToTop from './ScrollToTop';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-phone-input-material-ui/lib/style.css';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

// Components
import { AlertList, SkeletonKeyAlert } from './components';
import AppBar from './navigation/AppBar';
import Footer from './navigation/Footer';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <div style={{ position: 'relative', minHeight: '100vh' }}>
              <AlertList />
              <SkeletonKeyAlert />
              <AppBar />
              <Navigation />
              <Footer />
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
